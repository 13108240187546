import { Trans } from '@lingui/macro';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { CSSProperties } from 'react';

import { colors } from '../../../../tailwind-design-preset';

export const NoddiCircularLoader = ({ style }: { style?: CSSProperties }) => (
  <Stack alignItems='center' justifyContent='center' style={style}>
    <CircularProgress sx={{ marginBottom: '15px', color: colors.primary.orange }} />
    <Trans>Loading..</Trans>
  </Stack>
);

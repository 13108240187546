import { Trans, t } from '@lingui/macro';
import { Card, CardContent, Typography } from '@mui/material';
import { DateFormats, format } from 'noddi-util';

import { KeyValueRow } from '../../../Elements';

type Props = {
  euControlNext: string | undefined;
  euControlPrevious: string | undefined;
};

const EuCheckDetails = ({ euControlNext, euControlPrevious }: Props) => {
  return euControlNext || euControlPrevious ? (
    <div>
      <Typography variant='h6'>
        <Trans>EU check</Trans>
      </Typography>
      <Card sx={{ marginTop: '1rem', height: '100%' }}>
        <CardContent>
          {euControlNext && (
            <KeyValueRow
              header={t`Next check`}
              value={format(euControlNext, DateFormats.DASHED_DATE_ISO_8601)}
              showDivider={!!euControlPrevious}
            />
          )}
          {euControlPrevious && (
            <KeyValueRow
              header={t`Last approval`}
              value={format(euControlPrevious, DateFormats.DASHED_DATE_ISO_8601)}
              showDivider={false}
            />
          )}
        </CardContent>
      </Card>
    </div>
  ) : null;
};

export { EuCheckDetails };

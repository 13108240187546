import { Trans, t } from '@lingui/macro';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, NoddiContainer } from 'noddi-ui';

import NewAppointmentLink from '../../components/Links/NewAppointment';
import { useCars } from '../../hooks/ApiHooks/useCars';
import CarCard from './CarListCard';

const Cars = () => {
  const { getCars } = useCars();

  const { currentUserGroupId: userGroupId, isTokenLoginLoading } = useAuthContext();
  const { isLoading, data: cars } = getCars(userGroupId);

  if (isLoading || isTokenLoginLoading) {
    return <LoadingScreen />;
  }
  if (cars && cars?.length === 0) {
    return (
      <NoddiContainer header={t`Your cars`} headerNode={<NewAppointmentLink />}>
        <Typography textAlign='center' mt={3} variant='body1'>
          <Trans>You don't have any cars registered in our system</Trans>
        </Typography>
      </NoddiContainer>
    );
  }

  return (
    <NoddiContainer header={t`Your cars`} headerNode={<NewAppointmentLink />}>
      <Grid container spacing={2} marginTop={1}>
        {cars?.map((car) => (
          <Grid item xs={12} sm={6} md={6} key={car.id}>
            <CarCard car={car} />
          </Grid>
        ))}
      </Grid>
    </NoddiContainer>
  );
};

export default Cars;

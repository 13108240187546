import { Trans, t } from '@lingui/macro';
import { Avatar, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { ErrorPage, LoadingScreen, NoddiContainer } from 'noddi-ui';

import MembershipDiscount from './MembershipDiscount';

const Membership = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  if (!userGroupId) {
    return <ErrorPage />;
  }
  const { isPending, data: membershipPrograms } = noddiAsync.useGet({
    type: URLKeys.getMembershipPrograms,
    input: { userGroupId },
    queryConfig: {
      enabled: !!userGroupId
    }
  });

  if (isPending) {
    return <LoadingScreen />;
  }

  return (
    <NoddiContainer
      header={t`Your member benefits`}
      description={t`An overview of all your active memberships and benefits`}
    >
      {membershipPrograms && membershipPrograms.length === 0 && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            mb: 4
          }}
        >
          <Typography sx={{ mt: 2 }} variant='h6'>
            <Trans>You currently have no membership benefits</Trans>
          </Typography>
        </Box>
      )}

      {membershipPrograms &&
        membershipPrograms.map((membershipProgram, programIndex) => (
          <div key={programIndex}>
            <Box
              sx={{
                pb: '80px'
              }}
            >
              <Stack direction='column' alignItems='center' mb={4}>
                {membershipProgram.image && (
                  <Avatar
                    src={membershipProgram.image}
                    sx={{
                      height: 80,
                      width: 80,
                      mb: 1
                    }}
                  />
                )}

                <Typography variant='h3'>{membershipProgram.name}</Typography>
              </Stack>
              <Grid container spacing={4}>
                {membershipProgram.discounts.map((discount, discountIndex) => (
                  <Grid key={discountIndex} xs={12}>
                    <MembershipDiscount discount={discount} />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Divider />
          </div>
        ))}
    </NoddiContainer>
  );
};

export default Membership;

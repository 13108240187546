import { Grid } from '@mui/material';
import { ServiceCategory } from 'noddi-async/src/types';

import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms/NoddiIcon';
import { getServiceCategoryTranslations } from './utils';

type Props = {
  serviceCategory: ServiceCategory;
};

export const AvailableServiceCategory = ({ serviceCategory }: Props): React.ReactElement => {
  const data = getServiceCategoryTranslations({ serviceCategoryName: serviceCategory.name });

  return (
    <Grid item xs={12} md={4}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          marginTop: '0.5em'
        }}
      >
        <NoddiIcon name='Check' color={colors.primary.orange} />
        <span style={{ marginLeft: '7px' }}>{data.name}</span>
      </div>
    </Grid>
  );
};

import { t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms/NoddiIcon';
import { resolveWheelSetStatusLogo, wheelSetStatusToTranslation } from './helpers';
import { TireType, Tires, WheelSetStatusType } from './types';

const WheelHeader = ({ type, wheelSetStatus }: { type?: TireType; wheelSetStatus: WheelSetStatusType }) => {
  return (
    <Stack direction='row' gap={2} paddingY={1}>
      <NoddiIcon name='WheelAngle' color={colors.primary.black} size={48} />
      <Stack direction='column'>
        <Typography>{type === Tires.summer ? t`Summer tires` : t`Winter tires`}</Typography>
        {wheelSetStatus && (
          <Stack direction='row' gap={1} alignItems='center'>
            {resolveWheelSetStatusLogo(wheelSetStatus)}
            <Typography variant='body2' color='text.secondary'>
              {wheelSetStatusToTranslation(wheelSetStatus)}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export { WheelHeader };

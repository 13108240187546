import { t } from '@lingui/macro';
import { AddressProps } from 'noddi-async/src/types';
import * as Yup from 'yup';

export const getAddressYupObject = () =>
  Yup.object({
    name: Yup.string().required(t`Name is required`),
    streetName: Yup.string().required(t`Street name is required`),
    streetNumber: Yup.string().required(t`Street number is required`),
    zipCode: Yup.string().required(t`Zip code is required`),
    city: Yup.string().required(t`City is required`),
    country: Yup.string().required(t`Country is required`),
    latitude: Yup.number().required(t`Latitude is required`),
    longitude: Yup.number().required(t`Longitude is required`),
    description: Yup.string().required(t`Description is required`),
    placeType: Yup.object({
      place_id: Yup.string().required(t`Place type is required`),
      description: Yup.string().required(t`Place type is required`),
      structured_formatting: Yup.object({
        main_text: Yup.string().required(t`Place type is required`),
        secondary_text: Yup.string().required(t`Place type is required`)
      }).required(t`Place type is required`)
    }).required(t`Place type is required`)
  });

export const getShortAddressName = (address: AddressProps) => `${address.streetName} ${address.streetNumber}`;

export const getLongAddressName = (address: AddressProps) =>
  `${address.streetName} ${address.streetNumber}, ${address.zipCode} ${address.city}, ${address.country}`;

import { AtLeastOnePartial } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { storage } from 'noddi-util';
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useState } from 'react';

import { IncompatibleServiceCategoryProps } from '../pages/BookingFlow/Steps/SalesItems/interface';
import { BookingInputFormData, SelectedSalesItem } from '../pages/BookingFlow/interfaces';
import { useWheelStore } from '../stores/WheelStore';

const defaultValuesInputData = {
  address: null,
  date: null,
  time: null,
  comment: null,
  serviceAreas: [],
  serviceAreaId: null,
  serviceCategories: [],
  selectedCars: []
};

export type BookingContextType = {
  bookingInputData: BookingInputFormData;
  salesItems: SelectedSalesItem[];
  incompatibleServiceCategories: IncompatibleServiceCategoryProps[];
  setIncompatibleServiceCategories: Dispatch<SetStateAction<IncompatibleServiceCategoryProps[]>>;
  setSalesItems: Dispatch<SetStateAction<SelectedSalesItem[]>>;
  setBookingInputData: (bookingData: BookingInputFormData) => void;
  updateBookingInputData: (newValues: AtLeastOnePartial<BookingInputFormData>) => void;
  resetTimeWindows: () => void;
  resetSalesItems: () => void;
  resetBookingData: () => void;
};

const Context = createContext<BookingContextType>({
  bookingInputData: defaultValuesInputData,
  salesItems: [],
  incompatibleServiceCategories: [],
  setIncompatibleServiceCategories: () => undefined,
  setSalesItems: () => undefined,
  setBookingInputData: () => undefined,
  updateBookingInputData: () => undefined,
  resetTimeWindows: () => undefined,
  resetBookingData: () => undefined,
  resetSalesItems: () => undefined
});

const Storage_Keys = {
  inputData: 'inputData',
  salesItems: 'salesItems',
  incompatibleServiceCategories: 'incompatibleServiceCategories'
} as const;

export function BookingContext({ children }: PropsWithChildren<unknown>) {
  const { reset: resetWheelStore } = useWheelStore();
  const { isLoggedIn } = useAuthContext();

  const [bookingInputData, setBookingInputData] = useState<BookingInputFormData>(() => {
    return storage.session.getItem(Storage_Keys.inputData) ?? defaultValuesInputData;
  });

  const [salesItems, setSalesItems] = useState<SelectedSalesItem[]>(() => {
    return storage.session.getItem(Storage_Keys.salesItems) ?? [];
  });

  const [incompatibleServiceCategories, setIncompatibleServiceCategories] = useState<
    IncompatibleServiceCategoryProps[]
  >(() => storage.session.getItem(Storage_Keys.incompatibleServiceCategories) ?? []);

  useEffect(() => {
    if (!isLoggedIn) {
      // Reset booking data when user logs out
      resetBookingData();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    // TODO: Maybe use debounce value for setting this to session storage
    storage.session.setItem(Storage_Keys.inputData, bookingInputData);
    storage.session.setItem(Storage_Keys.salesItems, salesItems);
    storage.session.setItem(Storage_Keys.incompatibleServiceCategories, incompatibleServiceCategories);
  }, [bookingInputData, salesItems, incompatibleServiceCategories]);

  function updateBookingInputData(newValues: AtLeastOnePartial<BookingInputFormData>) {
    setBookingInputData((prev) => ({ ...prev, ...newValues }));
  }

  function resetBookingInputData() {
    setBookingInputData(defaultValuesInputData);
    storage.session.removeItem(Storage_Keys.inputData);
  }

  function resetSalesItems() {
    setSalesItems([]);
    storage.session.removeItem(Storage_Keys.salesItems);
    resetWheelStore();
  }

  function resetIncompatibleServiceCategories() {
    setIncompatibleServiceCategories([]);
    storage.session.removeItem(Storage_Keys.incompatibleServiceCategories);
  }

  function resetTimeWindows() {
    updateBookingInputData({ time: null });
  }

  function resetBookingData() {
    resetBookingInputData();
    resetSalesItems();
    resetIncompatibleServiceCategories();
  }

  return (
    <Context.Provider
      value={{
        bookingInputData,
        salesItems,
        incompatibleServiceCategories,
        setSalesItems,
        setBookingInputData,
        updateBookingInputData,
        resetTimeWindows,
        setIncompatibleServiceCategories,
        resetBookingData,
        resetSalesItems
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useBookingContext() {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useBookingContext must be used within a BookingContext');
  }
  return context;
}

import { Trans, t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ErrorPage, NoddiCircularLoader } from 'noddi-ui';
import { Dispatch, SetStateAction, useState } from 'react';

import { useBookingContext } from '../../../../contexts/BookingContext';
import useFetchAddons from '../../../../hooks/ApiHooks/useFetchAddons';
import BookingScreen from '../../BookingScreen';
import { useStepValidator } from '../../hooks/useStepValidator';
import SalesItemCar from './SalesItemCar';
import useOnNextButtonClick from './useOnNextButtonClick';

export const SERVICE_CATEGORY_EXTRA = 'Tilleggstjenester';

const SalesItemsSelector = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { salesItemStepOk } = useStepValidator();
  const [showErrorPage, setShowErrorPage] = useState(false);

  const { refetch, isLoading: fetchingAddons } = useFetchAddons();

  const { navigateToNextStep } = useOnNextButtonClick({ fetchAddons: refetch });

  async function onNextButtonClicked() {
    setIsLoading(true);
    await navigateToNextStep();
    setIsLoading(false);
  }

  const enableNextButton = salesItemStepOk();

  if (showErrorPage) {
    return <ErrorPage />;
  }

  return (
    <BookingScreen seoTitle='Velg tjenester' onNextClicked={onNextButtonClicked} disableNextButton={!enableNextButton}>
      <Content isLoading={fetchingAddons || isLoading} setShowErrorPage={setShowErrorPage} />
    </BookingScreen>
  );
};

type ContentProps = {
  isLoading: boolean;
  setShowErrorPage: Dispatch<SetStateAction<boolean>>;
};
const Content = ({ isLoading, setShowErrorPage }: ContentProps) => {
  const { bookingInputData } = useBookingContext();

  const inputs = bookingInputData.selectedCars.map((car) => ({
    type: URLKeys.getSalesItemsForBooking,
    input: { carSizeId: car.carSizeId, serviceAreaIds: bookingInputData.serviceAreas.flatMap((value) => value.id) },
    queryConfig: {
      gcTime: Infinity,
      staleTime: Infinity
    }
  }));

  const results = noddiAsync.useGetAll(inputs);

  const isSalesItemsLoading = results.map((result) => result.isPending).some((loading) => loading);

  const isMultipleCarsSelected = bookingInputData.selectedCars.length > 1;

  return (
    <>
      {isLoading || isSalesItemsLoading ? (
        <>
          <Typography variant='h5' style={{ marginBottom: '1em' }}>
            <Trans>Loading available services..</Trans>
          </Typography>

          <NoddiCircularLoader style={{ minHeight: '200px' }} />
        </>
      ) : (
        <>
          <Typography variant='h5' style={{ marginBottom: '1rem' }}>
            <Trans>What can we help you with?</Trans>
          </Typography>
          <Typography marginBottom={3}>
            {isMultipleCarsSelected ? t`Please select a service for each car` : t`Please select a service`}
          </Typography>
          {bookingInputData.selectedCars.map((car, index) => {
            const availableSalesItems = results[index]?.data;
            if (!availableSalesItems) {
              setShowErrorPage(true);
              return null;
            }

            return <SalesItemCar key={car.licensePlateNumber} car={car} availableSalesItems={availableSalesItems} />;
          })}
        </>
      )}
    </>
  );
};

export default SalesItemsSelector;

import { SVGProps } from 'react';

const MenuDots = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill='none' {...props}>
    <circle cx={8.533} cy={16} r={2.133} stroke='#1F1F1F' strokeWidth={1.5} />
    <circle cx={16} cy={16} r={2.133} stroke='#1F1F1F' strokeWidth={1.5} />
    <circle cx={23.467} cy={16} r={2.133} stroke='#1F1F1F' strokeWidth={1.5} />
  </svg>
);
export { MenuDots };

import { Trans } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { useAuthContext } from 'noddi-provider';
import { NoddiButton, NoddiDialog } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../../appRoutes';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  bookingId: string;
  timeWindowId: number;
};

const EditBookingTimeWindowDialog = ({ isOpen, setIsOpen, bookingId, timeWindowId }: Props) => {
  const handleClose = () => setIsOpen(false);
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const navigate = useNavigate();

  const { mutateAsync: updateTimeWindow, isPending: isUpdateTimeWindowPending } = noddiAsync.usePatch({
    type: URLKeys.patchBooking,
    queryConfig: {
      onSuccess: async () => {
        await invalidateQueryExactMatch({
          urlKey: URLKeys.getUserBooking,
          input: { userGroupId: userGroupId as number, bookingId }
        });
        navigate(routes.myBookingDetails.getPath({ id: bookingId }));
      }
    }
  });

  return (
    <NoddiDialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth>
      <p className='mb-5 font-bold text-lg'>
        <Trans>Are you sure you want to change the time of your booking?</Trans>
      </p>

      <p className='mb-5'>
        <Trans>This is of course free of charge.</Trans>
      </p>

      <div className='flex gap-2'>
        <NoddiButton variant='secondary' onClick={handleClose} fullWidth>
          <Trans>Close</Trans>
        </NoddiButton>
        <NoddiButton
          fullWidth
          onClick={async () => await updateTimeWindow({ id: bookingId, bookingTimeWindowId: timeWindowId })}
          loading={isUpdateTimeWindowPending}
          variant='primary'
        >
          <Trans>Change time</Trans>
        </NoddiButton>
      </div>
    </NoddiDialog>
  );
};

export default EditBookingTimeWindowDialog;

import { Trans } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { ErrorPage, colors } from 'noddi-ui';

import TimeWindowPicker from '../../../../components/BookingFlow/TimeWindowPicker';
import { useBookingContext } from '../../../../contexts/BookingContext';
import BookingScreen from '../../BookingScreen';
import { useStepValidator } from '../../hooks/useStepValidator';
import useOnNextButtonClick from './useOnNextButtonClick';

const TimeWindowLayout = () => {
  const { bookingInputData: inputData } = useBookingContext();
  const { timeWindowsStepOk } = useStepValidator();

  const { navigateToNextStep } = useOnNextButtonClick();

  if (!inputData.serviceAreaId) {
    return <ErrorPage />;
  }

  const enableNext = timeWindowsStepOk();

  return (
    <BookingScreen seoTitle='Velg tidsluke' onNextClicked={navigateToNextStep} disableNextButton={!enableNext}>
      <Typography sx={{ marginTop: '1em', textAlign: 'left', width: '100%' }} mb={2} variant='h5'>
        <Trans>When is it convenient for us to come to you?</Trans>
      </Typography>
      <Stack
        boxShadow={2}
        borderRadius={3}
        padding={2}
        sx={{
          backgroundColor: colors.primary.white
        }}
      >
        <TimeWindowPicker />
      </Stack>
    </BookingScreen>
  );
};

export default TimeWindowLayout;

import { SxProps } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles/createTheme';
import { ReactNode, useState } from 'react';

import { NoddiIcon } from '../../../atoms/NoddiIcon';

interface Props {
  header: ReactNode;
  collapseBody: ReactNode;
  sx?: SxProps<Theme> | undefined;
  borderColor?: string | 'transparent';
  backgroundColor?: string;
  mountAsExpanded?: boolean;
  // use this if you need to do custom onClick logic inside collapsebody
  preventHandleExpand?: boolean;
  ExpandHelperElement?: React.ReactNode;
}

export function NoddiCollapseCard({
  header,
  collapseBody,
  sx,
  borderColor = 'transparent',
  backgroundColor = 'white',
  mountAsExpanded = false,
  preventHandleExpand = false,
  ExpandHelperElement
}: Props) {
  const [isExpanded, setIsExpanded] = useState(mountAsExpanded);
  const handleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const borderProps = borderColor === 'transparent' ? {} : { border: `1px solid ${borderColor}` };
  return (
    <Stack
      onClick={preventHandleExpand ? undefined : handleExpand}
      p={3}
      borderRadius={3}
      sx={{
        backgroundColor: backgroundColor,
        cursor: 'pointer',
        ...borderProps,
        ...sx
      }}
    >
      <Stack>
        <Stack
          onClick={preventHandleExpand ? handleExpand : undefined}
          direction='row'
          justifyContent='space-between'
          gap={1}
          alignItems='center'
          style={{ width: '100%' }}
        >
          {header}
          <Stack justifyContent='flex-end' direction='row' alignItems='center'>
            {ExpandHelperElement ? ExpandHelperElement : null}
            {isExpanded ? <NoddiIcon size={24} name='AltArrowUp' /> : <NoddiIcon name='AltArrowDown' size={24} />}
          </Stack>
        </Stack>
        <Collapse in={isExpanded} timeout='auto' unmountOnExit>
          {collapseBody}
        </Collapse>
      </Stack>
    </Stack>
  );
}

import { useSalesItems } from './useSalesItems';

const useSelectedData = () => {
  const { getSelectedSalesItemsFromCache } = useSalesItems();

  const salesItems = getSelectedSalesItemsFromCache();

  return {
    salesItems
  };
};

export default useSelectedData;

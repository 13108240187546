import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { MembershipDiscountProps } from 'noddi-async/src/types';

const MembershipDiscount = (props: { discount: MembershipDiscountProps }) => {
  const { discount } = props;

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxWidth: 600,
        mx: 'auto'
      }}
    >
      <Box sx={{ p: 3 }}>
        <Typography sx={{ mt: 2 }} variant='h6'>
          {discount.name}
        </Typography>
        <Typography color='text.secondary' sx={{ mt: 2 }} variant='body2'>
          {discount.description}
        </Typography>
      </Box>
    </Card>
  );
};

export default MembershipDiscount;

import { useAuthContext } from 'noddi-provider';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

type UseLoginRedirectProps = {
  homePath: string;
};
const useLoginRedirect = ({ homePath }: UseLoginRedirectProps) => {
  const { isLoggedIn } = useAuthContext();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl');
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate(homePath);
      }
    }
  }, [isLoggedIn]);
};

export { useLoginRedirect };

import { useMediaQuery } from '@mui/material';
import { URLKeys, getAllCachedDataFromUrlKey, noddiAsync } from 'noddi-async';
import { AvailableBookingTimeWindowsByDate } from 'noddi-async/src/types';
import { DateFormats, addDays, compareDates, datesSortCompare, differenceBetweenDates, format } from 'noddi-util';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { ApiErrorMessage } from '../../Elements/Errors';
import { NoddiCircularLoader } from '../../Elements/Loaders';
import BookingTimeWindowPickerDate, { OnSelectTimeWindowProps } from './BookingTimeWindowPickerDate';
import { SliderArrows } from './SliderArrows';

export const NUM_DAYS_TO_QUERY = 8;

type Props = {
  serviceAreaId: number;
  salesItemIds: number[];
  initialFromDate: Date;
  selectedTimeWindowId: number | null | undefined;
  loadingNode?: ReactNode;
  maxNumberOfSlidesOnPage?: number;
} & OnSelectTimeWindowProps;

const smallScreenSlidesToShow = 2;
const mediumScreenSlidesToShow = 3;
const largeScreenSlidesToShow = 5;

const getSlidesPerPage = (slidesPerPage: number, maxNumberOfSlidesOnPage: number = Infinity): number => {
  return slidesPerPage > maxNumberOfSlidesOnPage ? maxNumberOfSlidesOnPage : slidesPerPage;
};

const getSettings = (maxNumberOfSlidesOnPage: number = Infinity): Settings => {
  const small = getSlidesPerPage(smallScreenSlidesToShow, maxNumberOfSlidesOnPage);
  const medium = getSlidesPerPage(mediumScreenSlidesToShow, maxNumberOfSlidesOnPage);
  const large = getSlidesPerPage(largeScreenSlidesToShow, maxNumberOfSlidesOnPage);

  return {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: large,
    slidesToScroll: large,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: medium,
          slidesToScroll: medium
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: small,
          slidesToScroll: small
        }
      }
    ]
  };
};

export const BookingTimeWindowPicker = ({
  serviceAreaId,
  salesItemIds,
  initialFromDate,
  loadingNode,
  selectedTimeWindowId,
  maxNumberOfSlidesOnPage,
  onAvailableSelect,
  onUnavailableSelect
}: Props) => {
  const slider = useRef(null);

  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);
  const [lastVisibleIndex, setLastVisibleIndex] = useState(0);
  const isSmallScreen = useMediaQuery('(width <= 400px)');
  const isMediumScreen = useMediaQuery('(width <= 1500px)');

  const slidesOnScreen = isSmallScreen
    ? getSlidesPerPage(smallScreenSlidesToShow, maxNumberOfSlidesOnPage)
    : isMediumScreen
    ? getSlidesPerPage(mediumScreenSlidesToShow, maxNumberOfSlidesOnPage)
    : getSlidesPerPage(largeScreenSlidesToShow, maxNumberOfSlidesOnPage);

  const [fromDate, setFromDate] = useState<Date>(initialFromDate);

  const { isPending: fetchingLastTimeWindowDate, data: lastCreatedTimeWindowDate } = noddiAsync.useGet({
    type: URLKeys.getLatestCreatedBookingTimeWindowDate,
    queryConfig: {
      staleTime: Infinity
    }
  });

  const { error: timeWindowsError, isPending } = noddiAsync.useGet({
    type: URLKeys.getAvailableBookingTimeWindowsByDate,
    input: {
      serviceAreaId,
      salesItemIds,
      fromDate: format(fromDate, DateFormats.DASHED_DATE_ISO_8601),
      toDate: format(addDays(fromDate, NUM_DAYS_TO_QUERY), DateFormats.DASHED_DATE_ISO_8601)
    },
    queryConfig: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchInterval: 1000 * 60 * 4 // 4 minutes
    }
  });

  const allTimeWindowsByDate = getAllCachedDataFromUrlKey<AvailableBookingTimeWindowsByDate>({
    urlKey: URLKeys.getAvailableBookingTimeWindowsByDate
  }).sort((a, b) => datesSortCompare(a.date, b.date, 'asc'));

  const lastDate = allTimeWindowsByDate[lastVisibleIndex]?.date;
  const lastVisibleDate = lastDate ? new Date(lastDate) : undefined;
  const lastCreatedDate = lastCreatedTimeWindowDate ? new Date(lastCreatedTimeWindowDate) : undefined;

  const disableNextSlide =
    !!lastCreatedDate &&
    !!lastVisibleDate &&
    (compareDates(lastVisibleDate, lastCreatedDate, 'areEqual') ||
      compareDates(lastVisibleDate, lastCreatedDate, 'isAfter'));

  useEffect(() => {
    // don't need to refetch if we're on first slide. We can assume that the data is there already
    if (currentSlideNumber === 0) {
      return;
    }

    // Get first date object being displayed
    const fistObject = allTimeWindowsByDate[currentSlideNumber];
    if (!fistObject) {
      return;
    }

    // Get the last queried date object
    const lastObject = allTimeWindowsByDate[allTimeWindowsByDate.length - 1];
    if (!lastObject) {
      return;
    }

    // Get the number of days between the first date object and the last queried date object
    const daysDifference = differenceBetweenDates(lastObject.date, fistObject.date, 'days');

    // If the difference is greater than the number of days to query, then we don't need to query again
    if (daysDifference > NUM_DAYS_TO_QUERY) {
      return;
    }

    // Otherwise, we need to query again
    setFromDate(addDays(fromDate, NUM_DAYS_TO_QUERY) ?? new Date());
  }, [currentSlideNumber]);

  if ((isPending || fetchingLastTimeWindowDate) && allTimeWindowsByDate.length === 0) {
    return loadingNode || <NoddiCircularLoader />;
  }
  if (timeWindowsError) {
    return <ApiErrorMessage error={timeWindowsError} />;
  }

  return (
    <>
      <SliderArrows slider={slider} isLoading={isPending} disableNextSlide={disableNextSlide} />

      <Slider
        ref={slider}
        beforeChange={(_, next) => {
          if (disableNextSlide) {
            return;
          }
          setCurrentSlideNumber(next);
        }}
        afterChange={(currentSlide) => {
          setLastVisibleIndex(currentSlide + slidesOnScreen - 1);
        }}
        {...getSettings(maxNumberOfSlidesOnPage)}
      >
        {allTimeWindowsByDate.map((timeWindowsByDate) => (
          <BookingTimeWindowPickerDate
            key={timeWindowsByDate.date}
            selectedTimeWindowId={selectedTimeWindowId}
            timeWindowsByDate={timeWindowsByDate}
            onAvailableSelect={onAvailableSelect}
            onUnavailableSelect={onUnavailableSelect}
            isLoading={isPending}
          />
        ))}
      </Slider>
    </>
  );
};

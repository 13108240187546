import { t } from '@lingui/macro';
import html2canvas from 'html2canvas';
import { QRCodeProps } from 'react-qr-code';
import styled from 'styled-components';

import { NoddiButton } from '../Buttons';
import { BaseQRCodeDisplay } from './BaseQRCodeDisplay';

type TextProps = {
  text?: string;
  fontSize?: string | number;
  fontColor?: string;
  fontBackgroundColor?: string;
};

type BorderProps = {
  border?: boolean;
  borderPaddingPx?: number;
  borderSizePx?: number;
};

export type BaseProps = QRCodeProps &
  TextProps &
  BorderProps & {
    id?: string;
    download?: boolean;
  };

// If download is true, id is required.
type WithDownloadProps = BaseProps & {
  download: true;
  id: string;
};

// If download is false, id is optional.
type WithoutDownloadProps = BaseProps & {
  download?: false;
  id?: string;
};

// Union type that enforces the presence of `id` when `download` is true.
export type QRCodeDisplayProps = WithDownloadProps | WithoutDownloadProps;

const CenterDiv = styled.div`
  justify-content: center;
  align-items: center;
  width: max-content;
`;

const BorderDiv = styled.div<{
  borderPaddingPx: number;
  borderSizePx: number;
}>`
  text-align: center;
  padding: ${(props) => props.borderPaddingPx}px;
  border: ${(props) => props.borderSizePx}px solid black;
  border-radius: 15px;
`;

const TextDiv = styled.div<{
  fontSize: string | number;
  fontColor?: string;
  fontBackgroundColor?: string;
  maxWidth: number;
}>`
  text-align: center;
  margin-top: 10px;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.fontColor};
  background-color: ${(props) => props.fontBackgroundColor};
  border: 10px solid ${(props) => props.fontBackgroundColor};
  border-radius: 15px;
  max-width: ${(props) => props.maxWidth}px;
`;

const DownloadButton = ({ id, text, width }: { id: string; text?: string; width?: number }) => (
  <NoddiButton
    variant='primary'
    style={{ marginBottom: '24px', width: width || 'auto' }}
    onClick={async () => {
      const element = document.getElementById(id);
      if (!element) {
        return;
      }

      const canvas = await html2canvas(element, {
        backgroundColor: null,
        scale: 2
      });

      const url = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = url;
      a.download = text ? `${text}.png` : 'qr-code.png';
      a.click();
    }}
  >
    {t`Download as PNG`}
  </NoddiButton>
);

export const QRCodeRowDisplay = ({
  id,
  value,
  size = 256,
  bgColor = 'white',
  fgColor = 'black',
  level = 'L',
  border = true,
  borderPaddingPx = 30,
  borderSizePx = 10,
  text,
  fontSize = 16,
  fontColor = 'white',
  fontBackgroundColor = 'black',
  download = false
}: QRCodeDisplayProps) => {
  let width = size;
  if (border) {
    width += borderPaddingPx * 2 + borderSizePx * 2;
  }

  const Content = (
    <CenterDiv id={id}>
      {border ? (
        <BorderDiv borderPaddingPx={borderPaddingPx} borderSizePx={borderSizePx}>
          <BaseQRCodeDisplay value={value} size={size} bgColor={bgColor} fgColor={fgColor} level={level} />
        </BorderDiv>
      ) : (
        <BaseQRCodeDisplay value={value} size={size} bgColor={bgColor} fgColor={fgColor} level={level} />
      )}

      {text && (
        <TextDiv fontSize={fontSize} fontColor={fontColor} fontBackgroundColor={fontBackgroundColor} maxWidth={width}>
          {text}
        </TextDiv>
      )}
    </CenterDiv>
  );

  return download && id ? (
    <>
      <DownloadButton id={id} text={text} width={width} />
      {Content}
    </>
  ) : (
    Content
  );
};

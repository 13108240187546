import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { AddressPicker, NoddiLinearProgressLoader, SelectedAddressDetails } from 'noddi-ui';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useBookingContext } from '../../contexts/BookingContext';
import { GoogleTrackingService } from '../../services/GoogleTrackingService';
import { GtmEvents } from '../../types/gtmTracking';

const AddressPickerComponent = () => {
  const { currentUserGroupId: userGroupId, isLoggedIn } = useAuthContext();
  const { bookingInputData, updateBookingInputData } = useBookingContext();
  const { address } = bookingInputData;

  const {
    isLoading: isServiceAreasLoading,
    isError: isServiceAreaError,
    refetch: refetchServiceAreas,
    data: serviceAreas
  } = noddiAsync.useGet({
    type: URLKeys.getServiceAreasFromCoordinate,
    input: { lat: address?.latitude, lng: address?.longitude },
    queryConfig: {
      enabled: Boolean(address),
      staleTime: Infinity
    }
  });

  const { isLoading: userGroupAddressesIsLoading, data: userGroupAddresses } = noddiAsync.useGet({
    type: URLKeys.getUserGroupAddresses,
    input: { userGroupId: userGroupId as number },
    queryConfig: { enabled: !!userGroupId, staleTime: Infinity }
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const placeIdFromUrl = searchParams.get('address');
  const showProgressLoader = isServiceAreasLoading || userGroupAddressesIsLoading;

  const hasServiceCategories = Boolean(serviceAreas?.serviceCategories?.length ?? 0 > 0);

  useEffect(() => {
    if (address) {
      refetchServiceAreas();
    }
  }, [address]);

  // sets default address if a user has a profile and has addresses
  useEffect(() => {
    if (userGroupAddresses && userGroupAddresses?.length > 0) {
      const userGroupAddress = userGroupAddresses[0];
      if (userGroupAddress && !address) {
        updateBookingInputData({ address: userGroupAddress.address });
      }
    }
  }, [userGroupAddresses]);

  // each time the address changes, we want to update the serviceAreas and serviceCategories
  // that the user can choose from later in the booking flow
  useEffect(() => {
    if (serviceAreas) {
      updateBookingInputData({
        serviceAreas: serviceAreas?.serviceAreas,
        serviceCategories: serviceAreas?.serviceCategories ?? []
      });
    }

    if (serviceAreas && !hasServiceCategories) {
      GoogleTrackingService.trackEvent({
        eventType: GtmEvents.noServiceOffered
      });
    }
  }, [serviceAreas]);

  const addressDropdownOptions = useMemo(() => {
    if (!address || !userGroupAddresses) {
      return [];
    }

    const mappedAddresses = userGroupAddresses.map((item) => item.address);
    // Check if the provided address already exists in userGroupAddresses
    const addressExists = mappedAddresses.some(
      (userAddress) => userAddress.latitude === address.latitude && userAddress.longitude === address.longitude
    );
    if (addressExists) {
      return mappedAddresses;
    }
    const uniqueAddresses = [...mappedAddresses, address];

    return uniqueAddresses;
  }, [userGroupAddresses, address]);

  return (
    <div style={{ marginTop: 24, width: '100%' }}>
      <Typography variant='h5' mb={2}>
        <Trans>Write the address we should come to</Trans>
      </Typography>

      <AddressPicker
        addresses={addressDropdownOptions ?? []}
        onSelectAddress={(address) => {
          if (!address) {
            return;
          }
          const updatedAddress = { ...address };
          updateBookingInputData({ address: updatedAddress });
        }}
        selectedAddress={address}
        placeIdFromUrl={placeIdFromUrl}
        initWithCustomSearch={!isLoggedIn}
        style={{ marginTop: '1rem' }}
      />

      {showProgressLoader ? (
        <NoddiLinearProgressLoader />
      ) : (
        <SelectedAddressDetails
          isServiceAreaError={isServiceAreaError}
          serviceAreas={serviceAreas}
          hasServiceCategories={hasServiceCategories}
          address={address}
        />
      )}
    </div>
  );
};

export default AddressPickerComponent;

import { t } from '@lingui/macro';
import * as yup from 'yup';

const userRegistrationFormSchema = (skipPasswordCreation?: boolean) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(t`This field is required`)
      .min(2, t`This field must be at least 2 characters long`),
    surname: yup
      .string()
      .required(t`This field is required`)
      .min(2, t`This field must be at least 2 characters long`),
    email: yup
      .string()
      .required(t`This field is required`)
      .email(t`Must provide a valid email address`),
    password: skipPasswordCreation
      ? yup.string().notRequired()
      : yup
          .string()
          .required(t`This field is required`)
          .min(8, t`The password must be at least 8 characters long`),
    confirmPassword: skipPasswordCreation
      ? yup.string().notRequired()
      : yup
          .string()
          .required(t`This field is required`)
          .oneOf([yup.ref('password')], t`Passwords must match`)
  });

export default userRegistrationFormSchema;

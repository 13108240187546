import { SVGProps } from 'react';

const QuestionMark = (props: SVGProps<SVGSVGElement>) => (
  <svg width={30} height={30} fill='none' {...props}>
    <path
      stroke='#1C274C'
      strokeWidth={1.5}
      d='M5 15c0-4.714 0-7.071 1.464-8.536C7.93 5 10.286 5 15 5c4.714 0 7.071 0 8.535 1.464C25 7.93 25 10.286 25 15c0 4.714 0 7.071-1.465 8.535C22.072 25 19.714 25 15 25s-7.071 0-8.536-1.465C5 22.072 5 19.714 5 15Z'
    />
    <path
      stroke='#1C274C'
      strokeLinecap='round'
      strokeWidth={1.5}
      d='M13.125 11.875a1.875 1.875 0 1 1 2.828 1.615c-.475.281-.953.708-.953 1.26V16'
    />
    <circle cx={15} cy={19} r={1} fill='#1C274C' />
  </svg>
);
export { QuestionMark };

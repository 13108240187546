import { Box, Container, ContainerProps } from '@mui/material';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { NoddiCircularLoader } from '../Loaders';
import { BreadcrumbProps, NoddiBreadcrumbs } from '../Navigation';
import { NoddiHeader } from '../NoddiHeader';
import { NoddiSEO } from '../NoddiSEO';

interface Props {
  children?: ReactNode;
  header?: string;
  description?: string;
  headerNode?: ReactNode;
  seoTitle?: string;
  breadcrumbProps?: BreadcrumbProps;
  contentContainerProps?: ContainerProps;
  maxWidth?: ContainerProps['maxWidth'];
  headerContainerProps?: ContainerProps;
  isLoading?: boolean;
}

const Header = styled.header`
  background-color: white;
  padding: 2rem 0;
`;

export function NoddiContainer({
  children,
  header,
  description,
  headerNode,
  seoTitle,
  breadcrumbProps,
  maxWidth,
  contentContainerProps,
  headerContainerProps,
  isLoading
}: ContainerProps & Props) {
  const containerMaxWitdh = maxWidth ?? 'xl';
  return (
    <>
      <Header>
        <Container maxWidth={containerMaxWitdh} {...headerContainerProps}>
          {breadcrumbProps && <NoddiBreadcrumbs sx={{ marginY: '1rem' }} {...breadcrumbProps} />}
          {header && <NoddiHeader title={header} description={description} node={headerNode} />}
          {seoTitle ? <NoddiSEO title={seoTitle} /> : header && <NoddiSEO title={header} />}
        </Container>
      </Header>
      <Container maxWidth={containerMaxWitdh} {...contentContainerProps}>
        <Box sx={{ marginTop: '2rem' }} />
        {isLoading ? <NoddiCircularLoader /> : children}
        <Box sx={{ marginTop: '2rem' }} />
      </Container>
    </>
  );
}

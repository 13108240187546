import { SVGProps } from 'react';

const UpArrowAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill='none' {...props}>
    <path
      stroke='#1F1F1F'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M23.467 19.2 16 12.8l-7.467 6.4'
    />
  </svg>
);
export { UpArrowAlt };

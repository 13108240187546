import { SVGProps } from 'react';

const LeftArrowAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill='none' {...props}>
    <path
      stroke='#1F1F1F'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M19.2 8.533 12.8 16l6.4 7.467'
    />
  </svg>
);
export { LeftArrowAlt };

import { Trans, t } from '@lingui/macro';
import { Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import { URLKeys } from 'noddi-async';
import { AvailableSalesItem, SalesItemSlugs, wheelStorageOption } from 'noddi-async/src/types';
import {
  AvailableAddressPicker,
  ErrorPage,
  FieldWrapper,
  NoddiIcon,
  TypographyWithCustomIcon,
  TypographyWithInfoSign,
  useIsMobile
} from 'noddi-ui';

import { CardButton } from '../../../../components/Buttons/CardButton';
import { GridMinWidthItems } from '../../../../components/Layouts/GridMinWidthItems';
import { useBookingContext } from '../../../../contexts/BookingContext';
import useSelectSalesItem from '../../../../hooks/useSelectSalesItem';
import { useWheelStore } from '../../../../stores/WheelStore';
import { getCarDisplayText } from '../../../../utils/car';
import { SelectedCar } from '../../interfaces';

type Props = {
  licensePlateNumber: SelectedCar['licensePlateNumber'];
  wheelStorageOptions: AvailableSalesItem[];
};

const getIcon = (salesItem: AvailableSalesItem) => {
  let imgUrl;
  switch (salesItem.slug) {
    case SalesItemSlugs['nb-at-your-place-free']:
      imgUrl = '/assets/svgs/CarryingTires.svg';
      break;
    case SalesItemSlugs['nb-at-car-dealer']:
      imgUrl = '/assets/svgs/Warehouse.svg';
      break;
    case SalesItemSlugs['nb-at-another-tire-hotel']:
      imgUrl = '/assets/svgs/tire-storage.svg';
      break;
    default:
      imgUrl = '/assets/svgs/CarryingTires.svg';
      break;
  }
  return <img src={imgUrl} width={80} height={80} />;
};

const mapSalesItemToStorageType = (salesItem: AvailableSalesItem) => {
  switch (salesItem.slug) {
    case SalesItemSlugs['nb-at-your-place-free']:
      return wheelStorageOption.customer;
    case SalesItemSlugs['nb-at-car-dealer']:
      return wheelStorageOption.carDealer;
    case SalesItemSlugs['nb-at-another-tire-hotel']:
      return wheelStorageOption.wheelStorage;
    default:
      return wheelStorageOption.customer;
  }
};

const WheelStorageSelector = ({ licensePlateNumber, wheelStorageOptions }: Props) => {
  const { bookingInputData } = useBookingContext();
  const { selectWheelStorageSalesItems } = useSelectSalesItem();

  const { selectedWheelStorageOptions, addSelectedWheelStorageOptions, removeSelectedWheelStorageOptions } =
    useWheelStore();

  const storageOptionsForVehicle = selectedWheelStorageOptions.find(
    (option) => option.licensePlateNumber === licensePlateNumber
  );
  const storageType = storageOptionsForVehicle?.storageType;

  const isMobile = useIsMobile();
  const isSmallScreen = useMediaQuery('(max-width:400px)');
  const buttonHeight = isMobile ? 160 : 170;
  const buttonWidth = isSmallScreen ? 160 : 180;
  const address = bookingInputData.address;
  const cars = bookingInputData.selectedCars;

  const carProps = cars.find((car) => car.licensePlateNumber === licensePlateNumber);
  if (!carProps || !address) {
    return <ErrorPage />;
  }

  return (
    <>
      <TypographyWithCustomIcon variant='h6' icon={<NoddiIcon name='Car' size={24} />}>
        {getCarDisplayText(carProps)}
      </TypographyWithCustomIcon>
      <FieldWrapper>
        <GridMinWidthItems>
          {wheelStorageOptions.map((option) => {
            const salesItemStorageType = mapSalesItemToStorageType(option);
            return (
              <CardButton
                key={`${salesItemStorageType} ${option.id}`}
                onClick={() => {
                  // this selectSalesItem function also considers the case for deselection, so it is safe
                  // to use before checking the type of storage

                  selectWheelStorageSalesItems({
                    licensePlateNumber,
                    salesItem: option,
                    isAddon: false,
                    queryParams: { urlKey: URLKeys.getWheelStorageSalesItemsOptions, queryInput: undefined }
                  });

                  if (storageType === salesItemStorageType) {
                    return removeSelectedWheelStorageOptions(licensePlateNumber);
                  }
                  addSelectedWheelStorageOptions({
                    licensePlateNumber,
                    storageType: salesItemStorageType,
                    pickUpAddress: salesItemStorageType === wheelStorageOption.customer ? address : undefined
                  });
                }}
                width={buttonWidth}
                height={buttonHeight}
                isActive={storageType === salesItemStorageType}
              >
                <Stack alignItems='center'>
                  {getIcon(option)}
                  <Typography fontSize='13px' color='black' fontWeight={700}>
                    {option.price},-
                  </Typography>
                  <Typography color='black'>{option.name}</Typography>
                </Stack>
              </CardButton>
            );
          })}
        </GridMinWidthItems>

        {storageType && storageType !== wheelStorageOption.customer && (
          <div>
            <label>
              {' '}
              {storageType === wheelStorageOption.carDealer
                ? t`Type in your car dealer`
                : t`Type in your tire storage provider`}{' '}
            </label>

            <AvailableAddressPicker
              clearAddress={() => {
                addSelectedWheelStorageOptions({
                  licensePlateNumber,
                  pickUpAddress: undefined
                });
              }}
              onSelectAddress={(address) => {
                if (address) {
                  addSelectedWheelStorageOptions({
                    licensePlateNumber,
                    pickUpAddress: address
                  });
                }
              }}
              addresses={[]}
            />
          </div>
        )}
        {storageType === wheelStorageOption.customer && (
          <TypographyWithInfoSign>
            <Trans>
              This is the address you provided in step 1:{' '}
              <i>{`${address?.name ?? address?.fullName} ${address?.streetNumber}`}</i>
            </Trans>
          </TypographyWithInfoSign>
        )}
      </FieldWrapper>
    </>
  );
};

export default WheelStorageSelector;

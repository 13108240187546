import { SVGProps } from 'react';

interface PlusProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const Plus = ({ color = '#1C274C', ...props }: PlusProps) => (
  <svg width={30} height={30} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path stroke={color} strokeWidth={1.5} strokeLinecap='round' d='M15 5v20m-10-10h20' />
  </svg>
);

export { Plus };

import { SVGProps } from 'react';

interface HamburgerMenuProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const HamburgerMenu = ({ color = '#1F1F1F', ...props }: HamburgerMenuProps) => (
  <svg width={32} height={32} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeWidth={2}
      d='M24.533 10.667H7.467M24.533 16H7.467M24.533 21.333H7.467'
    />
  </svg>
);

export { HamburgerMenu };

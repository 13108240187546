import { t } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { KeyValueRow, NoddiChip, NoddiIcon } from 'noddi-ui';

type Props = {
  isCancelled?: boolean;
  slug?: string;
  address?: string;
  getDateText: () => string;
};

export function MetaSection({ isCancelled, slug, address, getDateText }: Props) {
  return (
    <>
      {isCancelled && (
        <KeyValueRow
          header={
            <div className='mb-4'>
              <NoddiChip label={t`Canceled`} variant='quaternary' />
            </div>
          }
          showDivider={false}
        />
      )}
      {slug && (
        <KeyValueRow
          header={
            <Stack alignItems='center' direction='row' spacing={1}>
              <NoddiIcon size={24} name='Magnifier' />
              <Typography color='text.secondary' variant='body2'>
                {slug}
              </Typography>
            </Stack>
          }
          showDivider={false}
        />
      )}
      {address && (
        <KeyValueRow
          header={
            <Stack alignItems='center' direction='row' spacing={1}>
              <NoddiIcon name='LocationPin' size={24} />

              <Typography color='text.secondary' variant='body2'>
                {address}
              </Typography>
            </Stack>
          }
          showDivider={false}
        />
      )}
      <KeyValueRow
        header={
          <Stack alignItems='center' direction='row' spacing={1}>
            <NoddiIcon name='Calendar' size={24} />
            <Typography color='text.secondary' variant='body2'>
              {getDateText()}
            </Typography>
          </Stack>
        }
        showDivider={false}
      />
    </>
  );
}

import { useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ServerTypes } from 'noddi-async';
import { NoddiIcon, colors, useIsMobile } from 'noddi-ui';
import { translateServicesLabels } from 'noddi-ui/src/components/Domains/Address/utils';
import styled from 'styled-components';

import { CardButton } from '../../../../components/Buttons/CardButton';
import { getSalesItemIcon } from '../../../../helper/salesItems';
import { SERVICE_CATEGORY_EXTRA } from './index';

type Props = {
  category: string;
  expandedCategory: string;
  selectedItemsCount: number;
  salesItems: ServerTypes.AvailableSalesItem[];
  setExpandedCategory: (value: string) => void;
};

const SelectedSalesItemsCount = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: ${colors.primary.orange};
  border-radius: 5px;
  padding: 1px 12px;
  color: white;
  font-size: 0.8rem;
`;

const ExtraServicesBadge = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
`;

const SalesItemCategoryButton = ({ category, expandedCategory, setExpandedCategory, selectedItemsCount }: Props) => {
  const extraCategoryIsEmpty = category === SERVICE_CATEGORY_EXTRA;
  const extraCategoryIsAvailable = category === SERVICE_CATEGORY_EXTRA;
  const isMobile = useIsMobile();
  const isSmallScreen = useMediaQuery('(max-width:400px)');

  const onClick = () => {
    if (expandedCategory === category) {
      setExpandedCategory('');
      return;
    }

    setExpandedCategory(category);
  };

  const isActive = expandedCategory === category;
  const buttonHeight = isMobile ? 140 : 160;
  const buttonWidth = isSmallScreen ? 160 : 180;

  return (
    <>
      <CardButton
        onClick={onClick}
        disabled={extraCategoryIsEmpty}
        isActive={isActive}
        height={buttonHeight}
        width={buttonWidth}
      >
        {selectedItemsCount > 0 && <SelectedSalesItemsCount>{selectedItemsCount}</SelectedSalesItemsCount>}
        {extraCategoryIsAvailable && (
          <ExtraServicesBadge>
            <NoddiIcon name='Star' color='#ffd34d' />
          </ExtraServicesBadge>
        )}

        <Stack spacing={2} alignItems='center'>
          {getSalesItemIcon({ category })}
          <Typography color={colors.primary.black} style={{ marginTop: '-5px' }}>
            {translateServicesLabels(category)}
          </Typography>
        </Stack>
      </CardButton>
    </>
  );
};

export default SalesItemCategoryButton;

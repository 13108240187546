import { Typography } from '@mui/material';
import { TireHotelContract } from 'noddi-async/src/types';
import { SimpleResponsiveGridLayout } from 'noddi-ui';
import styled from 'styled-components';

import { getHeaderTexts } from './BookingFlow/Components/actions';
import { useTireHotelContext } from './BookingFlow/context';
import ContractCard from './ContractCard';
import InfoCard from './InfoCard';
import { TireHotelProps } from './types';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

type ContractListProps = { tireHotelContracts: TireHotelContract[] } & Pick<TireHotelProps, 'tireHotelOptions'>;

const ContractList = ({ tireHotelContracts, tireHotelOptions }: ContractListProps) => {
  const headerTexts = getHeaderTexts();
  const { setSelectedTireHotelContracts, selectedTireHotelContracts, selectedAction } = useTireHotelContext();

  const headerText = selectedAction && headerTexts[selectedAction];

  return (
    <Layout>
      <div>
        {headerText && tireHotelContracts.length > 1 && (
          <Typography variant='h5' marginBottom={3}>
            {headerText}
          </Typography>
        )}

        <SimpleResponsiveGridLayout smBreakpoint='1350px' mdBreakpoint='1550px'>
          {tireHotelContracts.map((contract) => {
            const selectedIds = selectedTireHotelContracts.map((x) => x.id);
            const vinNumber = contract.car.vinNumber;
            const tireHotelOption = tireHotelOptions.find((option) => option.vinNumber === vinNumber);

            const shouldDisable =
              (selectedAction === 'cancel' && !tireHotelOption?.tireHotel?.canTerminateTireHotel) ||
              (selectedAction === 'order' && !tireHotelOption?.tireHotel?.canRenewTireHotel);

            return (
              <ContractCard
                key={contract.id}
                mode={selectedAction && tireHotelContracts.length > 1 ? 'select' : 'view'}
                tireHotelContract={contract}
                selected={selectedIds.includes(contract.id)}
                disabled={shouldDisable}
                onSelect={(contract) => {
                  if (selectedIds.includes(contract.id)) {
                    setSelectedTireHotelContracts(
                      selectedTireHotelContracts.filter((selected) => selected.id !== contract.id)
                    );
                  } else {
                    setSelectedTireHotelContracts([...selectedTireHotelContracts, contract]);
                  }
                }}
              />
            );
          })}
        </SimpleResponsiveGridLayout>
      </div>
      <InfoCard />
    </Layout>
  );
};

export default ContractList;

import { TireHotelProvider } from '../BookingFlow/context';
import TireHotelDetailContent from './TireHotelDetails';

const TireHotelDetailPage = () => {
  return (
    <TireHotelProvider>
      <TireHotelDetailContent />
    </TireHotelProvider>
  );
};

export default TireHotelDetailPage;

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useAuthContext } from 'noddi-provider';
import { NoddiButton, NoddiDialog, NoddiIcon, colors } from 'noddi-ui';
import { useState } from 'react';

import ChooseImpersonateUser from './ChooseSuperUser';

const ImpersonateUserButton = () => {
  const { isSuperUser } = useAuthContext();
  const [isSuperUserModalOpen, setIsSuperUserModalOpen] = useState(false);

  return isSuperUser ? (
    <>
      <Stack padding={1}>
        <div className='mx-4'>
          <NoddiButton
            variant='quaternary'
            onClick={() => setIsSuperUserModalOpen(true)}
            startIcon={<NoddiIcon name='Users' size={24} color={colors.primary.black} />}
          >
            Impersonate user
          </NoddiButton>
        </div>
      </Stack>
      <NoddiDialog
        open={isSuperUserModalOpen}
        onClose={() => setIsSuperUserModalOpen(false)}
        title='Impersonate user'
        maxWidth='xl'
      >
        <Stack justifyContent='space-between'>
          <Stack style={{ marginTop: 22, marginBottom: 22 }}>
            <Typography color='text.secondary'>
              By choosing a user from this list, you will impersonate that user. This means that you will be able to see
              the application as that user, and perform actions on behalf of that user
            </Typography>
          </Stack>
          <ChooseImpersonateUser handleClose={() => setIsSuperUserModalOpen(false)} />
        </Stack>
      </NoddiDialog>
    </>
  ) : null;
};

export default ImpersonateUserButton;

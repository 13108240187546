import { Trans } from '@lingui/macro';
import { Switch, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { LicenseAreaListItem } from 'noddi-async/src/types';
import { Nullable } from 'noddi-util';
import { Dispatch, SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useIsMobile } from '../../../../hooks';
import NoddiActiveFilterChips from '../../../Elements/Tables/Filters/ActiveFilterChips';
import { NoddiTableFilterList } from '../../../Elements/Tables/Filters/NoddiTableFilterList';
import useFromDateFilter from './useDateFilter';
import { useLicenseAreaFilters } from './useLicenseAreaFilters';

type RouteStatusFiltersProps = {
  licenseAreas: LicenseAreaListItem[] | undefined;
  selectedLicenseAreaId: Nullable<number>;
  setSelectedLicenseAreaId: (id: number | null) => void;
  fromDate: Date;
  setFromDate: (date: Date) => void;
  isAttentionMode: boolean;
  setIsAttentionMode: Dispatch<SetStateAction<boolean>>;
};

const Card = styled.div`
  padding: 1rem;
  border-radius: 20px;
  background-color: white;
  margin-bottom: 1rem;
`;

const RouteStatusFilters = ({
  licenseAreas,
  selectedLicenseAreaId,
  setSelectedLicenseAreaId,
  fromDate,
  setFromDate,
  isAttentionMode,
  setIsAttentionMode
}: RouteStatusFiltersProps) => {
  const licenseAreaFilter = useLicenseAreaFilters({
    licenseAreas,
    selectedLicenseAreaId,
    setSelectedLicenseAreaId
  });
  const dateFilter = useFromDateFilter({ fromDate, setFromDate });
  const clearFilters = () => {
    licenseAreaFilter.clearFilter();
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const filters = [licenseAreaFilter, dateFilter];

  const isMobile = useIsMobile();
  return (
    <Card>
      <Stack direction='column'>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          gap={0.5}
          width='100%'
          justifyContent='space-between'
          marginBottom={1}
        >
          <Stack direction='row' gap={0.5}>
            <NoddiTableFilterList filters={filters} clearFilters={clearFilters} />
          </Stack>
          <Stack direction='row' gap={0.5} alignItems='center'>
            <Stack spacing={1}>
              <Typography style={{ fontSize: 12 }}>
                <Trans>Receive live updates</Trans>
              </Typography>
            </Stack>
            <Switch
              checked={isAttentionMode}
              onChange={(e) => {
                searchParams.set('isAttentionMode', e.target.checked.toString());
                setSearchParams(searchParams);
                setIsAttentionMode(e.target.checked);
              }}
              name='is_attention'
            />
          </Stack>
        </Stack>
        <NoddiActiveFilterChips filters={filters} />
      </Stack>
    </Card>
  );
};

export { RouteStatusFilters };

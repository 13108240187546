import { SVGProps } from 'react';

interface EditProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const Edit = ({ color = '#1F1F1F', ...props }: EditProps) => (
  <svg width={32} height={32} fill='none' {...props}>
    <path stroke={color} strokeLinecap='round' strokeWidth={2} d='M7.467 26.667h17.066' />
    <path
      stroke={color}
      strokeWidth={2}
      d='m18.014 7.107.79-.79a3.356 3.356 0 0 1 4.747 4.745l-.791.79m-4.746-4.745s.099 1.68 1.582 3.164c1.483 1.483 3.163 1.582 3.163 1.582m-4.745-4.746-7.271 7.272c-.493.492-.74.738-.951 1.01-.25.32-.464.667-.639 1.034-.148.31-.258.64-.478 1.302l-.934 2.8m15.018-8.672-7.27 7.271c-.493.493-.74.739-1.011.95-.32.25-.667.465-1.034.64-.31.148-.641.258-1.302.478l-2.8.933m0 0-.684.229a.905.905 0 0 1-1.145-1.145l.228-.684m1.601 1.6-1.6-1.6'
    />
  </svg>
);

export { Edit };

import { FormikErrors } from 'formik';
import styled from 'styled-components';

const ErrorMessage = styled.p`
  color: red;
`;

const FieldArrayErrorMessage = <T,>({ errors, name }: { errors: FormikErrors<T>; name: keyof T }) => {
  const error = errors[name];
  return typeof error === 'string' ? <ErrorMessage>{error}</ErrorMessage> : null;
};

export { FieldArrayErrorMessage };

import { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { colors } from '../../../../tailwind-design-preset';

type CustomDefaultProps = {
  height?: string;
};

export const DefaultStyles = styled.div<CustomDefaultProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  ${({ height }) => `height: ${height ? height : '20vh'};`}
  height: 20vh;
  background-color: ${colors.primary.white};
`;

type Props = HTMLAttributes<HTMLDivElement> & CustomDefaultProps;
export const StickyBottomDiv = (props: Props) => <DefaultStyles {...props} />;

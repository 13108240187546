import { Card, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { UserGroupAddress } from 'noddi-async/src/types';
import { NoddiIcon, getLongAddressName } from 'noddi-ui';

type Props = {
  userGroupAddress: UserGroupAddress;
};

const Address = ({ userGroupAddress }: Props) => {
  const { address } = userGroupAddress;
  return (
    <Card sx={{ p: 2, my: 1, textDecoration: 'none', borderRadius: '14px' }}>
      <Stack direction='row' justifyContent='flex-start' gap={1} alignItems='center'>
        <NoddiIcon name='LocationPin' size={32} />
        <Stack>
          {userGroupAddress.name && <Typography variant='h6'>{userGroupAddress.name}</Typography>}
          <Typography variant='body2'>{getLongAddressName(address)}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default Address;

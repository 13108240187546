import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ApiErrorMessage, NoddiButton, NoddiIcon, colors } from 'noddi-ui';
import styled from 'styled-components';

import PaymentForm from './PaymentForm';

const HasPayedContainer = styled.div`
  background-color: ${colors.primary.darkPurple30};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  margin-top: 24px;
`;

export default function PaymentInfo({
  orderId,
  hasPayed,
  paymentMethod
}: {
  orderId: number;
  hasPayed: boolean;
  paymentMethod: string;
}) {
  const {
    mutateAsync: createDineroSession,
    isPending: isDineroSessionPending,
    isSuccess: isCreateDineroSessionComplete,
    data: dineroSession,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postDinteroSession
  });

  return (
    <>
      {hasPayed ? (
        <HasPayedContainer>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <NoddiIcon name='Check' color={colors.primary.orange} className='mr-1' />

            <Typography ml={2} fontWeight={500}>
              {paymentMethod.toLowerCase().includes('invoice')
                ? t`Thanks! You will receive an invoice shortly`
                : t`Payment completed`}
            </Typography>
          </div>
        </HasPayedContainer>
      ) : isCreateDineroSessionComplete ? (
        <PaymentForm sessionId={dineroSession.data.sessionId} />
      ) : (
        <>
          {error && <ApiErrorMessage error={error} />}
          <NoddiButton
            style={{ marginTop: '24px' }}
            loading={isDineroSessionPending}
            onClick={async () => {
              await createDineroSession({
                orderId
              });
            }}
          >
            <Trans>Pay now</Trans>
          </NoddiButton>
        </>
      )}
    </>
  );
}

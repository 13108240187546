import TireHotelPageContent from './BookingFlow/Components/TireHotelPageContent';
import { TireHotelProvider } from './BookingFlow/context';

const TireStorage = () => {
  return (
    <TireHotelProvider>
      <TireHotelPageContent />
    </TireHotelProvider>
  );
};

export default TireStorage;

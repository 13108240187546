import { Container, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { NoddiIconButton, NoddiSelectableItem, ServiceItem } from 'noddi-ui';

const BookingPlaygroundPage = () => {
  return (
    <>
      <Container maxWidth='sm'>
        <Stack gap={5}>
          <Stack gap={3}>
            <Typography>NoddiIconButton</Typography>
            <NoddiIconButton variant='primary' iconName='Cross' iconSize={32} isLoading={true} />
            <NoddiIconButton iconName='ArrowRight' iconSize={32} variant='primary' />
            <NoddiIconButton iconName='HamburgerMenu' iconSize={24} variant='secondary' />
            <NoddiIconButton iconName='LeftArrowAlt' iconSize={24} variant='primary' />
            <NoddiIconButton iconName='RightArrowAlt' iconSize={16} variant='secondary' />
            <NoddiIconButton iconName='ArrowLeft' iconSize={16} variant='primary' />
          </Stack>

          <Stack gap={3}>
            <ServiceItem
              serviceName='Dekkskift'
              description='Når du har dekkene liggende hjemme Standard bytte mellom sommer- og vinterhjul. Flytting av dekk opp til 10 meter på bakkenivå er inkludert. Dersom dekket har lite luft har vi også med oss batteridrevet kompressor for å fylle det. Vær obs på at vi ikke alltid har muligheten til å fylle til bilens spesifiserte lufttrykk, så vi anbefaler alle å kjøre innom en bensinstasjon etter byttet for å kontrollere lufttrykket. Våre teknikere har tilgang til teknisk informasjon om akkurat din bil, og bruker spesialverktøy for å påse at hjulbolter strammes med riktig kraft.'
              price={699}
              oldPrice={699}
              isSelected={true}
              onAddService={() => {
                alert('add service');
              }}
              onRemoveService={() => {
                alert('remove service');
              }}
            />

            <ServiceItem
              serviceName='Dekkhotell'
              description='Lite bod- eller garasjeplass? Noddi kan ta med dekkene dine, og lagre de for deg frem til neste sesong. Selve oppbevaringen gjennomføres av våre underleverandører, og du vil få full sporbarhet i hvor dekkene dine befinner seg. Du kan også bestille hjemlevering utenom sesong, om du for eksempel skal selge bilen din.'
              price={2599}
              oldPrice={2599}
              isSelected={false}
              onAddService={() => {
                alert('add service');
              }}
              onRemoveService={() => {
                alert('remove service');
              }}
            />

            <ServiceItem
              discountName='If kundeklubb (10%)'
              serviceName='Dekkhotell'
              description='Lite bod- eller garasjeplass? Noddi kan ta med dekkene dine, og lagre de for deg frem til neste sesong. Selve oppbevaringen gjennomføres av våre underleverandører, og du vil få full sporbarhet i hvor dekkene dine befinner seg. Du kan også bestille hjemlevering utenom sesong, om du for eksempel skal selge bilen din.'
              price={2239}
              oldPrice={2599}
              isSelected={false}
              onAddService={() => {
                alert('add service');
              }}
              onRemoveService={() => {
                alert('remove service');
              }}
            />
          </Stack>

          <Stack gap={3}>
            <NoddiSelectableItem
              description='EK42757'
              title='SMART Fortwo Electric'
              isSelected={true}
              onAdd={() => {
                alert('add car');
              }}
              onRemove={() => {
                alert('remove car');
              }}
              iconName='Car'
            />

            <NoddiSelectableItem
              title='SMART Fortwo Electric'
              isSelected={false}
              onAdd={() => {
                alert('add car');
              }}
              onRemove={() => {
                alert('remove car');
              }}
              iconName='Car'
            />
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default BookingPlaygroundPage;

import { Dialog, DialogContent, DialogContentProps, DialogProps } from '@mui/material';
import { PropsWithChildren } from 'react';

const NoddiDialog = ({
  children,
  contentProps,
  ...dialogProps
}: PropsWithChildren<DialogProps> & { contentProps?: DialogContentProps }) => {
  return (
    <Dialog {...dialogProps}>
      <DialogContent {...contentProps}>{children}</DialogContent>
    </Dialog>
  );
};

export { NoddiDialog };

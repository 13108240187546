import { Trans } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { NoddiButton } from 'noddi-ui';

import { useTireHotelContext } from './BookingFlow/context';
import usePopulateStore from './BookingFlow/hooks/usePopulateStore';

const ConfirmStorageChangeButton = () => {
  const { prePopulateSessionStorageFromTireHotelContracts } = usePopulateStore();
  const { setDialogOpen, selectedTireHotelContracts, setSelectedAction } = useTireHotelContext();

  return (
    <>
      <Stack direction='row' gap={2}>
        <NoddiButton variant='secondary' onClick={() => setSelectedAction(null)}>
          <Typography>
            <Trans>Cancel</Trans>
          </Typography>
        </NoddiButton>
        <NoddiButton
          variant='primary'
          onClick={() => {
            setDialogOpen(true);
            prePopulateSessionStorageFromTireHotelContracts({ tireHotelContracts: selectedTireHotelContracts });
          }}
        >
          <Typography>
            <Trans>Confirm</Trans>
          </Typography>
        </NoddiButton>
      </Stack>
    </>
  );
};

export default ConfirmStorageChangeButton;

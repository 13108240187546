import { Trans, t } from '@lingui/macro';
import { Avatar, Card, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, NoddiChip, NoddiContainer, NoddiIcon, useIsMobile } from 'noddi-ui';
import { DateFormats, format, formatNorwegianCurrencyAmount } from 'noddi-util';
import { Link } from 'react-router-dom';

import routes from '../../appRoutes';
import NewAppointmentLink from '../../components/Links/NewAppointment';

export const Bookings = () => {
  const isMobile = useIsMobile();

  const { getCurrentUserGroupId } = useAuthContext();
  const userGroupId = getCurrentUserGroupId();
  const { isPending, data: bookings } = noddiAsync.useGet({
    type: URLKeys.getBookings,
    input: { userGroupId: userGroupId as number },
    queryConfig: {
      enabled: !!userGroupId
    }
  });

  if (isPending) {
    return <LoadingScreen />;
  }
  if (!isPending && bookings && bookings.length === 0) {
    return (
      <NoddiContainer header={t`Your bookings`} seoTitle={t`Your bookings`}>
        <Typography mt={3} variant='body1' mb={2}>
          <Trans>You have no registered appointments with us yet.</Trans>
        </Typography>
        <NewAppointmentLink />
      </NoddiContainer>
    );
  }

  return (
    <NoddiContainer header={t`Your bookings`} seoTitle={t`Your bookings`} headerNode={<NewAppointmentLink />}>
      <Stack spacing={3}>
        {bookings &&
          bookings.map((booking, idx: number) => (
            <Card
              key={idx}
              component={Link}
              to={routes.myBookingDetails.getPath({ id: booking.id })}
              sx={{
                cursor: 'pointer',
                marginY: 1,
                userSelect: 'none',
                textDecoration: 'none'
              }}
            >
              <Stack alignItems='center' direction='row' sx={{ p: 2 }} spacing={2}>
                <Avatar
                  sx={{
                    height: 60,
                    width: 60,
                    backgroundColor: '#ECE5FC'
                  }}
                  children={
                    booking.date ? (
                      <Stack
                        alignItems='center'
                        justifyContent='center'
                        borderRadius='50%'
                        p={1}
                        height='60%'
                        width='60%'
                      >
                        <Typography variant='button'>{format(booking.date, DateFormats.SHORT_MONTH)}</Typography>
                        <Typography variant='subtitle2'>{format(booking.date, DateFormats.FULL_YEAR)}</Typography>
                      </Stack>
                    ) : (
                      <Stack
                        alignItems='center'
                        justifyContent='center'
                        borderRadius='50%'
                        p={1}
                        height='60%'
                        width='60%'
                      >
                        <Typography variant='h5'>?</Typography>
                      </Stack>
                    )
                  }
                  variant='rounded'
                />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography color='text.primary' gutterBottom variant='button'>
                    {booking.slug}
                  </Typography>
                  {booking.date && (
                    <Typography
                      color='text.secondary'
                      gutterBottom
                      variant='body2'
                      style={{
                        display: 'flex',
                        alignItems: isMobile ? undefined : 'center',
                        marginBottom: '0px'
                      }}
                    >
                      <NoddiIcon name='Calendar' size={24} className={`${isMobile ? 'mt-1' : ''} mr-1`} />
                      {booking.date ? format(booking.date, DateFormats.DAY_MONTH) : 'Ugyldig dato'}
                    </Typography>
                  )}
                </Box>
                <Stack direction='row' gap={1} alignItems='center' justifyContent='center'>
                  {booking.isCancelled ? (
                    <NoddiChip label={t`Cancelled`} variant='quaternary' />
                  ) : (
                    <Typography>{formatNorwegianCurrencyAmount(booking.price)}</Typography>
                  )}
                  <IconButton>
                    <NoddiIcon name='AltArrowRight' size={24} />
                  </IconButton>
                </Stack>
              </Stack>
            </Card>
          ))}
      </Stack>
    </NoddiContainer>
  );
};

import Button, { ButtonProps } from '@mui/material/Button';
import { colors } from 'noddi-ui';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface CardButtonProps extends ButtonProps {
  isActive: boolean;
  height: number;
  width: number;
}

const StyledButton = styled(Button)`
  width: 180px;
  border-radius: 15px !important;
`;

export const CardButton = ({ isActive, width, height, children, ...rest }: PropsWithChildren<CardButtonProps>) => {
  return (
    <StyledButton
      {...rest}
      style={{
        backgroundColor: isActive ? colors.secondary.coral : colors.primary.white,
        outline: isActive ? `1px solid ${colors.primary.orange}` : 'none',
        border: isActive ? 'none' : '2px solid #e2e8f0',
        width,
        height
      }}
    >
      {children}
    </StyledButton>
  );
};

import { t } from '@lingui/macro';
import { NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import useCleanupBooking from '../../hooks/useCleanupBooking';

const NewAppointmentLink = () => {
  const { cleanupBookingData } = useCleanupBooking();
  const navigate = useNavigate();

  function onNewBooking() {
    cleanupBookingData();
    navigate(routes.newBookingAddressStep.getPath());
  }
  return <NoddiButton size='small' onClick={onNewBooking}>{t`New order`}</NoddiButton>;
};

export default NewAppointmentLink;

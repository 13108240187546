import { Outlet } from 'react-router-dom';

import { HeaderNav } from './HeaderNav';

export const PublicRoutesLayout = () => {
  return (
    <>
      <HeaderNav /> <Outlet />
    </>
  );
};

import { Trans } from '@lingui/macro';
import { Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { NoddiAsyncError, NoddiButton } from 'noddi-ui';

interface CancelDialogProps {
  open: boolean;
  onClose: () => void;
  bookingIdNumber: number;
  cancelBooking: UseMutateAsyncFunction<
    undefined,
    NoddiAsyncError,
    {
      bookingId: number;
    },
    unknown
  >;
}

export const CancelDialog = ({ open, onClose, cancelBooking, bookingIdNumber }: CancelDialogProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth='xs'>
      <DialogContent>
        <div
          style={{
            padding: '1em',
            paddingBottom: 0
          }}
        >
          <Typography variant='h6'>
            <Trans>Are you sure you want to cancel the agreement?</Trans>
          </Typography>
        </div>

        <DialogActions>
          <Stack direction='row' justifyContent='space-between' gap={2} sx={{ width: '100%' }}>
            <NoddiButton
              style={{
                marginTop: '24px'
              }}
              fullWidth
              onClick={handleClose}
            >
              <Trans>Cancel</Trans>
            </NoddiButton>
            <NoddiButton
              style={{
                marginTop: '24px'
              }}
              fullWidth
              onClick={() => {
                cancelBooking({
                  bookingId: bookingIdNumber
                });
                handleClose();
              }}
              variant='secondary'
            >
              <Trans>Yes</Trans>
            </NoddiButton>
          </Stack>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

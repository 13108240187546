import * as Sentry from '@sentry/react';
import * as ReactQuery from '@tanstack/react-query';
import { noddiAsync } from 'noddi-async';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';

import sentryConfig from '../../../packages/config/sentry';
import App from './App';
import './index.css';

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_ID ?? ''
};

Sentry.init(sentryConfig);

if (import.meta.env.MODE === 'production') {
  TagManager.initialize(tagManagerArgs);
}

noddiAsync.init({
  reactQuery: ReactQuery,
  baseUrl: import.meta.env.VITE_APP_BE_URL ?? ''
});

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

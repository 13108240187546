import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { UserDataProps } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { trimAllWhiteCharacters } from 'noddi-util';
import { useState } from 'react';

import { SectionSpacer } from '../../Elements/Spacers';
import { PhoneNumberForm } from './PhoneNumberForm';
import { VerificationCodeForm } from './VerificationCodeForm';

const userAlreadyExists = (data: UserDataProps) => {
  const token = data.token;
  return token && token.length > 0;
};

export type CallBackObject = {
  customCallback: () => void;
  title: string;
};

type Props = {
  userRegistrationForm: (phoneNumber: string) => React.ReactElement;
  callBackObject?: CallBackObject;
};

// Since the worker app and the customer app are using the same codebase, we need to
// allow for different user registration forms. This is why provide a callback function
const SMSLogin = ({ userRegistrationForm, callBackObject }: Props) => {
  const { loginUser, isLoggedIn } = useAuthContext();
  const [mobileNumber, setMobileNumber] = useState('');
  const [codeSentSuccessfully, setCodeSentSuccessfully] = useState(false);

  const {
    mutateAsync,
    isPending,
    isSuccess: verificationSuccess,
    data,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postVerifyPhoneNumber,
    queryConfig: {
      onSuccess(data) {
        if (userAlreadyExists(data.data)) {
          // this is only revelant for the customer app

          loginUser(data.data);
        }
      }
    }
  });

  const phoneNumberDto = ({ urlEncode = false }: { urlEncode?: boolean }) => {
    const serializedMobileNumber = trimAllWhiteCharacters(mobileNumber);

    return urlEncode ? encodeURIComponent(serializedMobileNumber) : serializedMobileNumber;
  };

  return (
    <>
      {!isLoggedIn && (
        <SectionSpacer>
          <Typography marginBottom={1} variant='h5'>
            <Trans>Phone number</Trans>
          </Typography>
          <PhoneNumberForm
            setCodeSentSuccessfully={setCodeSentSuccessfully}
            mobileNumber={mobileNumber}
            setMobileNumber={setMobileNumber}
            callBackObject={callBackObject}
          />
        </SectionSpacer>
      )}
      {!isLoggedIn && codeSentSuccessfully ? (
        <SectionSpacer>
          <VerificationCodeForm
            error={error}
            isLoading={isPending}
            phoneNumber={phoneNumberDto({})}
            verificationSuccess={verificationSuccess}
            onClick={({ code, phoneNumber }: { code: string; phoneNumber: string }) => {
              mutateAsync({ code, phoneNumber });
            }}
          />
        </SectionSpacer>
      ) : // todo replace null with error component
      null}
      {verificationSuccess && !userAlreadyExists(data.data) && userRegistrationForm(phoneNumberDto({}))}
    </>
  );
};

export { SMSLogin };

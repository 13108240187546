/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from '@lingui/macro';
import { TablePagination } from '@mui/material';
import { ChangeEvent, PropsWithChildren } from 'react';

interface Props {
  page: number;
  rowsPerPageOptions?: number[];
  rowsPerPage: number;
  setPage: (page: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  hidden?: boolean;
  count: number;
}

export function PaginatedWrapper({
  page,
  rowsPerPageOptions = [10, 25, 50, 100],
  rowsPerPage,
  setPage,
  setRowsPerPage,
  hidden,
  count,
  children
}: PropsWithChildren<Props>) {
  const pageChangeCallback = (_: any, newPage: number) => {
    setPage(newPage + 1);
  };
  const onRowsPerPageChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value));
  };
  return (
    <>
      {children}
      <TablePagination
        hidden={hidden}
        component='div'
        count={count}
        onPageChange={pageChangeCallback}
        onRowsPerPageChange={onRowsPerPageChangeCallback}
        page={page - 1}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        labelRowsPerPage={t`Rows per page`}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t`of`} ${count}`}
      />
    </>
  );
}

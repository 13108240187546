import { ReactElement } from 'react';

export const isReactElement = (element: React.ReactNode): element is ReactElement => {
  return element != null && typeof element === 'object' && 'type' in element;
};

export const isTransElement = (children: React.ReactNode) =>
  isReactElement(children) &&
  (typeof children.type === 'function'
    ? children.type.name === 'Trans'
    : typeof children.type === 'string'
    ? children.type === 'Trans'
    : false);

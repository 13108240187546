import { BaseTextFieldProps, InputAdornment, MenuItem, Select, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Field, FieldProps } from 'formik';
import { useIsMobile } from 'noddi-ui/src/hooks/useIsMobile';
import { useEffect, useRef } from 'react';
import {
  CountryData,
  CountryIso2,
  FlagImage,
  defaultCountries,
  parseCountry,
  usePhoneInput
} from 'react-international-phone';
import 'react-international-phone/style.css';
import styled from 'styled-components';

import { NoddiTextInput } from '../Inputs';
import { FormFieldErrorMessage } from './FormFieldErrorMessage';

const StyledSelect = styled(Select)`
  width: max-content;

  fieldset {
    display: none;
  }

  &.Mui-focused:has(div[aria-expanded='false']) fieldset {
    display: block;
  }

  .MuiSelect-select {
    padding: 8px;
    padding-right: 24px !important;
  }

  svg {
    right: 0;
  }
`;

const StyledFlagImage = styled(FlagImage)`
  display: flex;
`;

const RightMarginFlagImage = styled(FlagImage)`
  margin-right: 8px;
`;

const StyledInputAdornment = styled(InputAdornment)`
  margin-right: 2px;
  margin-left: -8px;
`;

const MenuProps = {
  style: {
    height: '300px',
    width: '360px',
    top: '10px',
    left: '-34px'
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'left' as const
  }
};

export interface NoddiPhoneInputProps extends Omit<BaseTextFieldProps, 'variant' | 'ref'> {
  value: string;
  onChange: (phone: string) => void;
}

const countryDataToMenuItem = (c: CountryData) => {
  const { iso2, name, dialCode } = parseCountry(c);

  return (
    <MenuItem key={iso2} value={iso2}>
      <RightMarginFlagImage iso2={iso2} />
      <Typography marginRight='8px'>{name}</Typography>
      <Typography color='gray'>+{dialCode}</Typography>
    </MenuItem>
  );
};

const DEFAULT_COUNTRY = 'no';

export const NoddiPhoneInput = ({ value, onChange, ...restProps }: NoddiPhoneInputProps) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: DEFAULT_COUNTRY,
    value,
    countries: defaultCountries,
    onChange: ({ phone }) => onChange(phone),
    disableDialCodeAndPrefix: true
  });
  const isMobile = useIsMobile();
  const hasMounted = useRef(false);
  const isDesktop = !isMobile;

  useEffect(() => {
    if (hasMounted.current || isDesktop) {
      //Add the timeout for proper inputRef value
      setTimeout(() => {
        if (inputRef && inputRef?.current) {
          inputRef?.current.focus();
        }
      }, 50);
    } else {
      hasMounted.current = true;
    }
  }, [country]);

  return (
    <NoddiTextInput
      color='primary'
      value={inputValue}
      onChange={handlePhoneValueChange}
      type='tel'
      ref={inputRef}
      InputProps={{
        startAdornment: (
          <StyledInputAdornment position='start'>
            <StyledSelect
              MenuProps={MenuProps}
              value={country.iso2}
              onChange={({ target: { value } }) => setCountry(value as CountryIso2)}
              renderValue={(value) => (
                <Stack direction='row' gap={1}>
                  <StyledFlagImage iso2={value as CountryIso2} />+{country.dialCode}
                </Stack>
              )}
            >
              {defaultCountries.map(countryDataToMenuItem)}
            </StyledSelect>
          </StyledInputAdornment>
        )
      }}
      {...restProps}
    />
  );
};

export const NoddiFormPhoneInput = ({ name, label }: { name: string; label: string }) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <div>
        <NoddiPhoneInput
          error={Boolean(form.touched[name] && form.errors[name])}
          value={field.value}
          label={label}
          onChange={(value: string) => form.setFieldValue(name, value)}
        />
        <FormFieldErrorMessage name={name} component='div' />
      </div>
    )}
  </Field>
);

import { noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, NoddiAsyncError, VerticalLayout } from 'noddi-ui';
import { Navigate } from 'react-router-dom';

import routes from '../appRoutes';
import { TopNav } from '../components/Layouts/TopNav';
import ImpersonateUserButton from '../components/UserData/ImpersonateUserButton';
import useNavRoutes from '../hooks/useNavItems';

export function HomeLayout() {
  const { isLoggedIn, userData, tokenLoginOfUsers } = useAuthContext();
  const { getNavItems } = useNavRoutes();

  const { isLoading, isError, error } = noddiAsync.getReactQuery().useQuery<unknown, NoddiAsyncError>({
    queryKey: ['user'],
    queryFn: async () => {
      return await tokenLoginOfUsers();
    },
    staleTime: Infinity,
    enabled: isLoggedIn
  });

  if (!isLoggedIn) {
    return <Navigate to={routes.login.getPath()} />;
  }

  if (isLoading || !userData) {
    return <LoadingScreen />;
  }

  if (isError) {
    if (error?.response?.status === 401) {
      return <Navigate to={routes.login.getPath()} />;
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <VerticalLayout
        navItems={getNavItems()}
        customPathToProfile={routes.profile.getPath()}
        CustomNavElement={ImpersonateUserButton}
        TopNav={TopNav}
      />
    </div>
  );
}

import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { AvailableSalesItem } from 'noddi-async/src/types';
import { NoddiAsyncError } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../../../appRoutes';
import useHasSelectedWheelStorage from '../../hooks/useHasSelectedWheelStorage';

type Props = {
  fetchAddons: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<AvailableSalesItem[], NoddiAsyncError>>;
};

const useOnNextButtonClick = ({ fetchAddons }: Props) => {
  const navigate = useNavigate();
  const { getHasSelectedWheelStorage } = useHasSelectedWheelStorage();

  const navigateToNextStep = async () => {
    const { data: addons } = await fetchAddons();

    const hasSelectedWheelStorage = getHasSelectedWheelStorage();

    if (hasSelectedWheelStorage) {
      return navigate(routes.newBookingWheelStorageStep.getPath());
    }

    if (!addons || addons.length === 0) {
      return navigate(routes.newBookingTimeSlotStep.getPath());
    }

    return navigate(routes.newBookingAddonsStep.getPath());
  };

  return { navigateToNextStep };
};

export default useOnNextButtonClick;

import { Trans } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import { Container, TextWrapper } from './commons';

interface BookingConfirmationProps {
  createdResourceId?: string | null;
  patchedBookingId?: string | null;
}
const BookingConfirmation = ({ createdResourceId, patchedBookingId }: BookingConfirmationProps) => {
  const navigate = useNavigate();

  const onClick = (bookingId: string) => {
    navigate(routes.myBookingDetails.getPath({ id: bookingId }));
  };

  const bookingId = createdResourceId || patchedBookingId;

  return (
    <>
      <Container>
        <TextWrapper>
          {patchedBookingId ? (
            <Trans>The booking was updated!</Trans>
          ) : (
            <Trans>
              The booking is successful, you will soon receive a confirmation via SMS. Thank you for choosing Noddi!
            </Trans>
          )}
        </TextWrapper>
        <Typography textAlign='center' color='text.secondary' style={{ marginTop: 12 }}>
          <Trans>You will receive a payment link via SMS when the job is done.</Trans>{' '}
        </Typography>
        {bookingId && (
          <Stack alignItems='center'>
            <NoddiButton style={{ marginTop: '24px' }} onClick={() => onClick(bookingId)}>
              <Trans>See details</Trans>
            </NoddiButton>
          </Stack>
        )}
      </Container>
    </>
  );
};

export default BookingConfirmation;

import { SVGProps } from 'react';

interface MinusProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const Minus = ({ color = '#1C274C', ...props }: MinusProps) => (
  <svg width={30} height={30} viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path stroke={color} strokeWidth={1.5} strokeLinecap='round' d='M5 15h20' />
  </svg>
);

export { Minus };

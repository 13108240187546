import { Link } from 'react-router-dom';

import routes from '../../appRoutes';
import { Container, TextWrapper } from './commons';

const MembershipAdded = ({ membershipName }: { membershipName?: string | null }) => {
  return (
    <>
      <Container>
        <TextWrapper>
          Vi har registrert medlemskapet ditt
          {membershipName ? ` hos ${membershipName}! ` : '!'} Fordelene kan du nå finne under{' '}
          <Link to={routes.myMembershipPrograms.getPath()}>medlemskap</Link> siden
        </TextWrapper>
      </Container>
    </>
  );
};

export default MembershipAdded;

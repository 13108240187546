import { Trans, t } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AvailableSalesItem } from 'noddi-async/src/types';
import { ErrorPage, LoadingScreen } from 'noddi-ui';
import { useState } from 'react';

import { useWheelStore } from '../../../../stores/WheelStore';
import BookingScreen from '../../BookingScreen';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';
import usePrefetchTimeWindows from '../../hooks/usePrefetchTimeWindows';
import { useStepValidator } from '../../hooks/useStepValidator';
import { SelectedSalesItemFull } from '../../interfaces';
import HasTireHotel from '../Addons/HasTireHotel';
import { useTireHotelCheck } from '../Addons/HasTireHotel/usetireHotelCheck';
import WheelStorageSelector from './WheelStorageSelector';
import useOnNextButtonClick from './useOnNextButtonClick';

const WheelStoragePageContent = () => {
  const { getWheelStorageItems } = useWheelStore();
  const { wheelStorageStepOk } = useStepValidator();

  // prefetches time window and membership programs
  usePrefetchTimeWindows();
  useMembershipPrograms();
  const [isAddonsLoading, setIsAddonsLoading] = useState(false);

  const { navigateToNextStep } = useOnNextButtonClick();

  const {
    error: tireHotelCheckError,
    isTireHotelOptionsPending,
    shouldCheck: shouldCheckTireHotel,
    regNumbersWithTireHotel
  } = useTireHotelCheck();

  // this can only be one per car
  const wheelStorageSalesItems = getWheelStorageItems();

  const { data, isPending, error } = noddiAsync.useGet({
    type: URLKeys.getWheelStorageSalesItemsOptions,
    input: undefined,
    queryConfig: {
      staleTime: Infinity
    }
  });

  async function onNextClicked() {
    setIsAddonsLoading(true);
    await navigateToNextStep();
    setIsAddonsLoading(false);
  }

  if (error) {
    return <ErrorPage />;
  }

  const enableNextButton = wheelStorageStepOk();

  return (
    <BookingScreen
      seoTitle={t`Select wheel storage details`}
      onNextClicked={onNextClicked}
      disableNextButton={!enableNextButton}
    >
      <HasTireHotel
        regNumbersWithTireHotel={regNumbersWithTireHotel}
        error={tireHotelCheckError}
        isTireHotelOptionsPending={isTireHotelOptionsPending}
        shouldCheck={shouldCheckTireHotel}
      >
        {isPending || isAddonsLoading ? (
          <LoadingScreen minHeight='200px' />
        ) : (
          <Content wheelStorageSalesItems={wheelStorageSalesItems} wheelStorageOptions={data} />
        )}
      </HasTireHotel>
    </BookingScreen>
  );
};

type ContentProps = {
  wheelStorageSalesItems: SelectedSalesItemFull[];
  wheelStorageOptions: AvailableSalesItem[];
};
const Content = ({ wheelStorageSalesItems, wheelStorageOptions }: ContentProps) => {
  return (
    <>
      <Typography variant='h5' mb={2}>
        <Trans>Where are your current wheels stored? </Trans>
      </Typography>

      {wheelStorageSalesItems.map((item) => (
        <Box marginBottom={8} key={item.licensePlateNumber}>
          <WheelStorageSelector
            licensePlateNumber={item.licensePlateNumber}
            wheelStorageOptions={wheelStorageOptions}
          />
        </Box>
      ))}
    </>
  );
};

export default WheelStoragePageContent;

import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import {
  GeneralTireInfo,
  LoadingScreen,
  NoddiBreadcrumbs,
  NoddiContainer,
  SimpleGridLayout,
  TireMeasurement,
  getCarDisplayName,
  resolveWheelMeasurement
} from 'noddi-ui';
import { useNavigate, useParams } from 'react-router-dom';

import routes from '../../appRoutes';

const WheelMeasureMentPage = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const navigate = useNavigate();
  const { carId } = useParams();

  if (!carId) {
    navigate(routes.myCars.getPath());
    return null;
  }

  const { data: car, isPending } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCar,
    input: { userGroupId, carId },
    queryConfig: { enabled: !!userGroupId, staleTime: Infinity }
  });

  if (isPending) {
    return <LoadingScreen />;
  }
  if (!car) {
    return (
      <NoddiContainer
        header={t`Wheel measurements`}
        breadcrumbProps={{
          links: [
            {
              title: t`Cars`,
              path: routes.myCars.getPath()
            }
          ]
        }}
      >
        <Typography variant='h4'>
          <Trans>Wheel measurements</Trans>
        </Typography>
        <div style={{ marginTop: '10px' }}>
          <NoddiBreadcrumbs
            links={[
              {
                title: t`Cars`,
                path: routes.myCars.getPath()
              }
            ]}
          />
          <Stack my={3}>
            <Typography textAlign='center' variant='subtitle1'>
              <Trans>Could not find any data for this car</Trans>
            </Typography>
          </Stack>
        </div>
      </NoddiContainer>
    );
  }

  const { summer, winter } = resolveWheelMeasurement(car);
  const title = getCarDisplayName(car);

  return (
    <NoddiContainer header={title}>
      <div style={{ marginTop: '10px' }}>
        <NoddiBreadcrumbs
          links={[
            {
              title: t`Cars`,
              path: routes.myCars.getPath()
            },
            {
              title,
              path: routes.myCarDetails.getPath({ carId })
            },
            {
              title: t`Wheel measurements`
            }
          ]}
        />
      </div>

      <SimpleGridLayout>
        {winter && (
          <Box marginTop='2rem'>
            <TireMeasurement tireType='winter' wheels={winter.wheels} />
          </Box>
        )}
        {summer && (
          <Box marginTop='2rem'>
            <TireMeasurement tireType='summer' wheels={summer.wheels} />
          </Box>
        )}
        {!summer && !winter && (
          <Typography>
            <Trans>No measurements found. Contact hei@noddi.no if this is wrong</Trans>
          </Typography>
        )}
      </SimpleGridLayout>

      <GeneralTireInfo />
    </NoddiContainer>
  );
};

export default WheelMeasureMentPage;

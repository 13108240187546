import { create } from 'zustand';

interface StickyServiceSummaryStore {
  showSummary: boolean;
  setShowSummary: (showSummary: boolean) => void;
}

const initialState = {
  showSummary: true
};

export const useStickyServiceSummaryStore = create<StickyServiceSummaryStore>((set) => ({
  ...initialState,

  setShowSummary: (showSummary: boolean) => set({ showSummary }),

  reset: () => set(initialState)
}));

import { URLKeys, noddiAsync } from 'noddi-async';

export const useCars = () => {
  const getCars = (userGroupId: number | undefined | null) => {
    return noddiAsync.useGet({
      type: URLKeys.getUserGroupCars,
      input: { userGroupId },
      queryConfig: { enabled: !!userGroupId }
    });
  };

  const getCar = (userGroupId: number | undefined | null, carId: string) => {
    return noddiAsync.useGet({
      type: URLKeys.getUserGroupCar,
      input: { userGroupId, carId },
      queryConfig: { enabled: !!userGroupId }
    });
  };

  return {
    getCars,
    getCar
  };
};

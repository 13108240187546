import { t } from '@lingui/macro';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { DEFAULT_LICENSE_PLATE_COUNTRY_CODE, URLKeys, noddiAsync } from 'noddi-async';
import { LicensePlateNumberData, RegisteredCar } from 'noddi-async/src/types';
import { useEffect, useRef, useState } from 'react';

import { NoddiIcon } from '../../../atoms/NoddiIcon';
import { NoddiIconButton } from '../../../molecules';
import { mapErrorCodeToUiMessage } from '../../Elements/Errors/ErrorMessage';
import { isLicensePlateNumberAlreadyPicked } from './utils/isLicensePlateNumberAlreadyPicked';

type Props = {
  onAddCar: (carProps: LicensePlateNumberData) => void;
  alreadyPickedLicensePlateNumbers?: string[];
  registeredCars?: RegisteredCar[];
};
const RegNumberSearch = ({ onAddCar, alreadyPickedLicensePlateNumbers = [], registeredCars = [] }: Props) => {
  const [regNumber, setRegNumber] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { error, data, refetch, isLoading } = noddiAsync.useGet({
    type: URLKeys.getDataFromLicensePlateNumber,
    input: { licensePlateNumber: regNumber, countryCode: DEFAULT_LICENSE_PLATE_COUNTRY_CODE },
    queryConfig: {
      enabled: false,
      staleTime: Infinity
    }
  });

  const licensePlateNumberAlreadyPicked = isLicensePlateNumberAlreadyPicked(
    alreadyPickedLicensePlateNumbers,
    regNumber,
    registeredCars
  );

  useEffect(() => {
    if (data) {
      onAddCar(data);
      setRegNumber('');
    }
  }, [data]);

  const searchInputDisabled = regNumber.length < 7 || licensePlateNumberAlreadyPicked;
  return (
    <>
      <div className='mb-6'>
        <TextField
          inputRef={inputRef}
          label={t`License plate (7 characters)`}
          placeholder='AB12345'
          style={{ marginTop: '12px' }}
          fullWidth={true}
          value={regNumber}
          onChange={(e) => setRegNumber(e.target.value.toUpperCase())}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              refetch();
            }
          }}
          inputProps={{
            maxLength: 7
          }}
          InputLabelProps={{
            sx: {
              ml: '40px',
              transition: 'all 0.2s ease-in-out'
            },
            shrink: isFocused || regNumber.length > 0 // Shrink label when focused or when there's input
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' className='relative bottom-[5px]'>
                <NoddiIcon name='Notes' size={32} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <NoddiIconButton
                  onClick={() => refetch()}
                  iconSize={16}
                  iconName='ArrowRight'
                  variant={searchInputDisabled ? 'secondary' : 'primary'}
                  isLoading={isLoading}
                  isDisabled={searchInputDisabled}
                />
              </InputAdornment>
            )
          }}
        />
      </div>

      {/* {licensePlateNumberAlreadyPicked && (
        <SectionSpacer variant='small'>
          <Typography style={{ fontSize: '0.9rem' }}>
            <Trans>This vehicle is already picked for your booking.</Trans>
          </Typography>
        </SectionSpacer>
      )} */}
      {/* 
      {data && !licensePlateNumberAlreadyPicked && (
        <div className='mb-4'>
          <NoddiSelectableItem
            title={`${data.make} ${data.model}`}
            iconName='Car'
            isSelected={false}
            onAdd={() => {
              onAddCar(data);
            }}
            onRemove={() => { }}
          />
        </div>
      )} */}

      {error && (
        <div className='mb-6'>
          {mapErrorCodeToUiMessage({ error })}: {regNumber}
        </div>
      )}
    </>
  );
};

export { RegNumberSearch };

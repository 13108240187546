import { SVGProps } from 'react';

const TrashBin = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill='none' {...props}>
    <path
      stroke='#1F1F1F'
      strokeLinecap='round'
      strokeWidth={1.5}
      d='M25.067 9.6H6.933M23.289 12.267l-.49 7.359c-.19 2.832-.284 4.248-1.207 5.11-.922.864-2.341.864-5.18.864h-.824c-2.839 0-4.258 0-5.18-.863-.923-.863-1.017-2.28-1.206-5.111l-.49-7.36M13.333 14.933l.534 5.334M18.667 14.933l-.534 5.334'
    />
    <path
      stroke='#1F1F1F'
      strokeWidth={1.5}
      d='M10.133 9.6h.117a2.133 2.133 0 0 0 1.952-1.408l.037-.11.104-.31c.088-.266.133-.398.191-.511a1.6 1.6 0 0 1 1.167-.841c.125-.02.265-.02.545-.02h3.508c.28 0 .42 0 .545.02.5.08.933.392 1.167.841.058.113.103.245.191.51l.104.311A2.133 2.133 0 0 0 21.867 9.6'
    />
  </svg>
);
export { TrashBin };

import { SVGProps } from 'react';

const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill='none' {...props}>
    <path
      stroke='#1F1F1F'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M16 7.467v17.066m0 0 6.4-6.4m-6.4 6.4-6.4-6.4'
    />
  </svg>
);
export { ArrowDown };

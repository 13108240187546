import { Error404Page } from 'noddi-ui';
import { Route, Routes } from 'react-router-dom';

import routes from '../appRoutes';
import Addresses from './Addresses';
import { Bookings } from './Bookings';
import BookingDetails from './Bookings/[id]';
import EditBookingTimeWindow from './Bookings/[id]/EditBookingTimeWindow';
import Cars from './Cars';
import CarDetailPage from './Cars/CarDetailPage';
import WheelMeasureMentPage from './Cars/WheelMeasureMentPage';
import Coupons from './Coupons';
import Membership from './Memberships';
import Profile from './Profile';
import Referrals from './Referrals';
import { TireStorageRouter } from './TireStorageRouter';

export function HomeRouter() {
  return (
    <Routes>
      <Route path={routes.myBookingDetails.getBasePath()} element={<BookingDetails />} />
      <Route path={routes.editMyBookingTimeWindow.getBasePath()} element={<EditBookingTimeWindow />} />
      <Route path={routes.myCars.getBasePath()} element={<Cars />} />
      <Route path={routes.myCarDetails.getBasePath()} element={<CarDetailPage />} />
      <Route path={routes.myCarWheelMeasurements.getBasePath()} element={<WheelMeasureMentPage />} />
      <Route path={routes.myCoupons.getBasePath()} element={<Coupons />} />
      <Route path='*' element={<Error404Page />} />
      <Route path={routes.homeError.getBasePath()} element={<Error404Page />} />
      <Route path={routes.profile.getBasePath()} element={<Profile />} />
      <Route path={routes.myMembershipPrograms.getBasePath()} element={<Membership />} />
      <Route path={routes.homePageBookings.getBasePath()} element={<Bookings />} />
      <Route path={routes.myAddresses.getBasePath()} element={<Addresses />} />
      <Route path={routes.myReferrals.getBasePath()} element={<Referrals />} />
      <Route path={`${routes.tireStorage.getBasePath()}/*`} element={<TireStorageRouter />} />
    </Routes>
  );
}

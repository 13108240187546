import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms/NoddiIcon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
`;

const CheckIconBackground = styled('div')(() => ({
  color: '#fff',
  width: 120,
  height: 120,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.primary.orange
}));

const Confirmation = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Container>
        <CheckIconBackground>
          <NoddiIcon className='size-[80px]' name='Check' color={colors.primary.white} />
        </CheckIconBackground>
        {children}
      </Container>
    </>
  );
};

export { Confirmation };

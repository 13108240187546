import { SVGProps } from 'react';

interface AddHomeProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const AddHome = ({ color = '#1C274C', ...props }: AddHomeProps) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M18 16H15M15 16H12M15 16V13M15 16V19' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <path
      d='M24.6359 15.9579L24.3572 17.8952C23.8697 21.2827 23.626 22.9764 22.451 23.9882C21.2759 25 19.5526 25 16.1061 25H13.8939C10.4474 25 8.72409 25 7.54903 23.9882C6.37396 22.9764 6.13025 21.2827 5.64284 17.8952L5.36407 15.9579C4.98463 13.3208 4.79491 12.0023 5.33537 10.8749C5.87583 9.7476 7.02619 9.06234 9.32691 7.69181L10.7117 6.86687C12.801 5.62229 13.8457 5 15 5C16.1543 5 17.199 5.62229 19.2882 6.86687L20.6731 7.69181C22.9738 9.06234 24.1242 9.7476 24.6646 10.8749'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);

export { AddHome };

import { PropsWithChildren } from 'react';

type NoddiTextType = 'h1' | 'h2' | 'h3' | 'h4' | 'p';

interface Props {
  type?: NoddiTextType;
  bold?: boolean;
  className?: string;
}

const typeToElementMap: Record<NoddiTextType, keyof JSX.IntrinsicElements> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  p: 'p'
};

const typeToSizeMap: Record<NoddiTextType, string> = {
  h1: 'text-4xl',
  h2: 'text-3xl',
  h3: 'text-2xl',
  h4: 'text-base',
  p: 'text-sm'
};

// Classname is primarily passed for colors.
export const NoddiText = ({ children, className, bold, type = 'p' }: PropsWithChildren<Props>) => {
  const Element = typeToElementMap[type];
  const size = typeToSizeMap[type];
  const fontStyle = bold ? 'font-condensed' : 'font-regular';

  return <Element className={`${fontStyle} ${size} ${className}`}>{children}</Element>;
};

import { Trans, t } from '@lingui/macro';
import { Card, CardContent, CardHeader, CardProps, Divider, Stack, Typography } from '@mui/material';
import { ServerTypes } from 'noddi-async';
import { CarOrderLine } from 'noddi-async/src/types';
import { extractNumberFromCurrencyString, formatNorwegianCurrencyAmount } from 'noddi-util';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import { KeyValueRow } from '..';
import { NoddiIcon } from '../../atoms/NoddiIcon';
import { UnstyledLink } from '../Elements';

type AdminProps = {
  bookingId: number;
  userGroupId: number;
  cancelDialogIsOpen: boolean;
  setCancelDialogIsOpen: Dispatch<SetStateAction<boolean>>;
  setBookingItemIdToDelete: Dispatch<SetStateAction<number | null>>;
};

type OrderSummaryProps = {
  MetaSection?: ReactElement;
  carItemLines: CarOrderLine[];
  discountLines: ServerTypes.BookingOrderLineProps[];
  otherOrderLines: ServerTypes.BookingOrderLineProps[];
  adminProps?: AdminProps;
  showHeader?: boolean;
  title?: string;
} & CardProps;

type OrderLineType = {
  id: number;
  title: string;
  value: string;
  unableToComplete?: boolean;
  adminProps?: AdminProps;
};
type SubHeader = {
  title: string;
  icon: ReactElement;
  bookingItemId?: number | null;
  adminProps?: AdminProps;
};

type BookingItemSummary = {
  subHeader: SubHeader;
  orderLines: OrderLineType[];
};

const BookingItemHeader = ({ bookingItemId, title, icon, adminProps }: SubHeader) => {
  const BookingItemHeader = () => (
    <Stack direction='row' gap={1} alignItems='center'>
      {icon}

      <Typography
        variant='h6'
        color='text.secondary'
        fontSize='13px'
        sx={{
          '&:hover': adminProps ? { textDecoration: 'underline', cursor: 'pointer' } : {}
        }}
      >
        {title}
      </Typography>
    </Stack>
  );

  return adminProps && bookingItemId ? (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <UnstyledLink
        to={`/user-groups/${adminProps.userGroupId}/bookings/${adminProps.bookingId}/items/${bookingItemId}`}
      >
        <BookingItemHeader />
      </UnstyledLink>
      <NoddiIcon
        name='TrashBin'
        size={24}
        className='cursor-pointer'
        onClick={() => {
          adminProps.setCancelDialogIsOpen(true);
          adminProps.setBookingItemIdToDelete(bookingItemId);
        }}
      ></NoddiIcon>
    </Stack>
  ) : (
    <BookingItemHeader />
  );
};

const OrderLine = ({ id, title, value, unableToComplete, adminProps }: OrderLineType) => {
  const navigate = useNavigate();
  const Header = () => (
    <Typography
      variant='body2'
      color='text.secondary'
      fontSize='13px'
      sx={{ textDecoration: unableToComplete ? 'line-through' : 'none' }}
    >
      {title}
    </Typography>
  );

  const Value = () => (
    <Typography
      variant='body2'
      color='text.secondary'
      fontSize='13px'
      sx={{ textDecoration: unableToComplete ? 'line-through' : 'none' }}
    >
      {value}
    </Typography>
  );
  return (
    <Stack direction='row' justifyContent='space-between' paddingTop={1} alignItems='center'>
      {adminProps ? (
        <div style={{ width: '100%' }}>
          <KeyValueRow
            header={<Header />}
            value={<Value />}
            onClick={() =>
              navigate(`/user-groups/${adminProps.userGroupId}/bookings/${adminProps.bookingId}/order-lines/${id}`)
            }
            showDivider={false}
          />
        </div>
      ) : (
        <>
          <Header />
          <Value />
        </>
      )}
    </Stack>
  );
};

const OrderSummary = ({
  showHeader = true,
  carItemLines,
  MetaSection,
  discountLines,
  otherOrderLines,
  adminProps,
  title,
  ...cardProps
}: OrderSummaryProps) => {
  const carOrders: BookingItemSummary[] = [];
  const discountOrderLines: OrderLineType[] = [];
  const others: BookingItemSummary[] = [];

  carItemLines.forEach((car) => {
    const subHeader = {
      title: car.name,
      bookingItemId: car.bookingItemId,
      icon: <NoddiIcon name='Car' size={24} />
    };
    const orderLines: OrderLineType[] = [];

    car.orderLines.forEach((orderLine) => {
      const price = orderLine.pricePerUnit * orderLine.quantity;
      if (price < 0) {
        discountOrderLines.push({
          title: orderLine.description,
          id: orderLine.id,
          value: formatNorwegianCurrencyAmount(price),
          unableToComplete: !!orderLine.unableToComplete
        });
      } else {
        orderLines.push({
          id: orderLine.id,
          title: orderLine.description,
          value: formatNorwegianCurrencyAmount(price),
          unableToComplete: !!orderLine.unableToComplete
        });
      }
    });
    carOrders.push({
      subHeader,
      orderLines
    });
  });

  if (otherOrderLines.length > 0) {
    const subHeader = {
      title: t`Other`,
      icon: <NoddiIcon name='ShoppingCart' size={24} />
    };
    const orderLines = otherOrderLines.map((orderLine) => {
      return {
        title: orderLine.description,
        id: orderLine.id,
        value: formatNorwegianCurrencyAmount(orderLine.pricePerUnit * orderLine.quantity),
        unableToComplete: !!orderLine.unableToComplete
      };
    });
    others.push({
      subHeader,
      orderLines
    });
  }

  discountLines.forEach((orderLine) => {
    discountOrderLines.push({
      id: orderLine.id,
      title: orderLine.description,
      value: formatNorwegianCurrencyAmount(orderLine.pricePerUnit * orderLine.quantity),
      unableToComplete: !!orderLine.unableToComplete
    });
  });

  const allOrders = [...carOrders, ...others];

  if (discountOrderLines.length > 0) {
    allOrders.push({
      subHeader: {
        title: t`Discounts`,
        icon: <NoddiIcon name='Bill' size={24} />
      },
      orderLines: discountOrderLines
    });
  }

  const totalPrice = allOrders
    .map((x) => x.orderLines)
    .reduce((acc, orderLine) => {
      return (
        acc +
        orderLine
          .filter((x) => !x.unableToComplete)
          .map((x) => extractNumberFromCurrencyString(x.value))
          .reduce((acc, x) => acc + x, 0)
      );
    }, 0);
  const mva = totalPrice * 0.2;

  return (
    <Card sx={{ width: '100%' }} {...cardProps}>
      {showHeader && (
        <>
          <CardHeader title={title ? title : t`Your order`} />
          <Divider />
        </>
      )}
      <CardContent>
        {MetaSection && (
          <>
            {MetaSection}
            <Divider sx={{ marginY: '1rem' }} />
          </>
        )}
        {allOrders.map((order) => (
          <div key={order.subHeader.title}>
            <BookingItemHeader {...order.subHeader} adminProps={adminProps} />

            {order.orderLines.map((orderLine) => (
              <OrderLine {...orderLine} key={orderLine.title} adminProps={adminProps} />
            ))}
            <Divider sx={{ marginY: '1rem' }} />
          </div>
        ))}
        <Stack direction='row' justifyContent='space-between' paddingTop={1} alignItems='center'>
          <Typography variant='body2' color='text.secondary'>
            <Trans>VAT</Trans>
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {formatNorwegianCurrencyAmount(mva)}
          </Typography>
        </Stack>
        <Stack direction='row' justifyContent='space-between' paddingTop={1} alignItems='center'>
          <Typography variant='h6' fontSize='13px'>
            <Trans>Total</Trans>
          </Typography>

          <Typography variant='body2' color='text.secondary'>
            {formatNorwegianCurrencyAmount(totalPrice)}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export { OrderSummary };

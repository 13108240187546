import { URLKeys, noddiAsync } from 'noddi-async';
import { AvailableBookingTimeWindowsInput } from 'noddi-async/src/types';
import { NUM_DAYS_TO_QUERY } from 'noddi-ui';
import { DateFormats, addDays, format } from 'noddi-util';
import { useEffect } from 'react';

import { useBookingContext } from '../../../contexts/BookingContext';

// This hook prefetches the available booking time windows for the selected service area and sales items.
// It also makes sure to remove any cached entries that are not matching the current service area and sales items.
const usePrefetchTimeWindows = () => {
  const { bookingInputData, salesItems } = useBookingContext();
  const { serviceAreaId } = bookingInputData;
  const salesItemIds = salesItems.map((salesItem) => salesItem.id);

  const queryCache = noddiAsync.queryClient.getQueryCache();
  const noneMatchingEntries = queryCache
    .findAll({ queryKey: [URLKeys.getAvailableBookingTimeWindowsByDate] })
    .filter((match) => {
      const queryKey = match.queryKey[1] as AvailableBookingTimeWindowsInput;
      return queryKey.serviceAreaId !== serviceAreaId || queryKey.salesItemIds.join(',') !== salesItemIds.join(',');
    })
    .map((match) => match.queryKey);

  useEffect(() => {
    for (const queryKey of noneMatchingEntries) {
      noddiAsync.queryClient.removeQueries({ queryKey });
    }
  }, [salesItemIds, serviceAreaId, bookingInputData]);

  const prefetchLastCreatedBooking = noddiAsync.useGet({
    type: URLKeys.getLatestCreatedBookingTimeWindowDate,
    queryConfig: {
      staleTime: Infinity
    }
  });

  const prefetchAvailableBookingTimeWindows = noddiAsync.useGet({
    type: URLKeys.getAvailableBookingTimeWindowsByDate,
    input: {
      serviceAreaId: serviceAreaId || -1,
      salesItemIds,
      fromDate: format(addDays(new Date(), 1), DateFormats.DASHED_DATE_ISO_8601),
      toDate: format(addDays(new Date(), NUM_DAYS_TO_QUERY + 1), DateFormats.DASHED_DATE_ISO_8601)
    },
    queryConfig: {
      staleTime: Infinity,
      enabled: !!serviceAreaId && salesItems.length > 0,
      refetchInterval: 1000 * 60 * 4
    }
  });

  return { prefetchLastCreatedBooking, prefetchAvailableBookingTimeWindows };
};

export default usePrefetchTimeWindows;

import { isWheelStorageNewOrder } from '../../../helper/salesItems';
import { useSalesItems } from '../../../hooks/useSalesItems';

/**
 * This custom hook determines if a wheel storage sales item has been selected. It
 * does search for the selected sales items in the react query cache, so make sure that any data that
 * is read from the cache is already in the cache
 *
 * @returns {boolean} Returns true if a wheel storage sales item has been selected, otherwise false.
 */
const useHasSelectedWheelStorage = () => {
  const { getSelectedSalesItemsFromCache } = useSalesItems();

  const getHasSelectedWheelStorage = () => {
    const selectedSalesItems = getSelectedSalesItemsFromCache();

    return selectedSalesItems.some((item) => isWheelStorageNewOrder(item));
  };

  return {
    getHasSelectedWheelStorage
  };
};

export default useHasSelectedWheelStorage;

import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { NoddiCollapseCard, colors } from 'noddi-ui';

const InfoCard = () => {
  return (
    <div>
      <NoddiCollapseCard
        backgroundColor={colors.primary.darkPurple30}
        sx={{ marginBottom: '2rem' }}
        header={
          <Typography variant='h6'>
            <Trans>Practical information about tire hotels</Trans>
          </Typography>
        }
        collapseBody={
          <ul>
            <li>
              <Trans>
                Time to change your tires for the season? Ordering a tire change for cars on a tire hotel agreement is
                done by pressing <b>new order</b> above. We'll come to you and take the season's wheel set back for
                storage until it's time to change tires again next season.
              </Trans>
            </li>
            <li>
              <Trans>
                If you wish to terminate your agreement with the tire hotel, you can either have the tires delivered to
                your home or pick them up yourself. We also offer tire changes if you want home delivery of the tires.
              </Trans>
            </li>
          </ul>
        }
      />
    </div>
  );
};

export default InfoCard;

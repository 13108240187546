import { colors } from '../../tailwind-design-preset';
import { IconSize } from '../atoms';
import { IconName, NoddiIcon } from '../atoms/NoddiIcon';

type IconVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary';

interface NoddiIconButtonProps {
  iconName: IconName;
  iconSize: IconSize;
  variant: IconVariant;
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

//Created intro variants (will be redesigned once Rachid has time)

const variants = {
  primary: {
    wrapperClass: 'bg-primary-purple',
    loadingClass: 'border-t-[#FFC7ED]',
    iconColor: colors.primary.white
  },
  secondary: {
    wrapperClass: 'bg-[#F7F6FA]',
    loadingClass: 'border-t-primary-purple',
    iconColor: colors.primary.black
  },
  tertiary: {
    wrapperClass: 'bg-primary-orange',
    loadingClass: 'border-t-primary-black',
    iconColor: colors.primary.black
  },
  quaternary: {
    wrapperClass: 'bg-[#11192742] hover:bg-[#4a4a4a42]',
    loadingClass: '',
    iconColor: colors.primary.white
  }
};

export const NoddiIconButton = ({
  iconName,
  iconSize = 32,
  variant,
  onClick,
  isLoading,
  isDisabled
}: NoddiIconButtonProps) => {
  return (
    <div
      className={`relative w-fit cursor-pointer rounded-full p-2 ${variants[variant].wrapperClass} ${
        isDisabled ? 'pointer-events-none cursor-not-allowed' : 'cursor-pointer'
      }`}
      onClick={onClick}
    >
      <NoddiIcon name={iconName} size={iconSize} color={variants[variant].iconColor} />
      {isLoading && (
        <div
          className={`border-transparent absolute inset-0 box-border size-full animate-spin-slow rounded-full border-[3px] border-systemColors-transparent ${variants[variant].loadingClass}`}
        />
      )}
    </div>
  );
};

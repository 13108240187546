import { useLocation, useNavigate } from 'react-router-dom';

import routes from '../../../appRoutes';
import { useBookingContext } from '../../../contexts/BookingContext';

export function useBackNavigation() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { salesItems } = useBookingContext();

  function goBack() {
    switch (pathname) {
      case routes.newBookingCarStep.getPath():
        navigate(routes.newBookingAddressStep.getPath());
        break;
      case routes.newBookingServicesStep.getPath():
        navigate(routes.newBookingCarStep.getPath());
        break;
      case routes.newBookingAddonsStep.getPath():
        navigate(routes.newBookingServicesStep.getPath());
        break;
      case routes.newBookingTimeSlotStep.getPath():
        if (salesItems.some((item) => item.isAddon)) {
          navigate(routes.newBookingAddonsStep.getPath());
          break;
        }
        navigate(routes.newBookingServicesStep.getPath());
        break;
      case routes.newBookingSummaryStep.getPath():
        navigate(routes.newBookingTimeSlotStep.getPath());
        break;
      default:
        navigate(-1);
        break;
    }
  }
  return { goBack };
}

import { Route, Routes } from 'react-router-dom';

import routes from '../appRoutes';
import TireStorage from './TireHotel';
import { TireHotelProvider } from './TireHotel/BookingFlow/context';
import TireHotelDetailPage from './TireHotel/[id]';
import TireAddressDetails from './TireHotel/[id]/TireAddressDetails';

export function TireStorageRouter() {
  return (
    <Routes>
      <Route path={routes.tireStorageDetail.getBasePath()} element={<TireHotelDetailPage />} />
      <Route path='*' element={<TireStorage />} />
      <Route
        path={routes.tireStorageBooking.getBasePath()}
        element={
          <TireHotelProvider>
            <TireAddressDetails />
          </TireHotelProvider>
        }
      />
      <Route
        path={routes.tireStorageCancelBooking.getBasePath()}
        element={
          <TireHotelProvider>
            <TireAddressDetails isCancel={true} />
          </TireHotelProvider>
        }
      />
    </Routes>
  );
}

import Box from '@mui/material/Box';
import { BoxProps } from '@mui/system';

import { NoddiCircularLoader } from './NoddiCircularLoader';

export const LoadingScreen = (props: BoxProps) => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    minHeight='calc(100vh - var(--ct-topbar-height))'
    {...props}
  >
    <NoddiCircularLoader />
  </Box>
);

import { Trans } from '@lingui/macro';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { formatNorwegianCurrencyAmount } from 'noddi-util';

const DeliveryCostSection = ({ timeWindow }: { timeWindow: AvailableBookingTimeWindow }) => {
  return (
    <>
      <Divider sx={{ marginTop: '1.5em', marginBottom: '0.5em' }} />
      <Grid container direction='row' justifyContent='space-between' alignItems='center' marginTop='0.3em'>
        <Grid>
          <Trans>Delivery</Trans>
        </Grid>
        <Grid>{formatNorwegianCurrencyAmount(timeWindow.price, 0)}</Grid>
      </Grid>
    </>
  );
};

export default DeliveryCostSection;

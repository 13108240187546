import {
  CarDetailType,
  CarDetailsProps,
  CarListItem,
  OrganizationBookingListCar,
  OrganizationCar
} from 'noddi-async/src/types';

export const getCarDisplayName = (
  carProps: CarDetailsProps | CarListItem | CarDetailType | OrganizationBookingListCar | OrganizationCar
) => {
  let carText;

  if ('make' in carProps) {
    carText = `${carProps.make} ${carProps.model}`;
  } else if ('makeName' in carProps) {
    carText = `${carProps.makeName} ${carProps.model}`;
  } else {
    carText = `${carProps.model}`;
  }

  if ('licensePlateNumber' in carProps) {
    carText += ` - ${carProps.licensePlateNumber}`;
  }

  return carText;
};

export const psiToBar = (psi: number) => psi * 0.0689476;

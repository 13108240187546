import { useAuthContext } from 'noddi-provider';

import ImpersonatingAlert from '../ImpersonatingAlert';

export const TopNav = () => {
  const { impersonatedUser } = useAuthContext();

  const isImpersonating = !!impersonatedUser;

  return isImpersonating ? <ImpersonatingAlert /> : null;
};

import { NoddiIconButton, NoddiText } from 'noddi-ui';
import { formatNorwegianCurrencyAmount } from 'noddi-util';

type ServiceItemProps = {
  serviceName: string;
  price: number;
  oldPrice: number;
  discountName?: string;
  description?: string;
  isSelected: boolean;
  onAddService: () => void;
  onRemoveService: () => void;
};

const ServiceItem = ({
  serviceName,
  description,
  isSelected,
  price,
  oldPrice,
  discountName,
  onAddService,
  onRemoveService
}: ServiceItemProps) => {
  return (
    <div
      className={`flex flex-col items-start rounded-lg p-4 ${
        isSelected ? 'bg-primary-purple text-primary-white' : 'bg-primary-white'
      }`}
    >
      <div className='flex w-full'>
        <div className='gap- flex flex-col'>
          <NoddiText type='h3' className={isSelected ? 'text-primary-white' : ''}>
            {serviceName}
          </NoddiText>
          {price === oldPrice ? (
            <NoddiText type='p'>{formatNorwegianCurrencyAmount(price, 0)}</NoddiText>
          ) : (
            <div className='flex gap-2'>
              <NoddiText type='p' className='line-through opacity-[50%]'>
                {formatNorwegianCurrencyAmount(oldPrice, 0)}
              </NoddiText>
              <NoddiText type='p' className='text-signal-success'>
                {formatNorwegianCurrencyAmount(price, 0)}
              </NoddiText>
              <NoddiText type='p' className='text-signal-success opacity-[70%]'>
                {discountName}
              </NoddiText>
            </div>
          )}
        </div>

        {isSelected ? (
          <div className='ml-auto h-fit cursor-pointer'>
            <NoddiIconButton
              variant='secondary'
              iconName='Minus'
              iconSize={24}
              onClick={() => {
                onRemoveService();
              }}
            />
          </div>
        ) : (
          <div className='ml-auto h-fit cursor-pointer'>
            <NoddiIconButton
              iconName='Plus'
              variant='primary'
              iconSize={24}
              onClick={() => {
                onAddService();
              }}
            />
          </div>
        )}
      </div>
      {description && (
        <>
          <div className='my-4 h-[1px] w-full bg-[#ECECEC]' />
          {description && (
            <NoddiText className={isSelected ? 'text-primary-white opacity-[70%]' : 'text-primary-black opacity-[70%]'}>
              {description}
            </NoddiText>
          )}
        </>
      )}

      {/* //TODO addd collapsable item once we have one */}
    </div>
  );
};

export { ServiceItem };

import { Trans, t } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { MembershipProgramProps } from 'noddi-async/src/types';
import { removeQueryKey } from 'noddi-async/src/utils';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import AddressPickerComponent from '../../../../components/BookingFlow/AddressPickerComponent';
import TimeWindowPicker from '../../../../components/BookingFlow/TimeWindowPicker';
import { useBookingContext } from '../../../../contexts/BookingContext';
import { routeToConfirmation } from '../../../../helper/confirmation';
import useBookingPayload from '../../../../hooks/useBookingPayload';
import useCleanupBooking from '../../../../hooks/useCleanupBooking';
import { useSalesItems } from '../../../../hooks/useSalesItems';
import { useStepValidator } from '../../../BookingFlow/hooks/useStepValidator';
import useUpdateSalesItemIncompatibles from '../../../BookingFlow/hooks/useUpdateCompatibleServiceAreas';
import ConfirmBooking from '../Components/Steps/CofirmBooking';
import ReturnChoices from '../Components/Steps/ReturnChoices';
import { useTireHotelContext } from '../context';
import { TireStorageBookingSteps } from '../tireStorageBookingSteps';

type Props = {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  membershipData: MembershipProgramProps[] | undefined;
};
const useStepContent = ({ setIsLoading, membershipData }: Props) => {
  const { updateSalesItemIncompatibles } = useUpdateSalesItemIncompatibles();
  const { salesItems } = useBookingContext();
  const { getSelectedSalesItemsFromCache } = useSalesItems();
  const { createBookingPayload } = useBookingPayload();
  const { cleanupBookingData } = useCleanupBooking();
  const { addressStepOk, timeWindowsStepOk, salesItemStepOk } = useStepValidator();
  const navigate = useNavigate();
  const { setCurrentStep, selectedAction, setDialogOpen } = useTireHotelContext();

  const { mutateAsync: createBooking } = noddiAsync.usePost({
    type: URLKeys.postBooking,
    queryConfig: {
      onSuccess: async (data) => {
        cleanupBookingData();
        routeToConfirmation({ navigate, id: data.data.id });
      }
    }
  });

  // fetch salesItems when actions

  type StepContent = {
    [key in TireStorageBookingSteps]: {
      confirmText: string;
      onNextClicked: () => void;
      renderComponent: () => JSX.Element;
      back?: () => void;
      nextDisabled?: () => boolean;
    };
  };

  const stepContent: StepContent = {
    [TireStorageBookingSteps.returnChoices]: {
      confirmText: t`Next`,
      onNextClicked: () => {
        const selectedSalesItems = getSelectedSalesItemsFromCache();
        if (selectedSalesItems.length === 0) {
          // TODO ser error message
          return;
        }

        // If the user has selected to pick up the tires themselves, we skip the address step
        // and instead use the address of the serviceContract

        // TODO: fix pickUp yourself
        // const isPickUpYourself = selectedSalesItems.some(
        //   (item) => item.slug === SalesItemSlugs['nb-tire-storage-pickup-yourself']
        // );

        // if (isPickUpYourself) {
        //   // since we potentially have multiple serviceContracts, there is no nice way to
        //   // really decide which address to use, so we simply use the first one
        //   if (selectedTireHotelContracts.length < 1) {
        //     throw new Error('No serviceContracts found');
        //   }

        //   const first = selectedTireHotelContracts[0];
        //   if (first?.address) {
        //     updateBookingInputData({ address: first.address });
        //   }
        //   return setCurrentStep(TireStorageBookingSteps.confirm);
        // }
        return setCurrentStep(TireStorageBookingSteps.address);
      },
      nextDisabled: () => !salesItemStepOk(),
      renderComponent: () => <ReturnChoices />
    },
    [TireStorageBookingSteps.address]: {
      confirmText: t`Next`,
      onNextClicked: async () => {
        removeQueryKey({ urlKey: URLKeys.getAvailableBookingTimeWindowsByDate });

        updateSalesItemIncompatibles(salesItems);
        setCurrentStep(TireStorageBookingSteps.timePicker);
      },
      renderComponent: () => <AddressPickerComponent />,

      back: () => {
        if (selectedAction === 'order') {
          return;
        }
        setCurrentStep(TireStorageBookingSteps.returnChoices);
      },
      nextDisabled: () => !addressStepOk()
    },
    [TireStorageBookingSteps.timePicker]: {
      confirmText: t`Next`,
      onNextClicked: () => {
        setCurrentStep(TireStorageBookingSteps.confirm);
      },
      renderComponent: () => (
        <>
          <Typography textAlign='left' width='100%' marginY='1em' variant='h5'>
            <Trans>When is it convenient for us to come to you?</Trans>
          </Typography>
          <Stack boxShadow={2} borderRadius={3} gap={2} padding={2}>
            <TimeWindowPicker />
          </Stack>
        </>
      ),
      back: () => {
        setCurrentStep(TireStorageBookingSteps.address);
      },
      nextDisabled: () => !timeWindowsStepOk()
    },
    [TireStorageBookingSteps.confirm]: {
      confirmText: t`Confirm`,
      onNextClicked: async () => {
        const payload = createBookingPayload(membershipData);

        setIsLoading(true);
        await createBooking(payload);
        setIsLoading(false);
        setDialogOpen(false);
      },
      renderComponent: () => <ConfirmBooking membershipData={membershipData} />,
      back: () => {
        setCurrentStep(TireStorageBookingSteps.timePicker);
      }
    }
  };

  return stepContent;
};

export default useStepContent;

import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AvailableBookingTimeWindow, MembershipProgramProps } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { ApiErrorMessage, ErrorMessage, NoddiAsyncError, NoddiButton, NoddiLinearProgressLoader } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import { routeToConfirmation } from '../../../../helper/confirmation';
import useBookingPayload from '../../../../hooks/useBookingPayload';
import useCleanupBooking from '../../../../hooks/useCleanupBooking';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { useWheelStore } from '../../../../stores/WheelStore';
import { GtmEvents } from '../../../../types/gtmTracking';

type ConfirmBookingProps = {
  membershipPrograms: MembershipProgramProps[] | undefined;
  fullWidth?: boolean;
  BackButton?: JSX.Element;
};

const ConfirmBooking = ({ membershipPrograms, fullWidth = true, BackButton }: ConfirmBookingProps) => {
  // ensure user is logged in and user group is selected
  const { isTokenLoginLoading } = useAuthContext();
  const { cleanupBookingData } = useCleanupBooking();

  const navigate = useNavigate();

  const { selectedWheelStorageOptions } = useWheelStore();
  const { createBookingPayload, getGoogleTrackingData } = useBookingPayload();

  // check that all selected wheel storage options have a storage type and pick up address
  if (
    selectedWheelStorageOptions.length > 0 &&
    !selectedWheelStorageOptions.every((option) => !!option.storageType && !!option.pickUpAddress)
  ) {
    return (
      <ErrorMessage>
        {t`Not all tire storage options are provided, please go back and typ correct info. If problem persists, please contact support.`}
      </ErrorMessage>
    );
  }

  const {
    mutateAsync: createNewBooking,
    isPending,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postBooking,
    queryConfig: {
      onSuccess: async (data) => {
        try {
          const { coupons, salesItems, time } = getGoogleTrackingData();

          GoogleTrackingService.trackEvent({
            eventType: GtmEvents.purchase,
            data: {
              salesItems,
              coupons,
              time: time as AvailableBookingTimeWindow,
              membershipData: membershipPrograms
            }
          });
        } catch (error) {
          console.error('Error tracking booking created event', error);
          // TODO: setup error tracking
        }

        cleanupBookingData();
        routeToConfirmation({ navigate, id: data.data.id });
      }
    }
  });

  const onSubmit = async () => {
    const bookingParams = createBookingPayload(membershipPrograms);

    await createNewBooking(bookingParams);
  };

  if (isTokenLoginLoading) {
    return <NoddiLinearProgressLoader />;
  }

  return (
    <div className='flex flex-col '>
      {error && (
        <div className='mb-2'>
          <ApiErrorMessage error={error as NoddiAsyncError} />
        </div>
      )}
      <div className='flex gap-2'>
        {BackButton}
        <NoddiButton
          fullWidth={fullWidth}
          loading={isPending}
          disabled={isPending}
          onClick={onSubmit}
          data-testid='confirm-booking'
        >
          <Trans>Confirm booking</Trans>
        </NoddiButton>
      </div>
    </div>
  );
};

export default ConfirmBooking;

import { Trans } from '@lingui/macro';
import { Card, CardContent, Typography } from '@mui/material';

import { KeyValueRow, NoddiButtonLink } from '../../../Elements';
import { WheelHeader } from '../WheelMeasurementHeader';
import { WheelSetStatus, WheelSetStatusType } from '../types';

type Props = {
  to: string;
  statusSummerWheelSet: WheelSetStatusType;
  statusWinterWheelSet: WheelSetStatusType;
};
const TireDetails = ({ to, statusSummerWheelSet, statusWinterWheelSet }: Props) => {
  return (
    <div>
      <Typography variant='h6'>
        <Trans>Tires</Trans>
      </Typography>
      <Card sx={{ marginTop: '1rem', height: '100%' }}>
        <CardContent>
          <KeyValueRow header={<WheelHeader type='summer' wheelSetStatus={statusSummerWheelSet} />} />
          <KeyValueRow
            header={<WheelHeader type='winter' wheelSetStatus={statusWinterWheelSet} />}
            showDivider={false}
          />

          {(statusSummerWheelSet !== WheelSetStatus.MissingMeasurements ||
            statusWinterWheelSet !== WheelSetStatus.MissingMeasurements) && (
            <NoddiButtonLink variant='quaternary' to={to}>
              <Typography variant='body2' fontWeight='bold'>
                <Trans>See measurements</Trans>
              </Typography>
            </NoddiButtonLink>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export { TireDetails };

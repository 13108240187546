import { Trans, t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import {
  EuCheckDetails,
  GeneralDetails,
  LoadingScreen,
  NoddiContainer,
  SimpleGridLayout,
  TireDetails,
  Tires,
  calculateWheelSetStatus
} from 'noddi-ui';
import { useNavigate, useParams } from 'react-router-dom';

import routes from '../../appRoutes';

const CarDetailPage = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const navigate = useNavigate();
  const { carId } = useParams();

  if (!carId) {
    navigate(routes.myCars.getPath());
    return null;
  }

  const { data: car, isPending } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCar,
    input: { userGroupId, carId },
    queryConfig: { enabled: !!userGroupId, staleTime: Infinity }
  });

  const getBreadcrumbLinks = (title: string) => {
    return {
      links: [
        {
          title: t`Cars`,
          path: routes.myCars.getPath()
        },
        {
          title
        }
      ]
    };
  };

  if (isPending) {
    return <LoadingScreen />;
  }
  if (!car) {
    return (
      <NoddiContainer breadcrumbProps={getBreadcrumbLinks('')}>
        <Typography textAlign='center' variant='subtitle1'>
          <Trans>Did not find any information about this vehicle</Trans>
        </Typography>
      </NoddiContainer>
    );
  }

  const title = `${car.make} ${car.model} -  ${car.licensePlateNumber}`;
  const { euControlPrevious, euControlNext, length, weight, width, height, vinNumber } = car;

  const statusWinterWheelSet = calculateWheelSetStatus(car, Tires.winter);
  const statusSummerWheelSet = calculateWheelSetStatus(car, Tires.summer);

  return (
    <NoddiContainer header={title} breadcrumbProps={getBreadcrumbLinks(title)}>
      <SimpleGridLayout>
        <TireDetails
          to={routes.myCarWheelMeasurements.getPath({ carId })}
          statusWinterWheelSet={statusWinterWheelSet}
          statusSummerWheelSet={statusSummerWheelSet}
        />
        <GeneralDetails length={length} height={height} weight={weight} width={width} vinNumber={vinNumber} />

        <EuCheckDetails euControlNext={euControlNext} euControlPrevious={euControlPrevious} />
      </SimpleGridLayout>
    </NoddiContainer>
  );
};

export default CarDetailPage;

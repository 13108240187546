import { SVGProps } from 'react';

interface CustomProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const Money = ({ color = '#1C274C', ...props }: CustomProps) => (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='15' cy='15' r='10' stroke={color} strokeWidth='1.5' />
    <path d='M15 9V21' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <path
      d='M18 12.5C18 11.1193 16.6569 10 15 10C13.3431 10 12 11.1193 12 12.5C12 13.8807 13.3431 15 15 15C16.6569 15 18 16.1193 18 17.5C18 18.8807 16.6569 20 15 20C13.3431 20 12 18.8807 12 17.5'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
    />
  </svg>
);

export { Money };

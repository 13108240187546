import { Trans } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const Error404Page = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Stack className='full-height' justifyContent='center' alignItems='center' textAlign='center'>
      <Stack direction='column' gap={1}>
        <div>404</div>

        <Typography variant='h6'>
          <Trans>Page Not Found</Trans>
        </Typography>

        <Link to='/'>
          <p onClick={goBack}>
            &larr;
            <Trans>Go Back</Trans>
          </p>
        </Link>
      </Stack>
    </Stack>
  );
};

export { Error404Page };

import styled from 'styled-components';

export const TextWrapper = styled.div`
  text-align: center;
  font-size: 1.5rem;
`;

export const Container = styled.div`
  margin-top: 2rem;
  padding: 0 1rem;
  max-width: 500px;
`;

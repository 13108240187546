import { Trans } from '@lingui/macro';
import { Stack } from '@mui/system';
import { useState } from 'react';
import styled from 'styled-components';

import { NoddiDrawer } from '../../../../../Elements';
import RouteDrawer from '../../Drawer/RouteDrawer';
import { CardCommonCss } from './commonCss';
import { RouteAndRouteItemId } from './types';

const UnableCard = styled.button`
  ${CardCommonCss};
  background-color: #faf1da;
  max-width: 120px;
`;

const UnableToCompleteCard = ({ routeId, routeItemId }: RouteAndRouteItemId) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <UnableCard
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <Stack direction='column' height='100%' justifyContent='center'>
          <Stack direction='row' justifyContent='center'>
            <Trans>Unable to complete</Trans>
          </Stack>
        </Stack>
      </UnableCard>
      <NoddiDrawer
        isOpen={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <RouteDrawer routeItemId={routeItemId} routeId={routeId} />
      </NoddiDrawer>
    </>
  );
};

export default UnableToCompleteCard;

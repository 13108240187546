import { Confirmation } from 'noddi-ui';
import { useSearchParams } from 'react-router-dom';

import BookingConfirmation from './Booking';
import MembershipAdded from './MembershipAdded';
import { CONFIRMATION_TYPES, ConfirmationType } from './types';

const ConfirmationContent = () => {
  const [searchParams] = useSearchParams();

  const type = searchParams.get('type') as ConfirmationType;
  const createdResourceId = searchParams.get('createdResourceId');
  const membershipName = searchParams.get('membershipName');
  const patchedBookingId = searchParams.get('patchedBookingId');

  switch (type) {
    case CONFIRMATION_TYPES.booking:
      return <BookingConfirmation createdResourceId={createdResourceId} />;
    case CONFIRMATION_TYPES.membership_added:
      return <MembershipAdded membershipName={membershipName} />;
    case CONFIRMATION_TYPES.bookingUpdated:
      return <BookingConfirmation patchedBookingId={patchedBookingId} />;
    default:
      return <></>;
  }
};

const ConfirmationPage = () => {
  return (
    <Confirmation>
      <ConfirmationContent />
    </Confirmation>
  );
};

export default ConfirmationPage;

import { SVGProps } from 'react';

import { colors } from '../../../../tailwind-design-preset';

const LogoSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 172 49' width={172} height={49} fill='none' {...props}>
    <path
      fill={colors.primary.darkPurple}
      d='M22.706 3.952c2.277 0 5.425 5.693 5.425 19.758v1.675L15.74.737H0v3.215c2.344 0 3.952 3.483 3.952 20.227 0 16.678-1.54 20.228-3.952 20.228v3.215h13.597v-3.215c-2.21 0-5.426-5.626-5.426-19.96V15.54l15.941 32.083h8.373V24.179c0-16.677 1.473-20.227 3.952-20.227V.737H22.706v3.215ZM55.888 48.359c11.855 0 18.754-9.377 18.754-24.18C74.642 9.444 67.742 0 55.888 0 43.965 0 37.133 9.444 37.133 24.18c0 14.802 6.832 24.179 18.755 24.179Zm0-4.488c-3.416 0-4.823-2.679-4.823-19.692 0-17.012 1.407-19.691 4.823-19.691 3.349 0 4.755 2.679 4.755 19.691 0 17.013-1.407 19.692-4.755 19.692ZM76.008.737v3.215c2.344 0 2.746 3.55 2.746 20.227 0 16.678-.402 20.228-2.746 20.228v3.215h20.026c11.387 0 17.549-10.047 17.549-23.443 0-13.663-6.162-23.442-18.888-23.442H76.008Zm15.807 3.885h1.607c4.153 0 6.095 3.55 6.095 19.557 0 15.74-1.942 19.558-6.095 19.558h-1.607V4.622ZM114.895.737v3.215c2.344 0 2.746 3.55 2.746 20.227 0 16.678-.402 20.228-2.746 20.228v3.215h20.026c11.387 0 17.549-10.047 17.549-23.443 0-13.663-6.162-23.442-18.888-23.442h-18.687Zm15.807 3.885h1.607c4.153 0 6.095 3.55 6.095 19.557 0 15.74-1.942 19.558-6.095 19.558h-1.607V4.622ZM172 47.622v-3.215c-2.478 0-2.813-3.617-2.813-20.228 0-16.61.335-20.227 2.813-20.227V.737h-18.218v3.215c2.344 0 2.746 3.617 2.746 20.227 0 16.611-.402 20.228-2.746 20.228v3.215H172Z'
    />
  </svg>
);

export default LogoSvg;

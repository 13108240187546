import { TireHotelContract } from 'noddi-async/src/types';
import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';

import { Action } from '../Components/actions';
import { TireStorageBookingSteps } from '../tireStorageBookingSteps';

interface TireHotelBookingType {
  selectedAction: Action | null;
  selectedTireHotelContracts: TireHotelContract[];
  dialogOpen: boolean;
  currentStep: TireStorageBookingSteps;
  setSelectedAction: Dispatch<SetStateAction<Action | null>>;
  setSelectedTireHotelContracts: Dispatch<SetStateAction<TireHotelContract[]>>;
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<TireStorageBookingSteps>>;
}

const TireHotelContext = createContext<TireHotelBookingType>({} as TireHotelBookingType);

export const TireHotelProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedAction, setSelectedAction] = useState<Action | null>(null);
  const [selectedTireHotelContracts, setSelectedTireHotelContracts] = useState<TireHotelContract[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(TireStorageBookingSteps.address);

  return (
    <TireHotelContext.Provider
      value={{
        selectedAction,
        selectedTireHotelContracts,
        dialogOpen,
        currentStep,
        setCurrentStep,
        setSelectedAction,
        setSelectedTireHotelContracts,
        setDialogOpen
      }}
    >
      {children}
    </TireHotelContext.Provider>
  );
};

export function useTireHotelContext() {
  const context = useContext(TireHotelContext);
  if (context === undefined) {
    throw new Error('useTireStorageContext must be used within a TireStorageProvider');
  }
  return context;
}

import { URLKeys, noddiAsync } from 'noddi-async';

import { useBookingContext } from '../../../../../contexts/BookingContext';
import useSelectedData from '../../../../../hooks/useSelectedData';

const SALES_ITEM_SLUGS_TO_BE_BLOCKED = [
  'nb-wheel-change-lg',
  'nb-wheel-change-sm',
  'nb-tire-storage-included-wheel-change-lg',
  'nb-tire-storage-included-wheel-change-sm',
  'nb-tire-storage-only-storage'
];

export const useTireHotelCheck = () => {
  const { salesItems } = useSelectedData();
  const {
    bookingInputData: { selectedCars }
  } = useBookingContext();

  const shouldCheck = SALES_ITEM_SLUGS_TO_BE_BLOCKED.some((slug) => {
    const found = salesItems.find((item) => item.slug === slug);
    return !!found;
  });

  const vinNumbers = selectedCars.map((car) => car.vinNumber);

  const {
    data: tireHotelOptions,
    isPending: isTireHotelOptionsPending,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getTireHotelOptions,
    input: {
      vinNumbers
    },
    queryConfig: {
      enabled: shouldCheck
    }
  });

  const regNumbersWithTireHotel = (tireHotelOptions ?? [])
    .filter((item) => item.tireHotel.hasActiveTireHotel)
    .map((item) => item.licensePlateNumber);

  return {
    shouldCheck,
    isTireHotelOptionsPending,
    error,
    regNumbersWithTireHotel
  };
};

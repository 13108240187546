import { useAuthContext } from 'noddi-provider';
import { BookingTimeWindowPicker, ErrorPage, useGetScreenDimensions } from 'noddi-ui';
import { addDays } from 'noddi-util';
import styled from 'styled-components';

import { useBookingContext } from '../../contexts/BookingContext';

const TimeWindowPicker = () => {
  const { bookingInputData: inputData, salesItems, updateBookingInputData } = useBookingContext();
  const { isSuperUser } = useAuthContext();

  const { serviceAreaId } = inputData;
  const { screenSize } = useGetScreenDimensions();

  if (!serviceAreaId) {
    return <ErrorPage />;
  }

  return (
    <Wrapper height={screenSize.height * 0.55}>
      <BookingTimeWindowPicker
        serviceAreaId={serviceAreaId}
        salesItemIds={salesItems.map((salesItem) => salesItem.id)}
        initialFromDate={addDays(new Date(), 1) ?? new Date()}
        maxNumberOfSlidesOnPage={3}
        onUnavailableSelect={(timeWindow) => {
          if (!isSuperUser) {
            return;
          }
          updateBookingInputData({
            time: timeWindow
          });
        }}
        selectedTimeWindowId={inputData.time?.id}
        onAvailableSelect={(timeWindow) => {
          updateBookingInputData({
            time: timeWindow
          });
        }}
      />
    </Wrapper>
  );
};

export default TimeWindowPicker;

const Wrapper = styled.div<{ height: number }>`
  max-height: ${({ height }) => height}px;
  overflow: auto;
`;

import { AddHome } from '../svgs/AddHome';
import { Alert } from '../svgs/Alert';
import { AltArrowDown } from '../svgs/AltArrowDown';
import { AltArrowLeft } from '../svgs/AltArrowLeft';
import { AltArrowRight } from '../svgs/AltArrowRight';
import { AltArrowUp } from '../svgs/AltArrowUp';
import { ArrowDown } from '../svgs/ArrowDown';
import { ArrowLeft } from '../svgs/ArrowLeft';
import { ArrowRight } from '../svgs/ArrowRight';
import { ArrowUp } from '../svgs/ArrowUp';
import { Bill } from '../svgs/Bill';
import { Bus } from '../svgs/Bus';
import { Calendar } from '../svgs/Calendar';
import { CalendarPlus } from '../svgs/CalendarPlus';
import { Car } from '../svgs/Car';
import { ChatRounded } from '../svgs/ChatRounded';
import { Check } from '../svgs/Check';
import { CheckRounded } from '../svgs/CheckRounded';
import { ClockCircle } from '../svgs/ClockCircle';
import { CloseRounded } from '../svgs/CloseRounded';
import { Copy } from '../svgs/Copy';
import { Cross } from '../svgs/Cros';
import { Cup } from '../svgs/Cup';
import { DownArrowAlt } from '../svgs/DownArrowAlt';
import { Download } from '../svgs/Download';
import { Edit } from '../svgs/Edit';
import { Exclamation } from '../svgs/Exclamation';
import { Factory } from '../svgs/Factory';
import { Filter } from '../svgs/Filter';
import { HamburgerMenu } from '../svgs/HamburgerMenu';
import { Hammer } from '../svgs/Hammer';
import { Home } from '../svgs/Home';
import { HourGlass } from '../svgs/HourGlass';
import { Info } from '../svgs/Info';
import { LeftArrowAlt } from '../svgs/LeftArrowAlt';
import { LocationPin } from '../svgs/LocationPin';
import { Logout } from '../svgs/Logout';
import { Magnifier } from '../svgs/Magnifier';
import { MenuDots } from '../svgs/MenuDots';
import { Minus } from '../svgs/Minus';
import { Money } from '../svgs/Money';
import { Notes } from '../svgs/Notes';
import { Pause } from '../svgs/Pause';
import { Phone } from '../svgs/Phone';
import { Plus } from '../svgs/Plus';
import { QuestionMark } from '../svgs/QuestionMark';
import { RightArrowAlt } from '../svgs/RightArrowAlt';
import { Settings } from '../svgs/Settings';
import { ShoppingCart } from '../svgs/ShoppingCart';
import { Square } from '../svgs/Square';
import { Star } from '../svgs/Star';
import { TrashBin } from '../svgs/TrashBin';
import { UpArrowAlt } from '../svgs/UpArrowAlt';
import { UserCircle } from '../svgs/UserCircle';
import { Users } from '../svgs/Users';
import { Warning } from '../svgs/Warning';
import { Wheel } from '../svgs/Wheel';
import { WheelAngle } from '../svgs/WheelAngle';
import { IconSize } from './types';

export const iconMap = {
  ArrowUp,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  Bill,
  Calendar,
  ChatRounded,
  ClockCircle,
  Check,
  CheckRounded,
  CloseRounded,
  Cross,
  Cup,
  DownArrowAlt,
  Download,
  Edit,
  Exclamation,
  Filter,
  Factory,
  HamburgerMenu,
  Home,
  LeftArrowAlt,
  Magnifier,
  MenuDots,
  Plus,
  QuestionMark,
  RightArrowAlt,
  Settings,
  Star,
  TrashBin,
  UpArrowAlt,
  UserCircle,
  Users,
  WheelAngle,
  LocationPin,
  Info,
  Phone,
  AddHome,
  AltArrowDown,
  AltArrowLeft,
  AltArrowRight,
  AltArrowUp,
  Warning,
  Car,
  Wheel,
  ShoppingCart,
  Money,
  Copy,
  Square,
  Logout,
  HourGlass,
  Alert,
  Pause,
  CalendarPlus,
  Hammer,
  Bus,
  Minus,
  Notes
};

export type IconName = keyof typeof iconMap;

interface NoddiIconProps extends Pick<React.SVGProps<SVGSVGElement>, 'color' | 'className' | 'onClick'> {
  name: IconName;
  size?: IconSize;
}

export const NoddiIcon = ({ name, size = 32, color, className, onClick }: NoddiIconProps) => {
  const IconComponent = iconMap[name];

  if (!IconComponent) {
    return null;
  }

  return (
    <IconComponent
      width={size}
      height={size}
      color={color}
      className={className}
      onClick={onClick}
      viewBox='0 0 32 32'
    />
  );
};

import { t } from '@lingui/macro';

// All js macros such as t plural, select, selectOrdinal cannot be used on the module level, hence
// these are exported as functions to be used in the ErrorMessage component.

export function getErrorCodes() {
  const errorCodes = {
    invalid_phone_number: t`invalid_phone_number`,
    invalid_verification_code: t`invalid_verification_code`,
    user_not_naf_member: t`user_not_naf_member`,
    new_user_not_eligible_for_campaign: t`new_user_not_eligible_for_campaign`,
    booking_time_window_not_bookable: t`booking_time_window_not_bookable`,
    user_phone_or_email_already_exists: t`user_phone_or_email_already_exists`,
    capacity_contribution_already_exists: t`capacity_contribution_already_exists`,
    car_not_found: t`car_not_found`,
    credentials_invalid: t`credentials_invalid`,
    phone_number_verification_error: t`phone_number_verification_error`,
    pin_code_verification_error: t`pin_code_verification_error`,
    password_verification_failed: t`password_verification_failed`
  } as const;
  return errorCodes;
}

export function getGenericErrorMessages() {
  const genericErrorMessageIdentifiers = {
    genericErrorMessage: t`genericErrorMessage`,
    genericFirewallText: t`genericFirewallText`
  } as const;
  return genericErrorMessageIdentifiers;
}

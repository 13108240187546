import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import routes from '../../../../appRoutes';
import BookingScreen from '../../BookingScreen';
import { useStepValidator } from '../../hooks/useStepValidator';
import CarSelector from './CarSelector';

const Car = () => {
  const navigate = useNavigate();
  const { carStepOk } = useStepValidator();
  const enableNextButton = carStepOk();

  function onNextButtonClicked() {
    navigate(routes.newBookingServicesStep.getPath());
  }

  return (
    <BookingScreen seoTitle={t`Select cars`} onNextClicked={onNextButtonClicked} disableNextButton={!enableNextButton}>
      <div className='mb-2'>
        <Typography variant='h5'>
          <Trans>Which cars do you need help with?</Trans>
        </Typography>
      </div>

      <CarSelector />
    </BookingScreen>
  );
};

export default Car;

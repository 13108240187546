import { Trans } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AvailableSalesItem, SalesItemSlugs } from 'noddi-async/src/types';
import { ErrorPage, LoadingScreen, SelectableCard } from 'noddi-ui';

import { useBookingContext } from '../../../../../contexts/BookingContext';
import { getSalesItemIconBySlug } from '../../../../../helper/salesItems';
import useSelectSalesItem from '../../../../../hooks/useSelectSalesItem';

const ReturnChoices = () => {
  const { data, isPending, error } = noddiAsync.useGet({
    type: URLKeys.getSalesItemsForBooking,
    input: {
      group: 'tire-storage-cancel-options'
    },
    queryConfig: {
      staleTime: Infinity
    }
  });

  const {
    bookingInputData: { selectedCars }
  } = useBookingContext();
  const { selectSalesItemForMultipleCars, isSelected } = useSelectSalesItem();

  if (isPending) {
    return <LoadingScreen />;
  }
  if (error) {
    return <ErrorPage apiError={error} />;
  }

  const checkIfSelected = (salesItem: AvailableSalesItem) => {
    return selectedCars.some((car) => {
      return isSelected({ licensePlateNumber: car.licensePlateNumber, salesItem });
    });
  };

  return (
    <>
      <Typography variant='h5' marginTop={3} marginBottom={3}>
        <Trans>Return options</Trans>
      </Typography>
      <Stack direction='column' gap={2}>
        {data.map((salesItem) => {
          // TODO: remove this when we have a proper solution for this
          if (salesItem.slug === SalesItemSlugs['nb-tire-storage-pickup-yourself']) {
            return;
          }
          return (
            <SelectableCard
              key={salesItem.id}
              selected={checkIfSelected(salesItem)}
              onClick={(_) => {
                selectSalesItemForMultipleCars({
                  licensePlateNumbers: selectedCars.map((car) => car.licensePlateNumber),
                  salesItem,
                  isAddon: false,
                  queryParams: {
                    urlKey: URLKeys.getSalesItemsForBooking,
                    queryInput: {
                      group: 'tire-storage-cancel-options'
                    }
                  }
                });
              }}
              mode='select'
            >
              <Stack alignItems='center'>
                {getSalesItemIconBySlug(salesItem.slug)}
                <b>{salesItem.price} kr</b>

                <Typography textAlign='center'>{`${salesItem.description}`}</Typography>
              </Stack>
            </SelectableCard>
          );
        })}
      </Stack>
    </>
  );
};

export default ReturnChoices;

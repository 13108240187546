import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { AddressProps, ServiceAreasDataFromCoordinate } from 'noddi-async/src/types';

import { getLongAddressName } from '../../../helpers';
import { TypographyWithInfoSign, getGenericErrorMessages } from '../../Elements';
import { ServiceAvailabilityInfo } from './ServiceAvailabilityInfo';
import { hasStreetNumber } from './addressUtils';

type SelectedAddressDetailsProps = {
  isServiceAreaError: boolean;
  serviceAreas: ServiceAreasDataFromCoordinate | undefined;
  hasServiceCategories: boolean;
  address: AddressProps | null;
};

const SelectedAddressDetails = ({
  isServiceAreaError,
  serviceAreas,
  hasServiceCategories,
  address
}: SelectedAddressDetailsProps) => {
  if (!address) {
    return null;
  }

  const { genericFirewallText } = getGenericErrorMessages();
  if (isServiceAreaError) {
    return <Typography style={{ marginTop: 12 }}>{genericFirewallText}</Typography>;
  }

  if (!hasStreetNumber(address)) {
    return (
      <TypographyWithInfoSign>
        <Trans>Please enter an address with a street number so that we can check if we can come to you!</Trans>
      </TypographyWithInfoSign>
    );
  }

  return hasServiceCategories ? (
    <div style={{ marginTop: 24 }}>
      <ServiceAvailabilityInfo
        address={address ? getLongAddressName(address) : undefined}
        serviceCategories={serviceAreas?.serviceCategories ?? []}
        unavailableServiceCategories={serviceAreas?.unavailableServiceCategories ?? []}
      />
    </div>
  ) : (
    <TypographyWithInfoSign>
      <Trans>
        Sorry, we do not currently deliver services to your address. Try another address, or try again at a later time
      </Trans>
    </TypographyWithInfoSign>
  );
};

export { SelectedAddressDetails };

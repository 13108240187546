import { NoddiProgressBar } from 'noddi-ui/src/atoms/NoddiProgressBar';
import { useLocation } from 'react-router-dom';

import routes from '../../../appRoutes';

// we want do a weighted progress in our booking to encourage users to complete the booking
function getWeightedBookingProggess(pathname: string) {
  switch (pathname) {
    case routes.newBookingAddressStep.getPath():
      return 0;
    case routes.newBookingCarStep.getPath():
      return 30;
    case routes.newBookingServicesStep.getPath():
      return 50;
    case routes.newBookingAddonsStep.getPath():
    case routes.newBookingWheelStorageStep.getPath():
      return 80;
    case routes.newBookingTimeSlotStep.getPath():
      return 90;
    case routes.newBookingSummaryStep.getPath():
      return 100;

    default:
      return 100;
  }
}

const BookingProgressBar = () => {
  const { pathname } = useLocation();

  return <NoddiProgressBar value={getWeightedBookingProggess(pathname)} />;
};

export default BookingProgressBar;

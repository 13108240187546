import { RegisteredCar } from 'noddi-async/src/types';

export const isLicensePlateNumberAlreadyPicked = (
  registrationNumbers: (string | number)[],
  registrationNumber: string,
  registeredCars: RegisteredCar[]
) =>
  registrationNumbers.some(
    (number) =>
      number?.toString().toLowerCase() === registrationNumber.toLowerCase() ||
      registeredCars.some((car) => car.licensePlateNumber.toLowerCase() === registrationNumber.toLowerCase())
  );

import { Trans, t } from '@lingui/macro';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { useNoddiToast } from 'noddi-provider/src/utils/toast';
import {
  ApiErrorMessage,
  LoadingScreen,
  NoddiButton,
  NoddiContainer,
  NoddiIcon,
  OrderSummary,
  colors,
  getLongAddressName
} from 'noddi-ui';
import { DateFormats, format } from 'noddi-util';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import routes from '../../../appRoutes';
import { CancelDialog } from './CancelDialog';
import { MetaSection } from './MetaSection';
import NoBookingDataFound from './NoBookingDataFound';

const BookingDetails = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const navigate = useNavigate();
  const { id: bookingId } = useParams();
  const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
  const { noddiToast } = useNoddiToast();

  const bookingIdAsString = bookingId as string;
  const {
    isPending: isBookingPending,
    data: bookingData,
    isError,
    refetch: refetchBookingData
  } = noddiAsync.useGet({
    type: URLKeys.getUserBooking,
    input: { userGroupId: userGroupId as number, bookingId: bookingIdAsString },
    queryConfig: {
      enabled: !!userGroupId && !!bookingId
    }
  });

  const { mutateAsync: cancelBooking, isPending: isCancelling } = noddiAsync.usePost({
    type: URLKeys.getCancelBooking,
    queryConfig: {
      onSuccess: () => {
        refetchBookingData();
      }
    }
  });

  const bookingIdNumber = Number(bookingId);

  const {
    isLoading: isDownloadingReceipt,
    refetch: downloadReceipt,
    error: downloadReceiptError
  } = noddiAsync.useGet({
    type: URLKeys.downloadBookingReceipt,
    input: {
      bookingId: bookingIdNumber
    },
    queryConfig: {
      enabled: false
    }
  });

  const {
    isPending: isBookingPermissionsPending,
    data: bookingPermissions,
    isError: isBookingPermissionsError
  } = noddiAsync.useGet({
    type: URLKeys.getEditBookingPersmissions,
    input: { id: bookingIdAsString },
    queryConfig: {
      enabled: !!bookingId
    }
  });

  const getDateText = () => {
    if (bookingData?.completedAt) {
      return `${t`Delivered`} ${format(bookingData?.completedAt, DateFormats.FULL_MONTH_YEAR_DATE_TIME)}`;
    } else if (bookingData?.startedAt) {
      return `${t`Started`} ${format(bookingData?.startedAt, DateFormats.FULL_MONTH_YEAR_DATE_TIME)}`;
    } else if (bookingData?.bookingTimeWindow) {
      return `${t`Scheduled`} ${format(
        bookingData?.bookingTimeWindow.startPublic,
        DateFormats.FULL_MONTH_YEAR_DATE_TIME
      )} - ${format(bookingData?.bookingTimeWindow.endPublic, DateFormats.TIME)}`;
    } else {
      return '?';
    }
  };

  if (isBookingPending || isCancelling || isBookingPermissionsPending) {
    return <LoadingScreen />;
  }
  if (isError || !bookingData || isBookingPermissionsError) {
    return <NoBookingDataFound />;
  }

  function navigateToEditBookingTimeWindow(id: number) {
    navigate(routes.editMyBookingTimeWindow.getPath({ id }));
  }

  const showActionButtons = !bookingData?.isCancelled && !bookingData?.isCancelClosed;
  const showPaymentButton = bookingData?.completedAt && bookingData.amountDue && bookingData.amountDue > 0;

  return (
    <NoddiContainer
      breadcrumbProps={{
        links: [
          {
            title: t`Your bookings`,
            path: routes.homePageBookings.getPath()
          },
          {
            title: bookingData?.slug
          }
        ]
      }}
      headerNode={
        <>
          {showActionButtons && (
            <>
              <Stack direction='row' gap={2} alignItems='center'>
                {bookingPermissions.timeWindows.canEdit && (
                  <NoddiButton
                    onClick={() => navigateToEditBookingTimeWindow(bookingData.id)}
                    endIcon={<NoddiIcon name='Edit' color={colors.primary.white} className='size-5' />}
                  >
                    <Typography variant='body1'>
                      <Trans>Change time</Trans>
                    </Typography>
                  </NoddiButton>
                )}
                <NoddiButton
                  variant='secondary'
                  endIcon={<NoddiIcon name='CloseRounded' />}
                  onClick={() => setCancelDialogIsOpen(true)}
                  disabled={isCancelling}
                >
                  <Trans>Cancel</Trans>
                </NoddiButton>
                <CancelDialog
                  open={cancelDialogIsOpen}
                  onClose={() => setCancelDialogIsOpen(false)}
                  bookingIdNumber={bookingIdNumber}
                  cancelBooking={cancelBooking}
                />
              </Stack>
            </>
          )}
          {showPaymentButton ? (
            <Button
              variant='contained'
              onClick={() => navigate(routes.bookingInfo.getPath({ slug: bookingData?.slug }))}
            >
              <Trans>Pay</Trans>
            </Button>
          ) : null}
          {bookingData?.hasReceipt && (
            <>
              {downloadReceiptError && <ApiErrorMessage error={downloadReceiptError} />}
              <Button
                variant='contained'
                disabled={isDownloadingReceipt}
                endIcon={<NoddiIcon name='Download' color={colors.primary.white} size={24} />}
                onClick={async () => {
                  const { error } = await downloadReceipt();

                  if (error) {
                    return noddiToast.error(t`Could not download receipt`);
                  }

                  return refetchBookingData();
                }}
              >
                <Trans>Receipt</Trans>
              </Button>
            </>
          )}
        </>
      }
      header={t`Your bookings`}
    >
      {bookingData && (
        <OrderSummary
          MetaSection={
            <MetaSection
              isCancelled={bookingData?.isCancelled}
              slug={bookingData?.slug}
              address={bookingData?.address ? getLongAddressName(bookingData.address) : undefined}
              getDateText={getDateText}
            />
          }
          carItemLines={bookingData.cars}
          otherOrderLines={bookingData.otherOrderLines}
          discountLines={bookingData.discountOrderLines}
        />
      )}
    </NoddiContainer>
  );
};

export default BookingDetails;

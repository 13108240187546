import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';

const ForbiddenPage = () => {
  return (
    <div style={{ display: 'grid', placeContent: 'center', height: '100vh', textAlign: 'center' }}>
      <Typography variant='h1'>
        <Trans>Forbidden access</Trans>
      </Typography>
      <Typography variant='subtitle1'>
        <Trans>You do not have permission to access this application</Trans>
      </Typography>
    </div>
  );
};

// expect that all apps have same url to forbidden page
const forbiddenPageUrl = '/forbidden';

export { ForbiddenPage, forbiddenPageUrl };

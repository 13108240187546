import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { NoddiAsyncError } from 'noddi-async/src/types';

import { NoddiButton } from '../Buttons';
import { mapErrorCodeToUiMessage } from './ErrorMessage';

type ErrorPageProps = {
  errorTitle?: string;
  errorMessage?: string;
  apiError?: NoddiAsyncError | Array<NoddiAsyncError | null>;
};

const ErrorPage = ({ errorMessage, errorTitle, apiError }: ErrorPageProps) => {
  const errorToRender = Array.isArray(apiError) ? apiError.find((e) => e !== null) : apiError;
  const apiErrorMessage = errorToRender && mapErrorCodeToUiMessage({ error: errorToRender });

  const customErrorMessage = apiErrorMessage || errorMessage;

  if (!customErrorMessage) {
    return null;
  }

  return (
    <Stack
      direction='column'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '60vh',
        textAlign: 'center'
      }}
    >
      <Typography variant='h3'>
        {errorTitle ? errorTitle : <Trans>We're sorry, an error has occurred</Trans>}
      </Typography>
      <Typography variant='subtitle1'>
        {customErrorMessage ? (
          customErrorMessage
        ) : (
          <Trans>We are working on solving the issue, please try again later</Trans>
        )}
      </Typography>
      <NoddiButton variant='primary' onClick={() => window.location.reload()} style={{ marginTop: '20px' }}>
        <Typography>
          <Trans>Try again</Trans>
        </Typography>
      </NoddiButton>
    </Stack>
  );
};

export { ErrorPage };

import { Typography } from '@mui/material';
import { AvailableSalesItem } from 'noddi-async/src/types';
import { NoddiIcon } from 'noddi-ui';

import SalesItemListItem from '../../../../components/SalesItemListItem';
import { SelectedCar, SelectedSalesItemQueryInput } from '../../interfaces';
import { BookingCardPadding, BookingStepCard } from '../BookingCards';

type AddonPickerProps = {
  car: SelectedCar;
  addons: AvailableSalesItem[];
  queryParams: SelectedSalesItemQueryInput;
};
const AddonPicker = ({ car, addons, queryParams }: AddonPickerProps) => {
  if (!addons) {
    return (
      <Typography variant='h5' style={{ marginBottom: '1em' }}>
        Fant ingen ekstra tjenester for denne bilen.
      </Typography>
    );
  }

  return (
    <>
      <Typography marginBottom={1} variant='h6'>
        <span
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <NoddiIcon name='Car' size={24} className='mb-1 mr-2' />
          {`${car.carName} - ${car.licensePlateNumber}`}
        </span>
      </Typography>
      <BookingStepCard>
        <BookingCardPadding>
          {addons.map((addon) => (
            <SalesItemListItem key={addon.id} car={car} salesItem={addon} queryParams={queryParams} isAddon={true} />
          ))}
        </BookingCardPadding>
      </BookingStepCard>
    </>
  );
};

export default AddonPicker;

import { t } from '@lingui/macro';
import { storage } from 'noddi-util';
import { Dispatch, SetStateAction } from 'react';

type LoadLocalesProps = {
  setIsLanguageLoaded: Dispatch<SetStateAction<boolean>>;
  defaultLanguage?: 'en' | 'nb';
  dynamicActivateLocale: (locale: string) => Promise<void>;
};

export const localeStorageLocaleKey = 'locale';

export const getSupportedLocales = () => ({
  en: { label: t`English`, flag: '🇬🇧' },
  nb: { label: t`Norwegian`, flag: '🇳🇴' }
});

export async function initLocales({
  setIsLanguageLoaded,
  defaultLanguage = 'en',
  dynamicActivateLocale
}: LoadLocalesProps) {
  const locale = storage.local.getItem<string>(localeStorageLocaleKey) || defaultLanguage;

  // set default locale if locale is not supported
  if (!Object.keys(getSupportedLocales()).includes(locale)) {
    storage.local.setItem(localeStorageLocaleKey, defaultLanguage);
    return dynamicActivateLocale(defaultLanguage).then(() => setIsLanguageLoaded(true));
  }

  return dynamicActivateLocale(locale).then(() => setIsLanguageLoaded(true));
}

import { Trans } from '@lingui/macro';
import { AppBar, Box, Collapse, Divider, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { useAuthContext } from 'noddi-provider';
import { NoddiButton, NoddiIcon, NoddiLogo, colors, useIsMobile } from 'noddi-ui';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import ImpersonatingAlert from '../ImpersonatingAlert';

function ActionButtons({
  setOpenDrawer,
  showMyPageButton
}: {
  showMyPageButton: boolean;
  setOpenDrawer: (setOpen: boolean) => void;
}) {
  const navigate = useNavigate();

  function goToLogin() {
    const redirectUrl = window.location.pathname !== routes.login.getPath() ? window.location.pathname : undefined;
    const extraParams = redirectUrl ? `?redirectUrl=${redirectUrl}` : '';
    navigate(`${routes.login.getPath()}${extraParams}`);
  }

  function goToNewBooking() {
    navigate(routes.newBookingAddressStep.getPath());
  }
  function goToProfile() {
    navigate(routes.profile.getPath());
  }

  return (
    <>
      <NoddiButton
        variant='secondary'
        onClick={() => {
          setOpenDrawer(false);
          if (showMyPageButton) {
            goToProfile();
          } else {
            goToLogin();
          }
        }}
      >
        {showMyPageButton ? <Trans>My profile </Trans> : <Trans>Sign in</Trans>}
      </NoddiButton>
      <NoddiButton
        onClick={() => {
          setOpenDrawer(false);
          goToNewBooking();
        }}
      >
        <Trans>Create booking</Trans>
      </NoddiButton>
    </>
  );
}
export const HeaderNav = () => {
  const { isLoggedIn, impersonatedUser } = useAuthContext();
  const isMobile = useIsMobile();
  const [openDrawer, setOpenDrawer] = useState(false);

  const isImperonating = !!impersonatedUser;

  const logoRoute = isLoggedIn ? '/' : 'https://noddi.no';

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {isImperonating && (
          <AppBar>
            <ImpersonatingAlert />
          </AppBar>
        )}
        <AppBar
          position='static'
          style={{
            backgroundColor: '#fff',
            paddingTop: '6px',
            paddingBottom: '5px',
            display: 'flex',
            boxShadow: 'none',
            marginTop: isImperonating ? '50px' : undefined
          }}
        >
          <Toolbar>
            <Typography
              variant='h6'
              component='div'
              sx={{ flexGrow: 1 }}
              style={{ marginLeft: !isMobile ? '48px' : undefined }}
            >
              <Link to={logoRoute}>
                <NoddiLogo
                  style={{
                    maxWidth: '128px'
                  }}
                />
              </Link>
            </Typography>

            {isMobile ? (
              <IconButton
                size='large'
                edge='start'
                color='inherit'
                aria-label='menu'
                sx={{ mr: 2 }}
                style={{
                  color: ' #ffefeb',
                  backgroundColor: colors.primary.orange,
                  borderRadius: '10px'
                }}
                onClick={() => setOpenDrawer(!openDrawer)}
              >
                {!openDrawer ? (
                  <NoddiIcon name='HamburgerMenu' size={24} color={colors.primary.white} />
                ) : (
                  <NoddiIcon name='Cross' size={24} color={colors.primary.white} />
                )}
              </IconButton>
            ) : (
              <Stack
                alignItems='center'
                sx={{ p: '3px ' }}
                spacing={2}
                direction='row'
                style={{
                  marginRight: '5rem'
                }}
              >
                <ActionButtons showMyPageButton={isLoggedIn} setOpenDrawer={setOpenDrawer} />
              </Stack>
            )}
          </Toolbar>
          {isMobile && (
            <Collapse in={openDrawer} timeout='auto' unmountOnExit>
              <Divider sx={{ mt: 2 }} />
              <Stack p={3} gap={2}>
                <ActionButtons showMyPageButton={isLoggedIn} setOpenDrawer={setOpenDrawer} />
              </Stack>
            </Collapse>
          )}
        </AppBar>
      </Box>
    </>
  );
};

import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

import routes from '../../../../appRoutes';
import { useSalesItems } from '../../../../hooks/useSalesItems';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { useCouponStore } from '../../../../stores/CouponStore';
import { GtmEvents } from '../../../../types/gtmTracking';

type OnNextButtonClickProps = {
  setOnNextButtonIsLoading: Dispatch<SetStateAction<boolean>>;
};

const useOnNextButtonClick = () => {
  const { getSelectedSalesItemsFromCache } = useSalesItems();
  const { coupons } = useCouponStore();

  const navigate = useNavigate();
  const { currentUserGroupId, isLoggedIn } = useAuthContext();

  const { refetch } = noddiAsync.useGet({
    type: URLKeys.getMembershipPrograms,
    input: { userGroupId: currentUserGroupId as number },
    queryConfig: {
      enabled: false,
      staleTime: Infinity
    }
  });

  const navigateToNextStep = async ({ setOnNextButtonIsLoading }: OnNextButtonClickProps) => {
    const salesItems = getSelectedSalesItemsFromCache();
    if (!salesItems || salesItems.length === 0) {
      return navigate(routes.newBookingTimeSlotStep.getPath());
    }

    let membershipData;
    if (isLoggedIn) {
      setOnNextButtonIsLoading(true);
      membershipData = await refetch();
      setOnNextButtonIsLoading(false);
    }

    GoogleTrackingService.trackEvent({
      eventType: GtmEvents.beginCheckout,
      data: { salesItems, membershipData: membershipData ? membershipData.data : [], coupons }
    });

    return navigate(routes.newBookingTimeSlotStep.getPath());
  };

  return { navigateToNextStep };
};

export default useOnNextButtonClick;

import { Trans, t } from '@lingui/macro';
import { TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { URLKeys, noddiAsync } from 'noddi-async';
import { UserRoleType, useAuthContext } from 'noddi-provider';
import { hasValidPermission } from 'noddi-provider/src/utils';
import { useState } from 'react';

import { NoddiButton } from '../../../molecules/NoddiButton';
import { mapErrorCodeToUiMessage } from '../../Elements/Errors/ErrorMessage';

interface EmailLoginProps {
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  requiredLoginLevels?: UserRoleType[];
}

const EmailLogin = ({ setErrorMessage, requiredLoginLevels }: EmailLoginProps) => {
  const { loginUser } = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { isPending, mutateAsync: login } = noddiAsync.usePost({
    type: URLKeys.postEmailLogin,
    queryConfig: {
      onSuccess: (res) => {
        if (requiredLoginLevels) {
          if (!hasValidPermission(requiredLoginLevels, res.data.user)) {
            setErrorMessage(t`You do not have permission to access this page`);
            return;
          }
        }
        loginUser(res.data);
        setEmail('');
        setPassword('');
      },

      onError: (error) => {
        setErrorMessage(mapErrorCodeToUiMessage({ error }));
      }
    }
  });

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        login({ email, password });
      }}
    >
      <Stack spacing={3}>
        <TextField
          autoFocus
          fullWidth
          label='Email Address'
          name='email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label='Password'
          name='password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Stack>
      <NoddiButton style={{ marginTop: '24px' }} disabled={isPending} type='submit' fullWidth>
        <Trans>Logg inn</Trans>
      </NoddiButton>
    </form>
  );
};

export { EmailLogin };

import { Trans } from '@lingui/macro';
import { IconName, NoddiIcon, NoddiText, colors } from 'noddi-ui';

type NoddiSelectableItemProps = {
  title: string;
  description?: string;
  isSelected: boolean;
  iconName: IconName;
  onAdd: () => void;
  onRemove: () => void;
};

const NoddiSelectableItem = ({
  title,
  iconName,
  isSelected,
  description,
  onAdd,
  onRemove
}: NoddiSelectableItemProps) => {
  return (
    <div
      className={`flex min-w-[300px] cursor-pointer items-start rounded-lg p-4 transition-all  hover:shadow-cardShadow ${
        isSelected ? 'bg-primary-purple text-primary-white' : 'bg-primary-white'
      } `}
      onClick={() => {
        if (isSelected) {
          onRemove?.();
        } else {
          onAdd?.();
        }
      }}
    >
      <NoddiIcon name={iconName} color={isSelected ? colors.primary.white : colors.primary.black} className='mr-4' />
      <div className='mr-2 flex flex-col'>
        <NoddiText>{title}</NoddiText>

        {description && <NoddiText className='opacity-[50%]'>{description}</NoddiText>}
      </div>

      {isSelected ? (
        <div className='my-auto ml-auto flex cursor-pointer  items-center'>
          <NoddiText className='mr-2'>
            <Trans>Remove</Trans>
          </NoddiText>
          <NoddiIcon name='Minus' size={16} color={isSelected ? colors.primary.white : colors.primary.black} />
        </div>
      ) : (
        <div className='my-auto ml-auto flex cursor-pointer items-center'>
          <NoddiText className='mr-2'>
            <Trans>Add</Trans>
          </NoddiText>
          <NoddiIcon name='Plus' size={16} color={isSelected ? colors.primary.white : colors.primary.black} />
        </div>
      )}
    </div>
  );
};

export { NoddiSelectableItem };

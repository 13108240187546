import { Trans } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { NoddiLinearProgressLoader } from 'noddi-ui';

import { useBookingContext } from '../../../../contexts/BookingContext';
import AddMoreCarsView from './AddMoreCars';
import CarListItem from './CarListItem';

const CarSelector = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const { bookingInputData } = useBookingContext();

  const { isLoading, data: registeredCars } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCars,
    input: { userGroupId },
    queryConfig: {
      staleTime: Infinity,
      enabled: !!userGroupId
    }
  });

  const selectedCars = bookingInputData.selectedCars;

  if (isLoading) {
    return <NoddiLinearProgressLoader />;
  }

  return (
    <>
      <AddMoreCarsView registeredCars={registeredCars} />

      {selectedCars.length > 0 && (
        <>
          <p>
            <Trans>Selected cars</Trans>
          </p>
          <div className='mb-4 mt-3 flex flex-col gap-3'>
            {selectedCars.map((car) => (
              <CarListItem key={car.licensePlateNumber} selectedCar={car} />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default CarSelector;

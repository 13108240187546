import { colors } from 'noddi-ui';
import styled from 'styled-components';

export const BookingCardPadding = styled.div`
  @media (min-width: 500px) {
    padding: 3rem;
  }
  position: relative;
  padding: 2.5rem 1rem 1rem 1rem;
  border-radius: 1rem;
`;
export const BookingStepCard = styled.div`
  background-color: white;
  border-radius: 1rem;
  border: 1px solid ${colors?.primary?.orange};
  width: 100%;
`;

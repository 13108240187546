import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { CouponCard, ErrorPage, LoadingScreen, NoddiContainer } from 'noddi-ui';

import NewAppointmentLink from '../../components/Links/NewAppointment';

const Coupons = () => {
  const { isTokenLoginLoading, currentUserGroupId } = useAuthContext();

  const {
    data: coupons,
    isPending,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCoupons,
    input: { id: currentUserGroupId as number, includeUsedOnBookingIds: [] }
  });

  if (isTokenLoginLoading || isPending) {
    return <LoadingScreen />;
  }
  if (error) {
    return <ErrorPage apiError={error} />;
  }

  return (
    <NoddiContainer
      header={t`Your coupons`}
      description={t`Overview of all your coupons`}
      headerNode={<NewAppointmentLink />}
    >
      <div style={{ maxWidth: '500px', margin: '0 auto' }}>
        {coupons?.map((coupon) => (
          <CouponCard
            key={coupon.id}
            namePublic={coupon.namePublic}
            descriptionPublic={coupon.descriptionPublic}
            amount={coupon.amount}
            amountPercentage={coupon.amountPercentage}
            containerSx={{ marginBottom: '22px' }}
          />
        ))}
      </div>
    </NoddiContainer>
  );
};

export default Coupons;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

/* eslint-disable-next-line no-duplicate-imports */
import { Locale } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { PropsWithChildren } from 'react';

const customEnLocale: Locale = {
  ...enUS,
  options: {
    ...enUS.options,
    // Sunday = 0, Monday = 1.
    weekStartsOn: 1
  }
};

const NoddiLocalizationProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
      {children}
    </LocalizationProvider>
  );
};

export { NoddiLocalizationProvider };

import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useAuthContext } from 'noddi-provider';
import { NoddiButtonLink } from 'noddi-ui';

import routes from '../../../../../appRoutes';

type Props = {
  licensePlate: string;
};
const AlreadyHasTireHotel = ({ licensePlate }: Props) => {
  const { isLoggedIn } = useAuthContext();

  return (
    <Stack
      direction='column'
      padding={4}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '40vh',
        textAlign: 'center'
      }}
    >
      <Typography variant='h5'>
        <Trans>We already have your tires!</Trans>
      </Typography>
      <Typography variant='subtitle1'>
        <Trans>Please log in and manage your tire hotel subscription from</Trans> "<Trans>My tire hotel</Trans>"{' '}
        <Trans>for</Trans> {licensePlate}
      </Typography>
      <NoddiButtonLink variant='primary' to={routes.tireStorage.getPath()} style={{ marginTop: '20px' }}>
        <Typography>{isLoggedIn ? <Trans>My tire hotel</Trans> : <Trans>Log in</Trans>}</Typography>
      </NoddiButtonLink>
    </Stack>
  );
};

export default AlreadyHasTireHotel;

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';
import { hasStreetNumber } from 'noddi-ui';
import { useLocation, useNavigate } from 'react-router-dom';

import routes from '../../../appRoutes';
import { useBookingContext } from '../../../contexts/BookingContext';
import { useWheelStore } from '../../../stores/WheelStore';
import { SelectedCar, SelectedSalesItem } from '../interfaces';

type AllCarsHaveAtLeastOneSalesItemProps = {
  cars: SelectedCar[];
  salesItems: SelectedSalesItem[];
};

const allCarsHaveAtLeastOneSalesItem = ({ cars, salesItems }: AllCarsHaveAtLeastOneSalesItemProps) => {
  return cars.every((car) => salesItems.map((item) => item.licensePlateNumber).includes(car.licensePlateNumber));
};

const logValidationMsgAndNavigate = (currentStep: string, failedStep: string, navigate: () => void, payload?: any) => {
  const extraContext = payload ? { payload } : {};

  const logMsg = `Validation failed on step: ${currentStep}. Failed when validating requirements for step: ${failedStep}`;

  if (import.meta.env.MODE !== 'production') {
    console.error(logMsg, extraContext);
  } else {
    Sentry.captureMessage(
      `Validation failed on step: ${currentStep}. Failed when validating requirements for step: ${failedStep}`,
      {
        level: 'warning',
        ...extraContext
      }
    );
  }

  navigate();
};

export const useStepValidator = () => {
  const { salesItems, bookingInputData } = useBookingContext();
  const navigate = useNavigate();
  const { selectedWheelStorageOptions, getWheelStorageItems } = useWheelStore();

  const { pathname: activeStep } = useLocation();

  const addressStepOk = () => {
    const { address, serviceCategories } = bookingInputData;
    const hasServiceCategories = Boolean(serviceCategories?.length ?? 0 > 0);

    return hasServiceCategories && hasStreetNumber(address);
  };

  const carStepOk = () => {
    const { selectedCars } = bookingInputData;
    return selectedCars.length > 0;
  };

  const salesItemStepOk = () => {
    return allCarsHaveAtLeastOneSalesItem({ cars: bookingInputData.selectedCars, salesItems });
  };

  const wheelStorageStepOk = () => {
    const wheelStorageSalesItems = getWheelStorageItems();
    const vehiclesWithWheelStorage = [...new Set(wheelStorageSalesItems.map((item) => item.licensePlateNumber))];

    const allHaveSelectedState = vehiclesWithWheelStorage.every((licensePlateNumber) => {
      const selectedOptions = selectedWheelStorageOptions.find(
        (option) => option.licensePlateNumber === licensePlateNumber
      );
      if (!selectedOptions) {
        return false;
      }
      const { pickUpAddress, storageType } = selectedOptions;

      return pickUpAddress && storageType;
    });

    return allHaveSelectedState;
  };

  const timeWindowsStepOk = () => {
    const { time } = bookingInputData;
    return time && time.id;
  };

  /**
   * This function is used to validate the current step in a multi-step process.
   * It checks the active step and performs validation accordingly. If the validation fails, the function logs a validation message and navigates to the failed step.
   * # TODO: perhaps not the best UX to navigate to the failed step, but for now it's .
   *
   * @returns {boolean} - The function returns true if the active step is valid, otherwise it logs a validation message and navigates to the appropriate step.
   **/
  const validateStep = () => {
    if (activeStep === routes.newBookingAddressStep.getPath()) {
      return true;
    }

    const addressOk = addressStepOk();
    if (!addressOk) {
      return logValidationMsgAndNavigate(
        routes.newBookingCarStep.getPath(),
        routes.newBookingAddressStep.getPath(),
        () => navigate(routes.newBookingAddressStep.getPath())
      );
    }

    if (activeStep === routes.newBookingCarStep.getPath()) {
      return true;
    }

    const carOk = carStepOk();
    if (!carOk) {
      return logValidationMsgAndNavigate(
        routes.newBookingServicesStep.getPath(),
        routes.newBookingCarStep.getPath(),
        () => navigate(routes.newBookingCarStep.getPath())
      );
    }

    if (
      activeStep === routes.newBookingServicesStep.getPath() ||
      activeStep === routes.newBookingAddonsStep.getPath()
    ) {
      return true;
    }

    const salesItemOk = salesItemStepOk();
    if (!salesItemOk) {
      return logValidationMsgAndNavigate(
        routes.newBookingTimeSlotStep.getPath(),
        routes.newBookingServicesStep.getPath(),
        () => navigate(routes.newBookingServicesStep.getPath())
      );
    }
    if (
      activeStep === routes.newBookingTimeSlotStep.getPath() ||
      activeStep === routes.newBookingWheelStorageStep.getPath()
    ) {
      return true;
    }

    const timeWindowsOk = timeWindowsStepOk();
    if (!timeWindowsOk) {
      return logValidationMsgAndNavigate(
        routes.newBookingSummaryStep.getPath(),
        routes.newBookingTimeSlotStep.getPath(),
        () => navigate(routes.newBookingTimeSlotStep.getPath())
      );
    }

    return true;
  };

  return { addressStepOk, carStepOk, salesItemStepOk, wheelStorageStepOk, timeWindowsStepOk, validateStep };
};

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { noddiAsync } from 'noddi-async';
import { AuthProvider, LanguageProvider, NoddiLocalizationProvider, ToastProvider } from 'noddi-provider';
import { NbFallback, createTheme } from 'noddi-ui';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { initLocales } from '../../../packages/config';
import routes from './appRoutes';
import { PublicRoutesLayout } from './components/Layouts/PublicRouteLayout';
import { VITE_ENGLISH_LOCALE } from './constants/baseUrl';
import { BookingContext } from './contexts/BookingContext';
import BookingPlaygroundPage from './pages/BookingFlow/BookingPlaygroundPage';
import BookingInfo from './pages/BookingInfo';
import { BookingRouter } from './pages/BookingRouter';
import Coupons from './pages/Campaigns';
import Confirmation from './pages/Confirmation';
import { HomeLayout } from './pages/HomeLayout';
import { HomeRouter } from './pages/HomeRouter';
import Login from './pages/Login';
import MembershipDetails from './pages/MembershipDetail';
import PaymentInfo from './pages/PaymentInfo';
import Referrals from './pages/PublicReferrals';
import { dynamicActivateLocale } from './utils/lingui';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const theme = createTheme();

  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  const [locale, _] = useState<'en' | 'nb'>(VITE_ENGLISH_LOCALE === 'true' ? 'en' : 'nb');

  useEffect(() => {
    initLocales({ setIsLanguageLoaded, defaultLanguage: locale, dynamicActivateLocale });
  }, []);

  if (!isLanguageLoaded) {
    return null;
  }

  const NoddiServerContext = noddiAsync.NoddiServerContext;

  return (
    <Sentry.ErrorBoundary fallback={<NbFallback />}>
      <I18nProvider i18n={i18n}>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify
          }}
        >
          <NoddiServerContext>
            <AuthProvider>
              <LanguageProvider defaultLocale={locale} dynamicActivateLocale={dynamicActivateLocale}>
                <ThemeProvider theme={theme}>
                  <ToastProvider>
                    <NoddiLocalizationProvider>
                      <HelmetProvider>
                        <BookingContext>
                          <SentryRoutes>
                            <Route path='/' element={<PublicRoutesLayout />}>
                              <Route path='/bookingPlayground' element={<BookingPlaygroundPage />} />
                              <Route path={`${routes.newBooking.getBasePath()}`} element={<BookingRouter />} />
                              <Route path={routes.bookingInfo.getBasePath()} element={<BookingInfo />} />
                              <Route path={routes.campaignsInfo.getBasePath()} element={<Coupons />} />
                              <Route path={routes.referralsInfo.getBasePath()} element={<Referrals />} />
                              <Route path={routes.paymentInfo.getBasePath()} element={<PaymentInfo />} />
                              <Route path={routes.nafMembership.getBasePath()} element={<MembershipDetails />} />
                              <Route path={routes.login.getBasePath()} element={<Login />} />
                              <Route path='' element={<Navigate replace to={routes.login.getBasePath()} />} />
                            </Route>
                            <Route path='/' element={<HomeLayout />}>
                              <Route path={routes.confirmation.getBasePath()} element={<Confirmation />} />
                              <Route path={routes.home.getBasePath()} element={<HomeRouter />} />
                            </Route>
                            <Route path='*' element={<Navigate replace to={routes.login.getBasePath()} />} />
                          </SentryRoutes>
                        </BookingContext>
                      </HelmetProvider>
                    </NoddiLocalizationProvider>
                  </ToastProvider>
                </ThemeProvider>
              </LanguageProvider>
            </AuthProvider>
          </NoddiServerContext>
        </QueryParamProvider>
      </I18nProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;

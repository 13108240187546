import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { PropsWithChildren } from 'react';

import { NoddiIcon } from '../../../atoms/NoddiIcon';

const TypographyWithInfoSign = ({ children }: PropsWithChildren) => (
  <Stack direction='row' justifyContent='flex-start' alignItems='flex-start' gap={1}>
    <NoddiIcon name='Info' size={32} className='mt-4' />

    <Typography marginTop='1em'>{children}</Typography>
  </Stack>
);

export { TypographyWithInfoSign };

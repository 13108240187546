import { i18n } from '@lingui/core';
import { Typography, TypographyProps } from '@mui/material';
import { PropsWithChildren } from 'react';

import { colors } from '../../../../../tailwind-design-preset';
import { NoddiAsyncError } from './NoddiAsyncError';
import { getErrorCodes, getGenericErrorMessages } from './errorCodes';

interface ErrorMessageProps extends TypographyProps {
  error: NoddiAsyncError | Array<NoddiAsyncError | null>;
}

export const mapErrorCodeToUiMessage = ({ error }: { error: NoddiAsyncError }) => {
  const genericErrorMessageIdentifiers = getGenericErrorMessages();
  const errorCodes = getErrorCodes();

  const genericErrorMessage = genericErrorMessageIdentifiers.genericErrorMessage;
  const errors = error.response?.data.errors ?? [];

  if (!errors) {
    return genericErrorMessage;
  }
  if (errors.length === 1) {
    const code = errors[0]?.code;

    return errorCodes[code as keyof typeof errorCodes] ?? genericErrorMessage;
  } else if (errors.length > 1) {
    //writes reducer that iterates through errors and returns a string
    const message = errors.reduce((prev, curr, index) => {
      const code = curr?.code ?? '';

      const errorCode = errorCodes[code as keyof typeof errorCodes] ?? genericErrorMessage;
      if (index === errors.length - 1) {
        prev += errorCode;
      } else {
        if (errorCode) {
          prev += `${errorCode} ${i18n._('and')} `;
        }
      }

      return prev;
    }, '');
    return message;
  }
  return genericErrorMessage;
};

export function ApiErrorMessage({ error, ...typographyProps }: ErrorMessageProps) {
  const errorToRender = Array.isArray(error) ? error.find((e) => e !== null) : error;

  if (!errorToRender) {
    return null;
  }

  return (
    <Typography style={{ color: colors.signal.danger }} {...typographyProps}>
      {mapErrorCodeToUiMessage({ error: errorToRender })}
    </Typography>
  );
}

export function ErrorMessage({ children, ...typographyProps }: PropsWithChildren<TypographyProps>) {
  return (
    <Typography style={{ color: colors.signal.danger }} {...typographyProps}>
      {children}
    </Typography>
  );
}

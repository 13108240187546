import { Trans } from '@lingui/macro';
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  ListItem,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import { ServerTypes } from 'noddi-async';
import { NoddiButton, QuestionMark, colors } from 'noddi-ui';
import { formatNorwegianCurrencyAmount } from 'noddi-util';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useBookingContext } from '../contexts/BookingContext';
import { useSalesItems } from '../hooks/useSalesItems';
import useSelectSalesItem from '../hooks/useSelectSalesItem';
import { SelectedCar, SelectedSalesItemQueryInput } from '../pages/BookingFlow/interfaces';
import { useStickyServiceSummaryStore } from '../stores/StickyServiceSummaryStore';

type Props = {
  car: SelectedCar;
  salesItem: ServerTypes.AvailableSalesItem;
  queryParams: SelectedSalesItemQueryInput;
  isAddon: boolean;
};

const AddSalesItemButton = ({ car, salesItem, queryParams, isAddon }: Props) => {
  const { selectSalesItem, isSelected } = useSelectSalesItem();

  const isAlreadySelected = isSelected({ licensePlateNumber: car.licensePlateNumber, salesItem });

  return (
    <Button
      variant={isAlreadySelected ? 'outlined' : 'contained'}
      onClick={async () => {
        await selectSalesItem({ licensePlateNumber: car.licensePlateNumber, salesItem, queryParams, isAddon });
      }}
      style={
        isAlreadySelected
          ? {
              color: colors.primary.black,
              borderColor: colors.primary.black,
              minWidth: 90
            }
          : { backgroundColor: colors.primary.orange }
      }
    >
      {isAlreadySelected ? <Trans>Remove</Trans> : <Trans>Add</Trans>}
    </Button>
  );
};

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  car: SelectedCar;
  salesItem: ServerTypes.AvailableSalesItem;
}

const SimpleDialog = ({ open, onClose, salesItem }: SimpleDialogProps) => {
  const { setShowSummary } = useStickyServiceSummaryStore();
  const { incompatibleServiceCategories } = useBookingContext();
  const { getAllAvailableSalesItemsOrUndefined } = useSalesItems();

  // find all names of incompatible service categories for this sales item
  const incompatibleServiceCategoryIds = incompatibleServiceCategories.flatMap(
    (category) => category.incompatibleCategoriesIds
  );

  // find all sales items with the incompatible service categories
  const incompatibleSalesItemNames =
    getAllAvailableSalesItemsOrUndefined()
      ?.filter((item) => incompatibleServiceCategoryIds.includes(item.serviceCategory.id))
      .map((item) => item.name) ?? [];

  const handleClose = () => {
    setShowSummary(true);
    onClose();
  };

  const DisplayList = ({ incompatibles }: { incompatibles: string[] }) => {
    return (
      <ul>
        {incompatibles.map((item: string) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    );
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth='xs'>
      <DialogContent>
        <div style={{ padding: '1em' }}>
          <Typography variant='body1'>
            <Trans>
              Unfortunately, we currently have no workers in your area who can perform <b>{salesItem.name}</b> in
              combination with
            </Trans>
          </Typography>
          {incompatibleSalesItemNames.length > 0 ? (
            <DisplayList incompatibles={Array.from(new Set(incompatibleSalesItemNames))} />
          ) : (
            <>
              *<Trans>Failed to load category names</Trans>
            </>
          )}
          <Typography variant='body1'>
            <Trans>
              If you want this combination, you can try to make two different orders. Note that if this is possible, we
              cannot guarantee that the services will be performed at the same time.
            </Trans>
          </Typography>
          <Typography variant='body1'>
            <Trans>
              Do not hesitate to <Link to='https://www.noddi.no/hjelp-og-kontakt'> get in touch</Link> if you have any
              questions{' '}
            </Trans>
          </Typography>
        </div>

        <DialogActions>
          <NoddiButton onClick={handleClose} style={{ marginTop: '24px' }} fullWidth>
            OK
          </NoddiButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const SalesItemDescription = ({
  salesItem,
  isSmallScreen
}: {
  salesItem: ServerTypes.AvailableSalesItem;
  isSmallScreen: boolean;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
      }}
    >
      <Tooltip
        title={
          <Typography sx={{ whiteSpace: 'pre-line' }} variant='subtitle2'>
            {salesItem.description}
          </Typography>
        }
        enterTouchDelay={0}
        leaveTouchDelay={20000}
        placement='top'
        sx={{
          paddingLeft: 0,
          paddingBottom: 0,
          paddingTop: 0,
          paddingRight: 0,
          marginRight: 1,
          color: colors.primary.orange
        }}
        componentsProps={{
          tooltip: {
            sx: {
              color: colors.primary.black,
              backgroundColor: colors.primary.white,
              border: `1px solid ${colors.systemColors.grey}`
            }
          }
        }}
      >
        <IconButton>
          <QuestionMark />
        </IconButton>
      </Tooltip>
      <Typography sx={{ maxWidth: isSmallScreen ? '170px' : '200px' }} component='div' variant='body1'>
        {salesItem.name}
      </Typography>
    </div>
  );
};

const SalesItemListItem = ({ car, salesItem, queryParams, isAddon = false }: Props) => {
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const { setShowSummary } = useStickyServiceSummaryStore();
  const isSmallScreen = useMediaQuery('(max-width:400px)');
  const { incompatibleServiceCategories } = useBookingContext();

  const isCurrentlyIncompatible = incompatibleServiceCategories.find(
    (category) => category.serviceCategoryId === salesItem.serviceCategory.id
  );

  return (
    <>
      {isCurrentlyIncompatible ? (
        <>
          <ListItemText
            primary={<SalesItemDescription salesItem={salesItem} isSmallScreen={isSmallScreen} />}
            secondary={formatNorwegianCurrencyAmount(salesItem.price, 0)}
            sx={{ marginBottom: '0' }}
          />
          <div style={{ marginBottom: '10px' }}>
            <small
              onClick={() => {
                setShowSummary(false);
                setDialogIsOpen(true);
              }}
              style={{
                color: '#6366F1',
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
            >
              <Trans>Why can't I choose this service?</Trans>
            </small>

            <SimpleDialog open={dialogIsOpen} onClose={() => setDialogIsOpen(false)} car={car} salesItem={salesItem} />
          </div>
        </>
      ) : (
        <ListItem
          key={salesItem.id}
          secondaryAction={
            <AddSalesItemButton car={car} salesItem={salesItem} queryParams={queryParams} isAddon={isAddon} />
          }
          disablePadding={true}
        >
          <ListItemText
            primary={<SalesItemDescription salesItem={salesItem} isSmallScreen={isSmallScreen} />}
            secondary={formatNorwegianCurrencyAmount(salesItem.price, 0)}
            sx={{ marginBottom: '0' }}
          />
        </ListItem>
      )}

      <hr></hr>
    </>
  );
};

export default SalesItemListItem;

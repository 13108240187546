import { SVGProps } from 'react';

interface AltArrowUpProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const AltArrowUp = ({ color = '#1F1F1F', ...props }: AltArrowUpProps) => (
  <svg width={32} height={32} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M23.4667 19.2L16 12.8L8.53333 19.2'
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { AltArrowUp };

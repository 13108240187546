import { Trans } from '@lingui/macro';
import { Stack } from '@mui/system';
import { useState } from 'react';
import styled from 'styled-components';

import { NoddiIcon } from '../../../../../../atoms/NoddiIcon';
import { NoddiDrawer } from '../../../../../Elements';
import RouteDrawer from '../../Drawer/RouteDrawer';
import { CardCommonCss } from './commonCss';
import { RouteAndRouteItemId } from './types';

const CancelledCard = styled.button`
  ${CardCommonCss};
  background-color: #fbdbdb;
  max-width: 120px;
`;

type CancelledCardProps = RouteAndRouteItemId & {
  cancelledAt: string;
};
const CancelCard = ({ cancelledAt, routeId, routeItemId }: CancelledCardProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      <CancelledCard
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <Stack direction='column' height='100%' justifyContent='center'>
          <div>
            <Trans>Cancelled</Trans>
            <Stack direction='row' justifyContent='center' alignItems='center' gap={0.5}>
              <NoddiIcon name='CloseRounded' />
              <span>{cancelledAt}</span>
            </Stack>
          </div>
        </Stack>
      </CancelledCard>
      <NoddiDrawer
        isOpen={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <RouteDrawer routeItemId={routeItemId} routeId={routeId} />
      </NoddiDrawer>
    </>
  );
};

export default CancelCard;

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactNode, Ref, useRef } from 'react';

import { colors } from '../../../../../tailwind-design-preset';
import { ExpandingArrow } from '../../Icons';
import { UnstyledLink } from './UnstyledLink';

type ArrowLinkProps = {
  to: string;
  text: ReactNode;
  color?: string;
};

const ArrowLink = ({ to, text, color = colors.primary.orange }: ArrowLinkProps) => {
  const arrowRef = useRef<HTMLAnchorElement>(null);
  return (
    <UnstyledLink to={to} ref={arrowRef as Ref<HTMLAnchorElement>}>
      <Stack direction='row' alignItems='center' gap={1}>
        <Typography variant='body2' color={color}>
          {text}
        </Typography>
        <ExpandingArrow parentRef={arrowRef} />
      </Stack>
    </UnstyledLink>
  );
};

export { ArrowLink };

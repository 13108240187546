import { MutableRefObject } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms/NoddiIcon';

const SliderArrowsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

type SliderArrowProps = {
  slider: MutableRefObject<Slider | null>;
};

type ForwardArrowProps = {
  isLoading: boolean;
  disableNextSlide: boolean;
};

type SliderArrowsProps = SliderArrowProps & ForwardArrowProps;

const getBackArrow = ({ slider }: SliderArrowProps) => {
  if (!slider) {
    return null;
  }

  return (
    <NoddiIcon name='AltArrowLeft' size={32} onClick={() => slider?.current?.slickPrev()} className='cursor-pointer' />
  );
};

const getForwardArrow = ({ slider, isLoading, disableNextSlide }: SliderArrowProps & ForwardArrowProps) => {
  if (!slider) {
    return null;
  }
  if (isLoading || disableNextSlide) {
    return <NoddiIcon name='AltArrowRight' size={32} className='cursor-not-allowed' color={colors.systemColors.grey} />;
  }

  return (
    <NoddiIcon name='AltArrowRight' size={32} onClick={() => slider.current?.slickNext()} className='cursor-pointer' />
  );
};

export const SliderArrows = ({ slider, ...rest }: SliderArrowsProps) => {
  if (!slider) {
    return null;
  }

  return (
    <SliderArrowsContainer>
      {getBackArrow({ slider })}
      {getForwardArrow({ slider, ...rest })}
    </SliderArrowsContainer>
  );
};

import { Trans, t } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { useEffect, useRef } from 'react';

import { ApiErrorMessage, NoddiButton, NoddiFormTextInput, SectionSpacer } from '../../Elements';
import userRegistrationFormSchema from './validators';

interface IFormInput {
  firstName: string;
  surname: string;
  email: string;
  password?: string;
  confirmPassword?: string;
}

type Props = {
  phoneNumber: string;
  skipPasswordCreation?: boolean;
};

const UserRegistrationForm = ({ phoneNumber, skipPasswordCreation }: Props) => {
  const { loginUser } = useAuthContext();
  const commonInitialValues = {
    firstName: '',
    surname: '',
    email: ''
  };

  const initialValues = skipPasswordCreation
    ? commonInitialValues
    : { ...commonInitialValues, password: '', confirmPassword: '' };

  const onSubmit = async (data: IFormInput) => {
    await createUser({
      firstName: data.firstName,
      lastName: data.surname,
      phoneNumber: phoneNumber,
      email: data.email,
      password: data.password,
      confirmPassword: data.confirmPassword
    });
  };

  const {
    mutateAsync: createUser,
    isPending: isCreateUserPending,
    isError,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postUser,
    queryConfig: {
      onSuccess: (data) => loginUser(data.data) // this is actually a user sign-in
    }
  });

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, [ref]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={userRegistrationFormSchema(skipPasswordCreation)}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <Typography variant='h4'>
              <Trans>User information</Trans>
            </Typography>
            <SectionSpacer fullWidth variant='small'>
              <NoddiFormTextInput ref={ref} name='firstName' label={t`First name`} fullWidth />
            </SectionSpacer>
            <SectionSpacer fullWidth variant='small'>
              <NoddiFormTextInput name='surname' label={t`Surname`} />
            </SectionSpacer>
            <SectionSpacer fullWidth variant='small'>
              <NoddiFormTextInput name='email' label={t`E-mail address`} placeholder={t`test@example.com`} />
            </SectionSpacer>
            {skipPasswordCreation ? null : (
              <>
                <SectionSpacer fullWidth variant='small'>
                  <NoddiFormTextInput autoComplete='new-password' name='password' label={t`Password`} type='password' />
                </SectionSpacer>
                <SectionSpacer fullWidth variant='small'>
                  <NoddiFormTextInput
                    autoComplete='new-password'
                    name='confirmPassword'
                    label={t`Confirm password`}
                    type='password'
                  />
                </SectionSpacer>
              </>
            )}
            {isError && <ApiErrorMessage error={error} />}
            <NoddiButton
              type='submit'
              style={{ marginTop: '24px' }}
              fullWidth
              disabled={!isValid}
              loading={isCreateUserPending || isSubmitting}
            >
              <Trans>Create user</Trans>
            </NoddiButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export { UserRegistrationForm };

/** @type {import('tailwindcss').Config} */

const primary = {
  darkPurple: '#35155A',
  darkPurple90: '#492C6B',
  darkPurple80: '#5D447B',
  darkPurple70: '#725B8B',
  darkPurple60: '#86739C',
  darkPurple50: '#9A8AAC',
  darkPurple40: '#D7D0DE',
  darkPurple30: '#EBE8EE',

  purple: '#8661C1',
  purple90: '#6D48A7',
  puple80: '#532E8E',
  purple70: '#3A1575',
  purple60: '#20005B',
  purple50: '#070042',
  purple40: '#000028',
  purple30: '#00000F',

  pink: '#FFC7ED',
  pink90: '#FFCDEF',
  pink80: '#FFD2F1',
  pink70: '#FFD8F2',
  pink60: '#FFDDF4',
  pink50: '#FFE3F6',
  pink40: '#FFE9F8',
  pink30: '#FFEEFA',

  orange: '#FF4521',

  white: '#FFFFFF',
  black: '#000000'
};

const secondary = {
  black: '#1F1F1F',
  white: '#F7F6FA',
  silverGray: '#DADDE0',

  rapsberry: '#C738BD',
  rapsberry90: '#CD4CC4',
  rapsberry80: '#D260CA',
  rapsberry70: '#D874D1',
  rapsberry60: '#DD88D7',
  rapsberry50: '#E39BDE',
  rapsberry40: '#E9AFE5',
  rapsberry30: '#EEC3EB',

  coral: '#FFEAE5',
  coral90: '#FFECE8',
  coral80: '#FFEEEA',
  coral70: '#FFF0ED',
  coral60: '#FFF2EF',
  coral50: '#FFF5F2',
  coral40: '#FFF7F5',
  coral30: '#FFF9F7',

  orange: '#EE634B',
  orange50: '#BB3018'
};

const systemColors = {
  grey: '#7C7B7D',
  snow: '#FFFAFA',

  purpleBg: '#F3EFF9',
  darkPurpleBg: '#F7F7F7',
  rapsberryBg: '#F9EBF8',
  pinkBg: '#FFF4FB',

  outlineStroke: '#EDEEEF',
  transparent: 'transparent'
};

const text = {
  primary: primary.black,
  primaryInverted: '#1F1F1FB2',

  secondary: primary.white,
  seocondaryInverted: '#FFFFFFB2'
};

const signal = {
  success: '#128667',
  success50: '#A0CFC2',

  warning: '#D78E01',
  warning50: '#FFFADE',

  danger: '#EA5147',
  danger50: '#FFF3F2'
};

export const colors = {
  primary,
  secondary,
  systemColors,
  text,
  signal
} as const;

// type that gets all corors from the colors object
export type Colors = typeof colors;

export default {
  theme: {
    colors,
    spacing: {
      0: '0px',
      1: '4px',
      2: '8px',
      2.5: '10px',
      3: '12px',
      4: '16px',
      5: '20px',
      6: '24px',
      8: '32px',
      9: '36x',
      10: '40px',
      12: '48px',
      25: '100px',
      32: '128px'
    },
    fontFamily: {
      regular: ['"GT America Standard Regular"', 'sans-serif'],
      bold: ['"GT America Standard Bold"', 'sans-serif'],
      condensed: ['"GT America Condensed Black"', 'sans-serif']
    },
    screens: {
      xs: '475px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px'
    },
    animation: {
      'spin-slow': 'spin 2s linear infinite'
    },
    borderWidth: {
      '0': '0px',
      '1': '4px',
      '2': '8px'
    },
    boxShadow: {
      cardShadow: '0px 1.92px 15.03px 0px rgba(0, 0, 0, 0.25)'
    }
  }
};

import { ErrorPage, LoadingScreen, NoddiAsyncError } from 'noddi-ui';
import { PropsWithChildren } from 'react';

import AlreadyHasTireHotel from './AlreadyHasTireHotel';

type HasTireHotelProps = {
  shouldCheck: boolean;
  isTireHotelOptionsPending: boolean;
  error: NoddiAsyncError | null;
  regNumbersWithTireHotel: string[];
};

const HasTireHotel = ({
  children,
  shouldCheck,
  isTireHotelOptionsPending,
  error,
  regNumbersWithTireHotel
}: PropsWithChildren<HasTireHotelProps>) => {
  if (!shouldCheck) {
    return <>{children}</>;
  }

  if (isTireHotelOptionsPending) {
    return <LoadingScreen minHeight='200px' />;
  }
  if (error) {
    return <ErrorPage apiError={error} />;
  }

  if (regNumbersWithTireHotel.length > 0) {
    return <AlreadyHasTireHotel licensePlate={regNumbersWithTireHotel.join(',')} />;
  }

  return <>{children}</>;
};

export default HasTireHotel;

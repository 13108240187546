import { Grid } from '@mui/material';

import { colors } from '../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../atoms/NoddiIcon';
import { getServiceCategoryTranslations } from './utils';

export const UnavailableServiceCategory = ({
  serviceCategoryName
}: {
  serviceCategoryName: string;
}): React.ReactElement => {
  const data = getServiceCategoryTranslations({ serviceCategoryName: serviceCategoryName });
  return (
    <Grid item xs={12} md={4}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          marginTop: '0.5em'
        }}
      >
        <NoddiIcon name='Cross' color={colors.systemColors.grey} />
        <span style={{ marginLeft: '7px' }}>{data.name}</span>
      </div>
    </Grid>
  );
};

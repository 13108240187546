import { t } from '@lingui/macro';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { UseQueryResult } from '@tanstack/react-query';
import { ServerTypes, URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { NoddiCollapseCard, NoddiIcon, colors, getLongAddressName } from 'noddi-ui';
import { formatNorwegianCurrencyAmount } from 'noddi-util';
import { useLocation } from 'react-router-dom';

import routes from '../../appRoutes';
import { useBookingContext } from '../../contexts/BookingContext';
import bookingTimeWindowToDisplayText from '../../helper/timeWindow';
import useBookingSummaryProps from '../../hooks/useBookingSummaryProps';
import BookingSummary from '../BookingFlow/Summary';

const SummaryHeader = ({ totalPrice }: { totalPrice: number }) => {
  const { bookingInputData } = useBookingContext();

  const timeWindowDisplay =
    (bookingInputData.time && bookingTimeWindowToDisplayText(bookingInputData.time)) ||
    'Problemer med å laste tidsluke';

  return (
    <Stack alignItems='start' direction='column' sx={{ cursor: 'pointer', width: '100%' }}>
      <Stack alignItems='start' direction='row' spacing={1}>
        <NoddiIcon name='Calendar' size={24} />
        <Typography color='text.secondary' variant='body2'>
          {timeWindowDisplay}
        </Typography>
      </Stack>
      <Stack alignItems='center' direction='row' spacing={1}>
        <NoddiIcon name='LocationPin' size={24} />
        <Typography color='text.secondary' variant='body2'>
          {bookingInputData?.address ? getLongAddressName(bookingInputData.address) : undefined}
        </Typography>
      </Stack>
      <Stack alignItems='center' direction='row' spacing={1}>
        <NoddiIcon name='Bill' size={24} />
        <Typography color='text.secondary' variant='body2'>
          {formatNorwegianCurrencyAmount(totalPrice)}
        </Typography>
      </Stack>
    </Stack>
  );
};

const ServiceHeader = ({
  selectedSalesItems,
  totalPrice
}: {
  selectedSalesItems: UseQueryResult<ServerTypes.AvailableSalesItem[]>[];
  totalPrice?: number;
}) => {
  const isPluralSelected = selectedSalesItems.length > 1;
  const isNoneSelected = selectedSalesItems.length === 0;

  const getPriceText = () => {
    if (isNoneSelected) {
      return t`No services selected`;
    } else if (!isPluralSelected) {
      return `1 ${t`selected service`} (${formatNorwegianCurrencyAmount(totalPrice, 0)})`;
    }
    return `${selectedSalesItems.length} ${t`selected services`} (${formatNorwegianCurrencyAmount(totalPrice, 0)})`;
  };

  const priceText = getPriceText();

  return (
    <Stack alignItems='start' direction='column' spacing={1} sx={{ cursor: 'pointer', width: '100%' }}>
      <Stack alignItems='center' direction='row' spacing={1}>
        <NoddiIcon name='ShoppingCart' />
        <Typography color='text.secondary' variant='body2'>
          {priceText}
        </Typography>
      </Stack>
    </Stack>
  );
};

const StickyServiceSummary = () => {
  const { salesItems } = useBookingContext();

  const { currentUserGroupId } = useAuthContext();
  const { pathname } = useLocation();

  const { data: membershipData } = noddiAsync.useGet({
    type: URLKeys.getMembershipPrograms,
    input: { userGroupId: currentUserGroupId as number },
    queryConfig: {
      enabled: !!currentUserGroupId
    }
  });

  const salesItemsResults = noddiAsync.useGetAll(
    salesItems.map((salesItem) => ({
      type: salesItem.urlKey,
      input: salesItem.queryInput
    }))
  );

  const isOnSummaryStep = pathname === routes.newBookingSummaryStep.getBasePath();

  const { totalPrice } = useBookingSummaryProps({ membershipData });

  return (
    <NoddiCollapseCard
      sx={isOnSummaryStep ? undefined : { width: '100%' }}
      borderColor={colors.primary.orange}
      header={
        isOnSummaryStep ? (
          <SummaryHeader totalPrice={totalPrice} />
        ) : (
          <ServiceHeader selectedSalesItems={salesItemsResults} totalPrice={totalPrice} />
        )
      }
      collapseBody={<BookingSummary membershipData={membershipData} />}
    />
  );
};

export default StickyServiceSummary;

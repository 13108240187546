import { t } from '@lingui/macro';
import { Stack, SxProps } from '@mui/material';
import html2canvas from 'html2canvas';
import { ReactNode } from 'react';
import { QRCodeProps } from 'react-qr-code';
import styled from 'styled-components';

import { NoddiButton } from '../Buttons';
import { BaseQRCodeDisplay } from './BaseQRCodeDisplay';

export type BaseProps = QRCodeProps & {
  qrCodeSx?: SxProps;
  content?: ReactNode;
};

// If download is true, id is required.
type WithDownloadProps = BaseProps & {
  download: true;
  id: string;
};

// If download is false, id is optional.
type WithoutDownloadProps = BaseProps & {
  download?: false;
  id?: string;
};

// Union type that enforces the presence of `id` when `download` is true.
export type QRCodeDisplayProps = WithDownloadProps | WithoutDownloadProps;

const FlexDiv = styled.div`
  display: flex;
  width: max-content;
`;

const DownloadButton = ({ id, text }: { id: string; text?: string }) => (
  <NoddiButton
    variant='primary'
    style={{ marginBottom: '24px' }}
    onClick={async () => {
      const element = document.getElementById(id);
      if (!element) {
        return;
      }

      const canvas = await html2canvas(element, {
        backgroundColor: null,
        scale: 2
      });

      const url = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.href = url;
      a.download = text ? `${text}.png` : 'qr-code.png';
      a.click();
    }}
  >
    {t`Download as PNG`}
  </NoddiButton>
);

export const QRCodeColumnDisplay = ({
  id,
  value,
  size = 256,
  bgColor = 'white',
  fgColor = 'black',
  level = 'L',
  download = false,
  qrCodeSx,
  content
}: QRCodeDisplayProps) => {
  const Content = (
    <FlexDiv id={id}>
      <Stack sx={{ ...qrCodeSx }}>
        <BaseQRCodeDisplay value={value} size={size} bgColor={bgColor} fgColor={fgColor} level={level} />
      </Stack>
      {content}
    </FlexDiv>
  );

  return download && id ? (
    <>
      <DownloadButton id={id} />
      {Content}
    </>
  ) : (
    Content
  );
};

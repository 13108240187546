import { Card, CardContent, Stack } from '@mui/material';
import { RouteStatusForAdmin } from 'noddi-async/src/types';
import styled from 'styled-components';

import { useIsMobile } from '../../../../hooks';
import { RouteItemTypes, SolutionAndProblemIds, UniqueCapacityContributionsWithRouteItems } from '../types';
import { RouteHeader } from './RouteHeader';
import { RouteItemCard } from './RouteItem/RouteItemCard';

type RouteSwimLaneProps = {
  routeItemsWithContribution: UniqueCapacityContributionsWithRouteItems;
  isSelectedSolution: boolean;
  solutionAndProblemIds: SolutionAndProblemIds;
  route: RouteStatusForAdmin;
};

const getRouteStartsAt = (routeItemsWithContribution: RouteStatusForAdmin) => {
  if (routeItemsWithContribution.routeItems.length === 0) {
    return;
  }

  const first = routeItemsWithContribution.routeItems[0];
  if (!first) {
    return;
  }

  if (first.type !== RouteItemTypes.Depot) {
    return;
  }

  return first.estimatedServiceStart;
};

const RouteSwimLane = ({
  routeItemsWithContribution,
  isSelectedSolution,
  solutionAndProblemIds,
  route
}: RouteSwimLaneProps) => {
  const { id: routeId, delayInSeconds, completedAt, startedAt } = route;
  const { isValid } = route.summary;
  const delayInMinutes = Math.round(delayInSeconds / 60) / 1;
  const isMobile = useIsMobile();

  return (
    <>
      <Card sx={{ boxShadow: isMobile ? 'none !important' : 'inherit' }}>
        <CardContent sx={{ padding: 3 }}>
          <RouteHeader
            completedAt={completedAt}
            delayInMinutes={delayInMinutes}
            solutionAndProblemIds={solutionAndProblemIds}
            routeId={routeId}
            isSelectedSolution={isSelectedSolution}
            isValid={isValid}
            startedAt={startedAt}
            routeStartAt={getRouteStartsAt(route)}
          />
          <StyledStack direction='row' gap={1} sx={{ overflow: 'auto' }}>
            {routeItemsWithContribution.routeItems.map((routeItem) => {
              if (routeItem.type === RouteItemTypes.Depot) {
                return;
              }
              return (
                <RouteItemCard
                  key={routeItem.id}
                  routeItem={routeItem}
                  isSelectedSolution={isSelectedSolution}
                  delayInMinutes={delayInMinutes}
                  routeId={routeId}
                />
              );
            })}
          </StyledStack>
        </CardContent>
      </Card>
    </>
  );
};

export { RouteSwimLane };

const StyledStack = styled(Stack)`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

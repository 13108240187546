import { Button, ButtonProps } from '@mui/material';
import { PropsWithChildren } from 'react';

import { NoddiIcon } from '../../../../atoms/NoddiIcon';

type FilterButtonProps = {
  anchorRef: React.MutableRefObject<HTMLButtonElement | null>;
  handleClose: () => void;
  handleOpen: () => void;
  open: boolean;
  disabled?: boolean;
} & ButtonProps;
const FilterButton = ({
  anchorRef,
  handleClose,
  handleOpen,
  open,
  disabled = false,
  children,
  ...buttonProps
}: PropsWithChildren<FilterButtonProps>) => {
  return (
    <Button
      color='inherit'
      endIcon={<NoddiIcon name='AltArrowDown' size={24} />}
      onClick={handleOpen}
      ref={anchorRef}
      sx={{ border: '1px solid', backgroundColor: disabled ? 'background.disabled' : 'background.paper' }}
      disabled={disabled}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default FilterButton;

import { Typography, TypographyProps } from '@mui/material';
import { Stack } from '@mui/system';
import { PropsWithChildren } from 'react';

type TypographyWithInfoIconProps = {
  icon: React.ReactNode;
} & TypographyProps;
const TypographyWithCustomIcon = ({
  icon,
  children,
  ...typographyProps
}: PropsWithChildren<TypographyWithInfoIconProps>) => (
  <Stack direction='row' justifyContent='flex-start' alignItems='center' gap={1}>
    {icon}

    <Typography {...typographyProps}>{children}</Typography>
  </Stack>
);

export { TypographyWithCustomIcon };

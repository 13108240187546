import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
// eslint-disable-next-line
import { groupBy } from 'lodash';
import { ServerTypes, URLKeys } from 'noddi-async';
import { AvailableSalesItem } from 'noddi-async/src/types';
import { CloseCrossButton, NoddiIcon, TypographyWithCustomIcon, colors, useGetScreenDimensions } from 'noddi-ui';
import { Fragment, useState } from 'react';
import styled from 'styled-components';

import { GridMinWidthItems } from '../../../../components/Layouts/GridMinWidthItems';
import SalesItemListItem from '../../../../components/SalesItemListItem';
import { useBookingContext } from '../../../../contexts/BookingContext';
import { SelectedCar, SelectedSalesItem } from '../../interfaces';
import SalesItemCategoryButton from './SalesItemCategoryButton';

type RowIndex = {
  rowIndex: number;
};

const SaleItemDetailCardPadding = styled.div`
  @media (min-width: 500px) {
    padding: 3rem;
  }
  position: relative;
  padding: 2.5rem 1rem 1rem 1rem;
  border-radius: 1rem;
`;

const SaleItemDetailCard = styled.div<RowIndex>`
  background-color: white;
  border-radius: 1rem;
  outline: 1px solid ${colors.primary.orange};
  grid-column: 1 / -1;
  grid-row-start: ${(props) => props.rowIndex};
`;

const getCategoryCount = ({
  selectedSalesItems,
  category,
  salesItems,
  licensePlateNumber
}: {
  selectedSalesItems: SelectedSalesItem[];
  category: string;
  licensePlateNumber: string;
  salesItems: ServerTypes.AvailableSalesItem[];
}) => {
  const salesItemsInCategory = salesItems.filter((item) => item.uiCategory === category);
  return salesItemsInCategory.filter((item) =>
    selectedSalesItems.find(
      (selectedItem) => selectedItem.licensePlateNumber === licensePlateNumber && selectedItem.id === item.id
    )
  ).length;
};

type Props = {
  car: SelectedCar;
  availableSalesItems: AvailableSalesItem[];
};

const SalesItemCar = ({ car, availableSalesItems }: Props) => {
  const [expandedCategory, setExpandedCategory] = useState<string>('');
  const { bookingInputData, salesItems: selectedSalesItems } = useBookingContext();

  const { screenSize } = useGetScreenDimensions();

  // this is where the grid wraps and the layout changes
  const blocksPerGrid = screenSize.width < 582 ? 2 : 3;

  const uiCategories = groupBy(availableSalesItems, 'uiCategory');

  return (
    <>
      <TypographyWithCustomIcon variant='h6' icon={<NoddiIcon name='Car' size={24} />}>
        {car.carName} - {car.licensePlateNumber}
      </TypographyWithCustomIcon>

      {Object.keys(uiCategories)?.length > 0 ? (
        <GridMinWidthItems>
          {Object.entries(uiCategories).map(([category, salesItems], index) => {
            const gridRowStart = Math.ceil((index + 1) / blocksPerGrid) + 1;

            return (
              <Fragment key={category}>
                <SalesItemCategoryButton
                  category={category}
                  expandedCategory={expandedCategory}
                  setExpandedCategory={setExpandedCategory}
                  salesItems={salesItems}
                  selectedItemsCount={getCategoryCount({
                    selectedSalesItems,
                    category,
                    salesItems,
                    licensePlateNumber: car.licensePlateNumber
                  })}
                />
                {expandedCategory === category && (
                  <SaleItemDetailCard rowIndex={gridRowStart}>
                    <SaleItemDetailCardPadding>
                      <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <CloseCrossButton onClick={() => setExpandedCategory('')} />
                      </div>
                      <List>
                        {salesItems.map((salesItem, idx) => {
                          return (
                            <SalesItemListItem
                              key={idx}
                              car={car}
                              salesItem={salesItem}
                              isAddon={false}
                              queryParams={{
                                queryInput: {
                                  carSizeId: car.carSizeId,
                                  serviceAreaIds: bookingInputData.serviceAreas.flatMap((value) => value.id)
                                },
                                urlKey: URLKeys.getSalesItemsForBooking
                              }}
                            />
                          );
                        })}
                      </List>
                    </SaleItemDetailCardPadding>
                  </SaleItemDetailCard>
                )}
              </Fragment>
            );
          })}
        </GridMinWidthItems>
      ) : (
        <Typography marginTop={2}>Vi har dessverre ingen tjenester for denne biltypen.</Typography>
      )}
    </>
  );
};

export default SalesItemCar;

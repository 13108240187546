import { URLKeys } from 'noddi-async';
import { AvailableSalesItem, SalesItemSlugs } from 'noddi-async/src/types';

import { useBookingContext } from '../contexts/BookingContext';
import useUpdateSalesItemIncompatibles from '../pages/BookingFlow/hooks/useUpdateCompatibleServiceAreas';
import { SelectedSalesItem, SelectedSalesItemQueryInput } from '../pages/BookingFlow/interfaces';
import { useWheelStore } from '../stores/WheelStore';
import useActivateCoupon from './useActivateCoupon';

const TIRE_STORAGE_SALES_ITEM_SLUGS = [
  SalesItemSlugs,
  SalesItemSlugs['nb-tire-storage-included-wheel-change-lg']
] as string[];

const TIRE_STORAGE_TYPES_SALES_ITEM_SLUGS = [
  SalesItemSlugs['nb-at-car-dealer'],
  SalesItemSlugs['nb-at-your-place-free'],
  SalesItemSlugs['nb-at-another-tire-hotel']
] as string[];

const isWheelStorageConnected = (
  { slug: salesItemSlug }: Pick<AvailableSalesItem, 'slug'> | Pick<SelectedSalesItem, 'slug'>,
  { slug: selectedSalesItemSlug }: Pick<SelectedSalesItem, 'slug'>
) =>
  salesItemSlug &&
  TIRE_STORAGE_SALES_ITEM_SLUGS.includes(salesItemSlug) &&
  TIRE_STORAGE_TYPES_SALES_ITEM_SLUGS.includes(selectedSalesItemSlug ?? '');

type SalesItemAndCar = {
  licensePlateNumber: string;
  salesItem: AvailableSalesItem;
  isAddon: boolean;
  queryParams: SelectedSalesItemQueryInput;
};

const useSelectSalesItem = () => {
  const { salesItems, setSalesItems } = useBookingContext();
  const { activateCouponForSalesItem, deselectCouponsForSalesItem } = useActivateCoupon();
  const { updateSalesItemIncompatibles } = useUpdateSalesItemIncompatibles();
  const { removeSelectedWheelStorageOptions } = useWheelStore();

  const isSelected = ({ licensePlateNumber, salesItem }: Pick<SalesItemAndCar, 'licensePlateNumber' | 'salesItem'>) => {
    return salesItems.some((item) => item.licensePlateNumber === licensePlateNumber && item.id === salesItem.id);
  };

  const addSalesItem = ({ licensePlateNumber, salesItem, queryParams, isAddon }: SalesItemAndCar) => {
    // If the selected salesItem has incompatibles which are selected, unselect them.
    const selectedIncompatiblesItems = salesItems.filter(
      ({ id, licensePlateNumber: salesItemLicensePlateNumber }) =>
        salesItem.incompatibleIds.includes(id) && salesItemLicensePlateNumber === licensePlateNumber
    );

    const selectedIncompatiblesIds = selectedIncompatiblesItems.map(({ id }) => id);

    const additionalIncompatibles = salesItems.filter(
      (persistedItem) =>
        (persistedItem.licensePlateNumber === licensePlateNumber &&
          selectedIncompatiblesItems.some((item) => isWheelStorageConnected(item, persistedItem))) ||
        selectedIncompatiblesItems.some(({ id }) => salesItem.addonIds.includes(id))
    );

    if (additionalIncompatibles.length > 0) {
      removeSelectedWheelStorageOptions(licensePlateNumber);
    }

    selectedIncompatiblesIds.push(...additionalIncompatibles.map(({ id }) => id));

    setSalesItems((prev) => {
      const newState = [
        ...prev.filter(
          ({ id, licensePlateNumber: salesItemLicensePlateNumber }) =>
            !(selectedIncompatiblesIds.includes(id) && salesItemLicensePlateNumber === licensePlateNumber)
        ),
        {
          licensePlateNumber,
          id: salesItem.id,
          quantity: 1,
          slug: salesItem.slug,
          urlKey: queryParams.urlKey,
          queryInput: queryParams.queryInput,
          isAddon
        }
      ];
      // don't run if we are adding an addon
      if (!isAddon) {
        updateSalesItemIncompatibles(newState);
      }
      return newState;
    });
  };

  /**
   * TODO: solve this with incompatibles instead, and use the selectSalesItem also for wheel storage options
   *
   * Selects a wheel storage option and deselects it if it's already selected. Compared to {@link selectSalesItem}
   * this function also deselect all other wheel storage options with a given car index since the user can only
   * select one wheel storage option per car.
   *
   * @param {SalesItemAndCar} { licensePlateNumber, salesItem, queryParams, isAddon }
   */
  const selectWheelStorageSalesItems = async ({
    licensePlateNumber,
    salesItem,
    queryParams,
    isAddon
  }: SalesItemAndCar) => {
    const isAlreadySelected = salesItems.some(
      (item) => item.licensePlateNumber === licensePlateNumber && item.id === salesItem.id
    );

    if (isAlreadySelected) {
      deselectCouponsForSalesItem(salesItem.id);
      deselectSalesItem({ salesItem, isAddon, licensePlateNumber });
    } else {
      activateCouponForSalesItem(salesItem.id);
      setSalesItems((prev) => {
        const newState = [
          ...prev.filter((item) => {
            const { licensePlateNumber: otherIndex, urlKey } = item;

            if (licensePlateNumber === otherIndex && urlKey === URLKeys.getWheelStorageSalesItemsOptions) {
              return false;
            }
            return true;
          }),
          {
            licensePlateNumber,
            id: salesItem.id,
            quantity: 1,
            slug: salesItem.slug,
            urlKey: queryParams.urlKey,
            queryInput: queryParams.queryInput,
            isAddon
          }
        ];

        return newState;
      });
    }
  };

  const deselectSalesItem = ({
    salesItem,
    isAddon,
    licensePlateNumber
  }: Pick<SalesItemAndCar, 'salesItem' | 'isAddon' | 'licensePlateNumber'>) => {
    setSalesItems((prev) => {
      const newState = prev.filter(
        (item) =>
          !(
            item.licensePlateNumber === licensePlateNumber &&
            (item.id === salesItem.id ||
              salesItem.addonIds.includes(item.id) ||
              isWheelStorageConnected(salesItem, item))
          )
      );

      if (prev.some((item) => isWheelStorageConnected(salesItem, item))) {
        removeSelectedWheelStorageOptions(licensePlateNumber);
      }

      // don't run if we are adding an addon
      if (!isAddon) {
        updateSalesItemIncompatibles(newState);
      }

      return newState;
    });
  };

  const selectSalesItemForMultipleCars = async ({
    licensePlateNumbers,
    salesItem,
    queryParams,
    isAddon
  }: Omit<SalesItemAndCar, 'licensePlateNumber'> & { licensePlateNumbers: string[] }) => {
    const selectedIncompatiblesIds = salesItems
      .filter((item) => salesItem.incompatibleIds.includes(item.id))
      .map((item) => item.id);

    const newSalesItems = licensePlateNumbers.map((licensePlateNumber) => ({
      licensePlateNumber,
      id: salesItem.id,
      quantity: 1,
      slug: salesItem.slug,
      urlKey: queryParams.urlKey,
      queryInput: queryParams.queryInput,
      isAddon
    }));

    newSalesItems.forEach(({ id }) => activateCouponForSalesItem(id));

    setSalesItems((prev) => {
      const updatedSalesItems = [...prev];

      // Do not add the sales item if it's already selected
      if (updatedSalesItems.find((p) => p.id === salesItem.id)) {
        return prev;
      }

      const newState = [...prev.filter((item) => !selectedIncompatiblesIds.includes(item.id)), ...newSalesItems];

      // don't run if we are adding an addon
      if (!isAddon) {
        updateSalesItemIncompatibles(newState);
      }
      return newState;
    });
  };

  const selectSalesItem = async ({ licensePlateNumber, salesItem, queryParams, isAddon }: SalesItemAndCar) => {
    const isAlreadySelected = salesItems.some(
      (item) => item.licensePlateNumber === licensePlateNumber && item.id === salesItem.id
    );

    if (isAlreadySelected) {
      deselectCouponsForSalesItem(salesItem.id);
      deselectSalesItem({ salesItem, isAddon, licensePlateNumber });
    } else {
      activateCouponForSalesItem(salesItem.id);
      addSalesItem({ licensePlateNumber, salesItem, queryParams, isAddon });
    }
  };

  const deselectAllSalesItemsForCar = (licensePlateNumber: string) => {
    setSalesItems((prev) => prev.filter((item) => item.licensePlateNumber !== licensePlateNumber));
    removeSelectedWheelStorageOptions(licensePlateNumber);
  };

  return {
    selectSalesItem,
    isSelected,
    selectSalesItemForMultipleCars,
    selectWheelStorageSalesItems,
    deselectAllSalesItemsForCar
  };
};

export default useSelectSalesItem;

import { SVGProps } from 'react';

const DownArrowAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill='none' {...props}>
    <path
      stroke='#1F1F1F'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M23.467 12.8 16 19.2l-7.467-6.4'
    />
  </svg>
);
export { DownArrowAlt };

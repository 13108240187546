// ToastContext.tsx
import { colors } from 'noddi-ui';
import { PropsWithChildren, ReactNode, createContext, useCallback, useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';

type ToastType = 'success' | 'error' | 'warning';

interface Toast {
  id: number;
  type: ToastType;
  message: ReactNode;
}

interface ToastContextType {
  addToast: (type: ToastType, message: ReactNode) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

const ToastProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [toast, setToast] = useState<Toast | null>(null);

  const addToast = useCallback((type: ToastType, message: ReactNode) => {
    const id = Date.now();
    setToast({ id, type, message });
    setTimeout(() => {
      setToast(null);
    }, 3000);
  }, []);

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <ToastContainer>
        {toast && (
          <Toast key={toast.id} type={toast.type}>
            {toast.message}
          </Toast>
        )}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

const ToastContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100000;
`;

const toastIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const toastOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

const Toast = styled.div<{ type: ToastType }>`
  border: 2px solid;
  border-color: ${({ type }) =>
    type === 'success' ? colors.signal.success : type === 'error' ? colors.signal.danger : colors.signal.warning};
  background-color: white;
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow:
    0 3px 10px rgba(0, 0, 0, 0.1),
    0 3px 3px rgba(0, 0, 0, 0.05);
  animation:
    ${toastIn} 0.5s,
    ${toastOut} 0.5s 2.5s;
`;

export { ToastProvider, useToast };

import { Trans, t } from '@lingui/macro';
import { NoddiContainer } from 'noddi-ui';

import routes from '../../../appRoutes';

const NoBookingDataFound = () => {
  return (
    <NoddiContainer
      breadcrumbProps={{
        links: [
          {
            title: t`Your bookings`,
            path: routes.homePageBookings.getPath()
          }
        ]
      }}
    >
      <p>
        <Trans>No data found for your booking. Please contact us if the problem persist.</Trans>
      </p>
    </NoddiContainer>
  );
};

export default NoBookingDataFound;

import { t } from '@lingui/macro';
import { Menu, MenuItem, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { useState } from 'react';
import styled from 'styled-components';

import { colors } from '../../../../../tailwind-design-preset';
import { NoddiIcon } from '../../../../atoms/NoddiIcon';
import { DataGridDropdownItem } from '../../../../types';

type ActionAreaProps = {
  rowSelectionModel: GridRowSelectionModel;
  actions: DataGridDropdownItem[];
};

const SettingsWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px;

  :hover {
    cursor: pointer;
    background-color: #ebebec;
    border-radius: 50%;
  }
`;

const ActionArea = ({ rowSelectionModel, actions }: ActionAreaProps) => {
  const selectedCount = rowSelectionModel.length;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      <Stack
        alignItems='center'
        component='div'
        direction='row'
        width='100%'
        spacing={2}
        sx={{
          backgroundColor: colors.secondary.white,
          borderRadius: 1,
          p: 1,
          boxSizing: 'border-box'
        }}
      >
        <span style={{ minHeight: '30px', display: 'flex', alignItems: 'center' }}>
          <Typography fontWeight='bold' fontSize={13} color='text.secondary'>
            {selectedCount} {t`selected`}
          </Typography>
        </span>
        {rowSelectionModel.length > 0 && (
          <>
            {' '}
            <SettingsWrapper onClick={handleClick}>
              <NoddiIcon name='MenuDots' size={24} />
            </SettingsWrapper>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              {actions.map((item, index) => {
                const disableItem = (item.actionForSingleItemOnly && selectedCount > 1) || item.disabled;
                const { closeWhenClicked = true, nestedMenuProps } = item;

                return (
                  <MenuItem
                    key={index}
                    onClick={(event) => {
                      const { anchorEl, resetAnchorEl } = nestedMenuProps || {};

                      if (anchorEl && resetAnchorEl) {
                        event.stopPropagation();
                        resetAnchorEl();
                        handleClose();
                      } else {
                        item.action(event, rowSelectionModel);
                      }
                      if (closeWhenClicked) {
                        handleClose();
                      }
                    }}
                    disabled={disableItem}
                  >
                    {'icon' in item.render && item.render.text ? (
                      <Stack direction='row' alignItems='center' gap={1}>
                        {item.render.icon}
                        <Typography>{item.render.text}</Typography>
                      </Stack>
                    ) : 'text' in item.render ? (
                      <Typography>{item.render.text}</Typography>
                    ) : (
                      item.render
                    )}
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export { ActionArea };

import { SVGProps } from 'react';

interface AltArrowRightProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const AltArrowRight = ({ color = '#1F1F1F', ...props }: AltArrowRightProps) => (
  <svg width={32} height={32} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.8 8.53333L19.2 16L12.8 23.4667'
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { AltArrowRight };

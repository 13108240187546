import { SVGProps } from 'react';

const Cross = (props: SVGProps<SVGSVGElement> & { color?: string }) => {
  const { color = '#1F1F1F', ...rest } = props;
  return (
    <svg width={32} height={32} fill='none' {...rest}>
      <path stroke={color} strokeLinecap='round' strokeWidth={1.5} d='m10 10 11.782 11.782M22 10 10.218 21.782' />
    </svg>
  );
};
export { Cross };

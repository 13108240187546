import { LoadingScreen } from 'noddi-ui';
import { storage } from 'noddi-util';
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';

interface LanguageContextType {
  currentLanguage: string;
  setCurrentLanguage: Dispatch<SetStateAction<string>>;
}

const LanguageContext = createContext<LanguageContextType>({} as LanguageContextType);

type LanguageProviderProps = {
  children: React.ReactNode;
  defaultLocale: string;
  dynamicActivateLocale: (locale: string) => Promise<void>;
};

export const localeStorageLocaleKey = 'locale';

export const LanguageProvider = ({ children, defaultLocale, dynamicActivateLocale }: LanguageProviderProps) => {
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    return storage.local.getItem<string>(localeStorageLocaleKey) || defaultLocale;
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dynamicActivateLocale(currentLanguage)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [currentLanguage]);

  return (
    <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
      {loading ? <LoadingScreen /> : children}
    </LanguageContext.Provider>
  );
};

export function useLanguageContext() {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguageContext must be used within a LanguageProvider');
  }
  return context;
}

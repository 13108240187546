import { Stack, Typography } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors } from '../../../../../tailwind-design-preset';

type NoddiLinkProps = LinkProps & {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  endIcon?: React.ReactNode;
  children: React.ReactNode;
};

const sharedStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  min-height: 39px;
  padding: 0 20px;
  box-sizing: border-box;
  border: none;
`;

const PrimaryLink = css`
  background-color: ${colors.primary.orange};
  color: #ffff;
`;

const SecondaryLink = css`
  background-color: ${colors.primary.darkPurple};
  color: #ffff;
`;

const TertiaryLink = css`
  background-color: ${colors.primary.white};
  color: black;
  border: 1px solid black;
`;

const QuaternaryButton = css`
  background-color: ${colors.primary.darkPurple30};
  color: ${colors.primary.darkPurple};
`;

const StyledButtonLink = styled(Link)<NoddiLinkProps>`
  ${sharedStyles};
  ${(props) =>
    props.variant === 'primary'
      ? PrimaryLink
      : props.variant === 'secondary'
      ? SecondaryLink
      : props.variant === 'tertiary'
      ? TertiaryLink
      : props.variant === 'quaternary'
      ? QuaternaryButton
      : PrimaryLink}
`;

export function NoddiButtonLink({ children, endIcon, ...props }: NoddiLinkProps) {
  const Content = () => {
    return typeof children === 'string' ? <Typography>{children}</Typography> : children;
  };
  return (
    <StyledButtonLink {...props}>
      {endIcon ? (
        <Stack direction='row' gap={1} justifyContent='space-between' alignItems='center'>
          <Content />
          {endIcon}
        </Stack>
      ) : (
        <Content />
      )}
    </StyledButtonLink>
  );
}

import { Trans, t } from '@lingui/macro';
import { Box, Button, Card, Container, Snackbar, Stack, Typography } from '@mui/material';
import { NoddiIcon, colors } from 'noddi-ui';
import { useState } from 'react';

const CopyReferralLink = ({ referralCode }: { referralCode: string }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const referralLink = `app.noddi.no/referrals/${referralCode}`;

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpen(false);
  };

  return (
    <Container maxWidth='lg'>
      <Card>
        <Stack p={3}>
          <Box>
            <Typography color='text.secondary' variant='body2'>
              <Trans>Share your unique link to invite others</Trans>
            </Typography>
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'baseline' }}>
              <Stack
                width='100%'
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
                mt={1}
                sx={{
                  backgroundColor: colors.secondary.coral,
                  borderRadius: 0.5,
                  outline: `0.2px solid ${colors.primary.orange}`,
                  px: 1,
                  py: 0,
                  pr: 0
                }}
              >
                <Box
                  sx={{ flexGrow: 1, cursor: 'pointer', width: '100%' }}
                  onClick={() => {
                    navigator.clipboard.writeText(referralLink);
                    setIsOpen(true);
                  }}
                >
                  <Stack alignItems='center' direction='row' width='100%' justifyContent='space-between'>
                    <Typography color='text.secondary' className='referralLink-text'>
                      {referralLink}
                    </Typography>
                    <Button
                      sx={{
                        backgroundColor: colors.primary.orange,
                        borderRadius: 0
                      }}
                    >
                      <NoddiIcon name='Copy' size={24} color={colors.primary.white} className='link-icon copy-icon' />
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Card>

      <Snackbar open={isOpen} autoHideDuration={1000} onClose={handleClose} message={t`Referral link was copied`} />
    </Container>
  );
};

export default CopyReferralLink;

import AddressPickerComponent from '../../../../components/BookingFlow/AddressPickerComponent';
import BookingScreen from '../../BookingScreen';
import { useStepValidator } from '../../hooks/useStepValidator';
import useOnNextButtonClick from './useOnNextButtonClick';

const Address = () => {
  const { navigateToNextStep } = useOnNextButtonClick();
  const { addressStepOk } = useStepValidator();
  const enableNextButton = addressStepOk();

  return (
    <BookingScreen seoTitle='Velg adresse' onNextClicked={navigateToNextStep} disableNextButton={!enableNextButton}>
      <AddressPickerComponent />
    </BookingScreen>
  );
};

export default Address;

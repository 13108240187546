import { t } from '@lingui/macro';
import { Skeleton } from '@mui/material';
import { AvailableBookingTimeWindow, AvailableBookingTimeWindowsByDate } from 'noddi-async/src/types';
import { DateFormats, dateToFromNowDaily, format } from 'noddi-util';
import styled from 'styled-components';

import { colors } from '../../../../tailwind-design-preset';
import { useGetScreenDimensions } from '../../../hooks';

const CARD_RADIUS = '13px';

const isTimeWindowUnavailable = ({ timeWindow }: { timeWindow: AvailableBookingTimeWindow }) => {
  return timeWindow.isTimeWindowTooShort || timeWindow.isCapacityFull || timeWindow.isClosed;
};

const StyledDateCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: ${CARD_RADIUS};
  border: 2px solid #dcd2f1;
  margin-top: 12px;
`;

const StyledDateText1 = styled.div`
  color: ${colors.primary.darkPurple};
  /* Small text */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const DateCard = ({ date }: { date: string }) => {
  return (
    <StyledDateCard>
      <StyledDateText1>
        {dateToFromNowDaily({ date: date, translations: { today: t`Today`, tomorrow: t`Tomorrow` } })}
      </StyledDateText1>
    </StyledDateCard>
  );
};

const StyledDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.185rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 100%;
`;

const StyledNoTimeWindowCard = styled.div<{ isUnavailable: boolean; isActive: boolean }>`
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0.5rem 2.5rem;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: ${CARD_RADIUS};
  background: ${(props) => (props.isUnavailable ? colors.secondary.white : colors.primary.pink50)};
  border: 2px solid ${(props) => (props.isActive ? colors.primary.darkPurple : 'transparent')};
  cursor: ${(props) => (props.isUnavailable ? 'default' : 'pointer')};
  :hover {
    background-color: ${(props) => (props.isUnavailable ? '' : '#d4cdf8')};
  }
`;

const StyledTimeWindowCard = styled.div<{ isUnavailable: boolean; isActive: boolean }>`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0.5rem 2.5rem;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: ${CARD_RADIUS};
  background: ${(props) => (props.isUnavailable ? colors.secondary.white : colors.primary.pink50)};

  border: 2px solid ${(props) => (props.isActive ? colors.primary.darkPurple : 'transparent')};
  cursor: ${(props) => (props.isUnavailable ? 'default' : 'pointer')};
  :hover {
    background-color: ${(props) => (props.isUnavailable ? '' : '#d4cdf8')};
  }
`;

const StyledTimeWindowCardMainText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0;
  white-space: nowrap;
  text-align: left;
  color: #000000;
`;

const StyledTimeWindowCardSubText = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  color: #000000;
`;

export type OnSelectTimeWindowProps = {
  onAvailableSelect: (bookingTimeWindow: AvailableBookingTimeWindow) => void;
  onUnavailableSelect?: (bookingTimeWindow: AvailableBookingTimeWindow) => void;
};

type TimwWindowCardProps = {
  timeWindow: AvailableBookingTimeWindow;
  isActive: boolean;
  isLoading: boolean;
} & OnSelectTimeWindowProps;

const NoTimeWindowsCard = () => {
  return (
    <StyledNoTimeWindowCard isUnavailable={true} isActive={false}>
      <StyledTimeWindowCardMainText>{t`Closed`}</StyledTimeWindowCardMainText>
    </StyledNoTimeWindowCard>
  );
};

const TimeWindowCard = ({
  timeWindow,
  isLoading,
  onAvailableSelect,
  onUnavailableSelect,
  isActive
}: TimwWindowCardProps) => {
  const isUnavailable = isTimeWindowUnavailable({ timeWindow });

  const handleOnClick = () => {
    if (isUnavailable) {
      onUnavailableSelect?.(timeWindow);
    } else {
      onAvailableSelect(timeWindow);
    }
  };

  const getSubText = () => {
    if (isUnavailable) {
      return t`Full`;
    }
    return `${timeWindow.price},-`;
  };

  if (isLoading) {
    return <Skeleton variant='rounded' width='100%' height={60} />;
  }

  return (
    <StyledTimeWindowCard onClick={handleOnClick} isUnavailable={isUnavailable} isActive={isActive}>
      <StyledTimeWindowCardMainText>
        {format(timeWindow.startPublic, DateFormats.HOURS)}-{format(timeWindow.endPublic, DateFormats.HOURS)}
      </StyledTimeWindowCardMainText>
      <StyledTimeWindowCardSubText>{getSubText()}</StyledTimeWindowCardSubText>
    </StyledTimeWindowCard>
  );
};

type BookingTimeWindowPickerDateProps = {
  timeWindowsByDate: AvailableBookingTimeWindowsByDate;
  isLoading: boolean;
  selectedTimeWindowId: number | null | undefined;
} & OnSelectTimeWindowProps;

const BookingTimeWindowPickerDate = ({
  timeWindowsByDate,
  selectedTimeWindowId,
  ...rest
}: BookingTimeWindowPickerDateProps) => {
  const { screenSize } = useGetScreenDimensions();
  return (
    <StyledDateContainer>
      <DateCard date={timeWindowsByDate.date} />
      <Wrapper height={screenSize.height * 0.45}>
        {timeWindowsByDate.timeWindows.length === 0 && <NoTimeWindowsCard />}
        {timeWindowsByDate.timeWindows.map((timeWindow) => {
          return (
            <TimeWindowCard
              key={timeWindow.id}
              timeWindow={timeWindow}
              isActive={timeWindow.id === selectedTimeWindowId}
              {...rest}
            />
          );
        })}
      </Wrapper>
    </StyledDateContainer>
  );
};

const Wrapper = styled.div<{ height: number }>`
  max-height: ${({ height }) => height}px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  gap: 1.185rem;
`;

export default BookingTimeWindowPickerDate;

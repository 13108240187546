import { AvailableBookingTimeWindow } from 'noddi-async/src/types';
import { useNavigate } from 'react-router-dom';

import routes from '../../../../appRoutes';
import { useBookingContext } from '../../../../contexts/BookingContext';
import { useSalesItems } from '../../../../hooks/useSalesItems';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { useCouponStore } from '../../../../stores/CouponStore';
import { GtmEvents } from '../../../../types/gtmTracking';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';

const useOnNextButtonClick = () => {
  const navigate = useNavigate();
  const { getSelectedSalesItemsFromCache } = useSalesItems();
  const { bookingInputData } = useBookingContext();
  const { coupons } = useCouponStore();

  // this performs a fetch, but the result SHOULD always be cached at this point so we can safely assume
  // that the data is there
  const { data } = useMembershipPrograms();

  const navigateToNextStep = () => {
    const salesItems = getSelectedSalesItemsFromCache();
    if (!salesItems || salesItems.length === 0) {
      return navigateToNext();
    }

    GoogleTrackingService.trackEvent({
      eventType: GtmEvents.addShippingInfo,
      data: {
        salesItems,
        membershipData: data,
        coupons,
        time: bookingInputData.time as AvailableBookingTimeWindow
      }
    });

    return navigateToNext();
  };

  const navigateToNext = () => {
    navigate(routes.newBookingSummaryStep.getPath());
  };

  return { navigateToNextStep };
};

export default useOnNextButtonClick;

import styled from 'styled-components';

type MinWidthGridProps = {
  minWidth?: string;
};

export const GridMinWidthItems = styled.div<MinWidthGridProps>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${(props) => props.minWidth ?? '160px'}, 1fr));
  gap: 10px 20px;
  margin: 1rem 0;
`;

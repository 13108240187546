const CURRENCY_FORMAT_FALLBACK_VALUE = '-';
const MINIMUM_FRACTION_DIGITS = 2;
const MAXIMUM_FRACTION_DIGITS = 2;
const FORMATTER_STYLE = 'currency';
const NORWEGIAN_CURRENCY_IDENTIFIER = 'NOK';

export const formatNorwegianCurrencyAmount = (
  amount?: number,
  minimumFractionDigits: number = MINIMUM_FRACTION_DIGITS
): string => {
  if (amount === undefined || amount === null) {
    return CURRENCY_FORMAT_FALLBACK_VALUE;
  }

  const norwegianCurrencyFormatter = new Intl.NumberFormat('no-NO', {
    style: FORMATTER_STYLE,
    currency: NORWEGIAN_CURRENCY_IDENTIFIER,
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: MAXIMUM_FRACTION_DIGITS
  });

  return norwegianCurrencyFormatter.format(amount);
};
export const extractNumberFromCurrencyString = (currencyStr: string): number => {
  currencyStr = currencyStr.replace(/\u00A0/g, ' ');

  currencyStr = currencyStr.replace('−', '-');

  // Remove currency symbol and any other non-numeric characters except for minus sign, comma, and period
  let cleanedStr = currencyStr.replace(/[^\d,.,-]/g, '');

  // Ensure that commas are only replaced by periods if they represent decimal separators
  if (cleanedStr.includes(',')) {
    // Check if there are multiple commas, if so, it's probably a thousand separator
    const parts = cleanedStr.split(',');
    if (parts.length > 2) {
      cleanedStr = cleanedStr.replace(/,/g, '');
    } else {
      cleanedStr = cleanedStr.replace(',', '.');
    }
  }

  // Parse the cleaned string to a float number
  const number = parseFloat(cleanedStr);

  return number;
};

import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { NoddiSEO, colors } from 'noddi-ui';
import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import routes from '../../appRoutes';
import StickyServiceSummary from '../../components/StickyServiceSummary';
import { useStickyServiceSummaryStore } from '../../stores/StickyServiceSummaryStore';
import PublicServiceAnnouncement from './PublicServiceAnnoncement';
import NavBackButton from './Steps/NavBackButton';
import StickyBottomBar from './StickyBottomBar';
import { useBackNavigation } from './hooks/useBackNavigation';
import { useStepValidator } from './hooks/useStepValidator';

const BookingContainer = styled.div<{ isMobile: boolean; noPaddingTop: boolean }>`
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  padding-top: ${(p) => (p.noPaddingTop ? 0 : '24px')};
  height: 100%;
  min-height: ${(p) => (p.isMobile ? '92vh' : '90vh')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  //TODO replace with color from our pallete once ist unified
  background-color: #f4eff9;
`;

const InnerContainer = styled.div<{ withPadding: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #f3eff9;
  padding-top: ${(p) => (p.withPadding ? '16px' : '0px')};
`;

const StickyOuterContainer = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 16px;
  border-width: 0px;
  z-index: 1150;
  max-width: 560px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 40px;
  background-color: ${colors.primary.white};
  display: flex;
`;

export const StickyBottomContainer = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;

  z-index: 1337;

  display: flex;
  padding-bottom: 32px;
  padding-top: 24px;
  background-color: ${colors.primary.white};
  flex-direction: column;
`;
interface ScreenProps {
  seoTitle: string;
  children: React.ReactNode;
  onNextClicked?: () => void;
  disableNextButton?: boolean;
  hideNextButton?: boolean;
  CustomBookingButton?: JSX.Element | null;
}

export function useIsMobileViewInBookingFlow() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
}

const BookingScreen = ({
  seoTitle,
  children,
  onNextClicked,
  disableNextButton,
  CustomBookingButton,
  hideNextButton
}: PropsWithChildren<ScreenProps>) => {
  const { showSummary } = useStickyServiceSummaryStore();
  const { pathname } = useLocation();
  const { goBack } = useBackNavigation();

  const isServiceSPathInUrl =
    pathname.includes(routes.newBookingServicesStep.getBasePath()) ||
    pathname.includes(routes.newBookingAddonsStep.getBasePath()) ||
    pathname.includes(routes.newBookingWheelStorageStep.getBasePath());
  const isTimeWindowPath = pathname.includes(routes.newBookingTimeSlotStep.getBasePath());
  const hideBackButton = pathname.includes(routes.newBookingAddressStep.getBasePath());

  const isMobileViewInBookingFlow = useIsMobileViewInBookingFlow();
  const { validateStep } = useStepValidator();

  useEffect(() => {
    validateStep();
  }, [pathname]);

  const noPaddingTop = isTimeWindowPath && !isMobileViewInBookingFlow;

  const isSummaryPage = pathname.includes(routes.newBookingSummaryStep.getBasePath());

  return (
    <TopContainer>
      <InnerContainer withPadding={isServiceSPathInUrl}>
        <NoddiSEO title={seoTitle} />

        {showSummary && isServiceSPathInUrl && (
          <StickyOuterContainer>
            <StickyServiceSummary />
          </StickyOuterContainer>
        )}

        <BookingContainer isMobile={isMobileViewInBookingFlow} noPaddingTop={noPaddingTop}>
          <Stack direction='column' px={2.5}>
            <PublicServiceAnnouncement />
            <Stack direction='column' width='100%'>
              {isSummaryPage && isMobileViewInBookingFlow && <NavBackButton />}
              {children}
            </Stack>
          </Stack>
          {CustomBookingButton}
        </BookingContainer>
      </InnerContainer>
      {!isSummaryPage && (
        <StickyBottomBar
          hideBackButton={hideBackButton}
          hideNextButton={hideNextButton}
          disableNextButton={disableNextButton}
          onNextClicked={onNextClicked}
          onPreviousClick={goBack}
        />
      )}
    </TopContainer>
  );
};

export default BookingScreen;

import { Trans } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
//TODO
import styled from 'styled-components';

import { NoddiIcon } from '../../../../../../atoms/NoddiIcon';
import { CardCommonCss } from './commonCss';

type RouteItemCardProps = {
  serviceTimeInMinutes: number;
};

const Card = styled.div`
  ${CardCommonCss};
  min-width: 80px;
`;

const BreakCard = ({ serviceTimeInMinutes }: RouteItemCardProps) => {
  return (
    <Card>
      <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
        <Typography fontSize={12}>{`${serviceTimeInMinutes} min`}</Typography>
      </Stack>
      <Stack direction='column' height='100%' justifyContent='center'>
        <Stack direction='row' alignItems='center' justifyContent='center' gap={1}>
          <NoddiIcon name='Pause' size={16} />
          <Typography textAlign='center' fontSize={15}>
            <Trans>break</Trans>
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default BreakCard;

import { Box, Rating, Stack, TextField, Typography } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { ApiErrorMessage, NoddiButton, NoddiIcon, colors } from 'noddi-ui';
import { useState } from 'react';

const defaultLabels: { [index: string]: string } = {
  0: 'Veldig dårlig',
  1: 'Dårlig',
  2: 'Helt greit',
  3: 'Ok',
  4: 'Veldig bra',
  5: 'Fantastisk!'
};
const reccomendLabels: { [index: string]: string } = {
  0: 'Veldig lite sannsynlig',
  1: 'Lite sannsynlig',
  2: 'Noe sannsynlig',
  3: 'Ganske sannsynlig',
  4: 'Veldig sannsynlig',
  5: 'Helt klart!'
};

const politenessLabels: { [index: string]: string } = {
  1: 'Lite føflig',
  2: 'Noe høflig',
  3: 'Helt ok',
  4: 'Veldig høflig',
  5: 'Kjempehøflig!'
};

const easeOfUseLabels: { [index: string]: string } = {
  1: 'Vanskelig',
  2: 'Litt vanskelig',
  3: 'Enkelt',
  4: 'Veldig enkelt',
  5: 'Kjempeenkelt!'
};

const carResultLabelsLabels: { [index: string]: string } = {
  1: 'Misfornøyd',
  2: 'Litt fornøyd',
  3: 'Ganske fornøyd',
  4: 'Veldig fornøyd!',
  5: 'Kjempefornøyd!'
};

type RatingType = 'overall' | 'politeness' | 'ease_of_use' | 'car_result' | 'communication';

function getRatingLabel(type: RatingType, hover: number) {
  switch (type) {
    case 'overall':
      return reccomendLabels[hover];
    case 'politeness':
      return politenessLabels[hover];
    case 'ease_of_use':
      return easeOfUseLabels[hover];
    case 'car_result':
      return carResultLabelsLabels[hover];
    default:
      return defaultLabels[hover];
  }
}

function RatingComponent({
  rating,
  setRating,
  label,
  hover,
  setHover,
  type
}: {
  rating: number | null;
  setRating: (value: number | null) => void;
  label: string;
  hover: number;
  setHover: (value: number) => void;
  type: RatingType;
}) {
  return (
    <Stack>
      <Typography
        color='text.secondary'
        sx={{
          mt: 1
        }}
        variant='body2'
      >
        {label}
      </Typography>
      <Stack direction='row' alignItems='center'>
        <Rating
          style={{ color: colors.primary.orange }}
          size='large'
          name='rating'
          value={rating}
          onChange={(_, newValue) => {
            if (newValue) {
              setRating(newValue);
            }
          }}
          onChangeActive={(_, newHover) => {
            setHover(newHover);
          }}
        />
        {hover > 0 && <Box sx={{ ml: 2 }}>{getRatingLabel(type, hover)}</Box>}
      </Stack>
      {type === 'politeness' && rating === null && (
        <div onClick={() => setRating(null)}>
          <Typography color='text.secondary' style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            Hopp over
          </Typography>
        </div>
      )}
    </Stack>
  );
}

const Feedback = ({ bookingId }: { bookingId: number }) => {
  const [feedbackComment, setFeedbackComment] = useState<string | null>('');
  const [overallRating, setOverallRating] = useState<number | null>(null);
  const [overallHover, setOverallHover] = useState<number>(-1);
  const [politenessRating, setPolitenessRating] = useState<number | null>(null);
  const [politenessHover, setPolitenessHover] = useState<number>(-1);
  const [easeOfUseRating, setEaseOfUseRating] = useState<number | null>(null);
  const [easeOfUseHover, setEaseOfUseHover] = useState<number>(-1);
  const [communicationRating, setCommunicationRating] = useState<number | null>(null);
  const [communicationHover, setCommunicationHover] = useState<number>(-1);
  const [carRating, setCarRating] = useState<number | null>(null);
  const [carHover, setCarHover] = useState<number>(-1);
  const [ratingStep, setRatingStep] = useState<number>(0);

  const {
    mutateAsync: addFeedback,
    isPending: isAddFeedbackPending,
    isSuccess: isAddFeedbackSuccess,
    error: addFeedbackError
  } = noddiAsync.usePost({
    type: URLKeys.postFeedback
  });

  return (
    <Stack mb={2} mt={2}>
      {isAddFeedbackSuccess ? (
        <Stack direction='row' alignItems='center'>
          <NoddiIcon name='Check' color={colors.primary.orange} className='mr-1' />
          <Typography>Tusen takk for tilbakemeldingen!</Typography>
        </Stack>
      ) : (
        <>
          <Typography variant='h6'>Hvordan var din opplevelse med Noddi?</Typography>
          <Stack mt={1}>
            <RatingComponent
              type='ease_of_use'
              rating={easeOfUseRating}
              hover={easeOfUseHover}
              setHover={setEaseOfUseHover}
              setRating={(value) => {
                setRatingStep((prev) => prev + 1);
                setEaseOfUseRating(value);
              }}
              label='Hvor enkelt syntes du det var å booke tjenesten?'
            />
            {ratingStep > 0 && (
              <RatingComponent
                type='communication'
                rating={communicationRating}
                hover={communicationHover}
                setHover={setCommunicationHover}
                setRating={(value) => {
                  setRatingStep((prev) => prev + 1);
                  setCommunicationRating(value);
                }}
                label='Hvor bra synes du kommunikasjonen vår før avtalen var?'
              />
            )}
            {ratingStep > 1 && (
              <RatingComponent
                type='car_result'
                rating={carRating}
                hover={carHover}
                setHover={setCarHover}
                setRating={(value) => {
                  setRatingStep((prev) => prev + 1);
                  setCarRating(value);
                }}
                label='Hvor tilfreds er du med resultatet på jobben?'
              />
            )}
            {ratingStep > 2 && (
              <RatingComponent
                type='politeness'
                rating={politenessRating}
                hover={politenessHover}
                setRating={(value) => {
                  setRatingStep((prev) => prev + 1);
                  setPolitenessRating(value);
                }}
                setHover={setPolitenessHover}
                label='Hvor høflig opplevde du at teknikeren var?'
              />
            )}
            {ratingStep > 3 && (
              <RatingComponent
                type='overall'
                rating={overallRating}
                hover={overallHover}
                setHover={setOverallHover}
                setRating={(value) => {
                  setRatingStep((prev) => prev + 1);
                  setOverallRating(value);
                }}
                label='Hvor sannsynlig er det at du vil anbefale Noddi til noen andre?'
              />
            )}
            {ratingStep > 4 && (
              <>
                <TextField
                  style={{ marginTop: 22, marginBottom: 12 }}
                  multiline
                  fullWidth
                  value={feedbackComment}
                  onChange={(e) => setFeedbackComment(e.target.value)}
                  label='Noen andre kommentarer?'
                  placeholder='Kommentar'
                />
                {addFeedbackError && (
                  <div style={{ marginBottom: 12 }}>
                    <ApiErrorMessage error={addFeedbackError} />
                  </div>
                )}
                <NoddiButton
                  loading={isAddFeedbackPending}
                  variant='secondary'
                  onClick={() => {
                    if (!overallRating || !politenessRating || !easeOfUseRating || !communicationRating || !carRating) {
                      return;
                    }

                    addFeedback({
                      customerComment: feedbackComment,
                      customerRatingCarResult: carRating,
                      customerRatingCommunication: communicationRating,
                      customerRatingOverall: overallRating,
                      customerRatingPoliteness: politenessRating,
                      customerRatingEaseOfUse: easeOfUseRating,
                      bookingId: bookingId
                    });
                  }}
                >
                  Send inn tilbakemelding
                </NoddiButton>
              </>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Feedback;

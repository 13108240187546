import { Stack, Typography } from '@mui/material';
import { ServerTypes, URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { ApiErrorMessage, NoddiButton, NoddiIcon, NoddiLinearProgressLoader, colors } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import routes from '../../appRoutes';

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 42px;
`;

export default function ActivateCampaign({ campaign }: { campaign: ServerTypes.CampaignPublic }) {
  const navigate = useNavigate();

  const { currentUserGroupId, isTokenLoginLoading } = useAuthContext();

  const {
    mutateAsync: activateCampaign,
    isPending: isActivateCampaignPending,
    isSuccess: isActivateCampaignSuccess,
    error: activateCampaignError
  } = noddiAsync.usePost({
    type: URLKeys.postActivateCampaign
  });

  if (isTokenLoginLoading) {
    return <NoddiLinearProgressLoader />;
  }
  if (!currentUserGroupId) {
    throw new Error('User group id is required');
  }

  return (
    <Stack>
      {isActivateCampaignSuccess ? (
        <Stack>
          <FlexContainer>
            <NoddiIcon name='Check' color={colors.primary.orange} className='mr-1' />
            <span style={{ marginLeft: '7px', fontSize: '1.2rem' }}>Kupongene er aktivert! 🎉</span>
          </FlexContainer>
          <Typography style={{ marginTop: 4 }}>Du kan nå lage en ny booking med kupongene dine.</Typography>
          <NoddiButton
            style={{ marginTop: '24px' }}
            loading={isActivateCampaignPending}
            onClick={() => navigate(routes.newBooking.getPath())}
          >
            Lag en ny booking
          </NoddiButton>
        </Stack>
      ) : (
        <>
          {activateCampaignError && <ApiErrorMessage error={activateCampaignError} />}
          <NoddiButton
            style={{ marginTop: '24px' }}
            loading={isActivateCampaignPending}
            onClick={async () =>
              await activateCampaign({
                userGroupId: currentUserGroupId,
                campaignId: campaign.id
              })
            }
          >
            Aktiver kuponger
          </NoddiButton>
        </>
      )}
    </Stack>
  );
}

import { CircularProgress, Typography } from '@mui/material';
import { ButtonHTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '../../tailwind-design-preset';
import { isTransElement } from '../components/Elements/Buttons/helpers';

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'link';
  size?: 'small' | 'medium';
} & ButtonHTMLAttributes<HTMLButtonElement>;

type NoddiButtonProps = Omit<ButtonProps, 'onClick'> & {
  loading?: boolean;
  onClick?: () => void;
} & ({ startIcon?: ReactNode; endIcon?: never } | { endIcon?: ReactNode; startIcon?: never });

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Content = ({ children }: PropsWithChildren<unknown>) => {
  const isTrans = isTransElement(children);
  return typeof children === 'string' || isTrans ? <Typography>{children}</Typography> : children;
};

export function NoddiButton({ loading, children, size = 'medium', type = 'button', ...props }: NoddiButtonProps) {
  if (loading) {
    return (
      <StyledButton type={type} size={size} {...props}>
        <SpinnerContainer>
          <CircularProgress color='inherit' size={23} />
        </SpinnerContainer>
      </StyledButton>
    );
  }
  return (
    <StyledButton type={type} size={size} {...props}>
      {props.endIcon || props.startIcon ? (
        <div className='flex items-center gap-2'>
          {props.startIcon}
          <Content>{children}</Content>
          {props.endIcon}
        </div>
      ) : (
        <Content>{children}</Content>
      )}
    </StyledButton>
  );
}

const buttonStyles = {
  shared: css`
    font-size: 16px;
    border-radius: 10px;
    align-items: center;
    box-sizing: border-box;
    border: none;
    transition: all 400ms ease-in-out;
    &:not(:disabled) {
      cursor: pointer;
    }
  `,
  enabled: css`
    &:active {
      opacity: 0.5;
    }
    &:focus {
      outline: 0;
    }
  `,
  disabled: css`
    background-color: ${colors.systemColors.grey} !important;
    color: ${colors.systemColors.outlineStroke}!important;
    cursor: not-allowed;
  `,
  small: css`
    padding: 8px 14px;
  `,
  medium: css`
    padding: 10px 20px;
  `
};

const buttonVariants = {
  primary: css`
    background-color: ${colors.primary.darkPurple};
    color: #ffff;
    &:hover {
      background-color: #573084;
    }
    &:focus {
      background-color: ${colors.primary.purple};
    }
  `,

  secondary: css`
    background-color: ${colors.primary.white};
    border: 1px solid ${colors.systemColors.outlineStroke};
    color: ${colors.primary.black};
    &:hover {
      background-color: ${colors.systemColors.outlineStroke};
    }
    &:focus {
      background-color: ${colors.systemColors.outlineStroke};
    }
  `,

  tertiary: css`
    background-color: ${colors.primary.orange};
    color: ${colors.primary.white};
    &:hover {
      background-color: ${colors.secondary.orange};
    }
    &:focus {
      background-color: ${colors.signal.danger};
    }
  `,

  quaternary: css`
    background-color: ${colors.primary.darkPurple30};
    color: ${colors.primary.darkPurple};
  `,

  link: css`
    color: ${colors.primary.purple};
    border: none;
    padding: 0px 0px !important;
    border-radius: 0px;
    background-color: transparent;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  `
};

const StyledButton = styled.button<ButtonProps>`
  ${buttonStyles.shared};

  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  ${(props) =>
    props.variant ? buttonVariants[props.variant] : buttonVariants['primary']} // Default to primary variant
  ${(props) => (props.disabled ? buttonStyles.disabled : buttonStyles.enabled)}
  ${(props) => (props.size === 'small' ? buttonStyles.small : buttonStyles.medium)}
  &:focus {
    outline: none;
  }
`;

import { Trans, t } from '@lingui/macro';
import { Card, CardContent, Typography } from '@mui/material';

import { KeyValueRow } from '../../../Elements';

type Props = {
  length: number;
  vinNumber: string | undefined;
  weight: number;
  height: number;
  width: number;
};

const GeneralDetails = ({ length, vinNumber, weight, width, height }: Props) => {
  return length || height || width || weight || vinNumber ? (
    <div>
      <Typography variant='h6'>
        <Trans>General info</Trans>
      </Typography>
      <Card sx={{ marginTop: '1rem', height: '100%' }}>
        <CardContent>
          {length && <KeyValueRow header={t`Length`} value={`${length} mm`} />}
          {height && <KeyValueRow header={t`Height`} value={`${height} mm`} />}
          {width && <KeyValueRow header={t`Width`} value={`${width} mm`} />}
          {weight && <KeyValueRow header={t`Weight`} value={`${weight} mm`} />}
          {vinNumber && <KeyValueRow header={t`VIN/Chassi`} value={vinNumber} showDivider={false} />}
        </CardContent>
      </Card>
    </div>
  ) : null;
};
export { GeneralDetails };

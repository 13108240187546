import { t } from '@lingui/macro';
import Stack from '@mui/material/Stack';
import { NoddiIcon } from 'noddi-ui';

import { useBackNavigation } from '../hooks/useBackNavigation';

const NavBackButton = () => {
  const { goBack } = useBackNavigation();

  return (
    <Stack width='100%' mb={2}>
      <small className='flex cursor-pointer text-base' onClick={goBack}>
        <NoddiIcon name='AltArrowLeft' size={24} className='mb-[-6px]' /> {t`Back`}
      </small>
    </Stack>
  );
};

export default NavBackButton;

import { Trans } from '@lingui/macro';
import { NoddiButton, NoddiIcon, NoddiIconButton, colors } from 'noddi-ui';
import { PropsWithChildren } from 'react';

import BookingProgressBar from './Steps/BookingProgressBar';

interface ScreenProps {
  onNextClicked?: () => void;
  onPreviousClick?: () => void;
  disableNextButton?: boolean;
  hideBackButton?: boolean;
  hideNextButton?: boolean;
}

const StickyBottomBar = ({
  onNextClicked,
  onPreviousClick,
  disableNextButton,
  hideBackButton,
  hideNextButton
}: PropsWithChildren<ScreenProps>) => {
  return (
    <div className='sticky bottom-0 z-[1337] flex flex-col bg-primary-white px-2 py-5'>
      <div className='mx-auto w-full max-w-screen-lg'>
        <div className='flex items-center gap-2'>
          {!hideBackButton && (
            <>
              <NoddiIconButton iconName='ArrowLeft' iconSize={24} onClick={onPreviousClick} variant='secondary' />
              <div className='w-full max-w-32'>
                <BookingProgressBar />
              </div>
            </>
          )}

          {!hideNextButton && (
            <NoddiButton
              className='ml-auto'
              onClick={onNextClicked}
              disabled={disableNextButton}
              endIcon={<NoddiIcon name='ArrowRight' size={24} color={colors.primary.white} />}
            >
              <Trans>Continue</Trans>
            </NoddiButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default StickyBottomBar;

import { t } from '@lingui/macro';
import { NoddiAsyncError, NoddiIcon, mapErrorCodeToUiMessage } from 'noddi-ui';
import styled from 'styled-components';

import { useToast } from '../contexts/ToastProvider';

const useNoddiToast = () => {
  const { addToast } = useToast();

  const success = (message: string) =>
    addToast(
      'success',
      <Wrapper>
        <NoddiIcon name='CheckRounded' />
        {message}
      </Wrapper>
    );
  const error = (message: string) =>
    addToast(
      'error',
      <Wrapper>
        <NoddiIcon name='CloseRounded' />
        {message}
      </Wrapper>
    );
  const warning = (message: string) =>
    addToast(
      'warning',
      <Wrapper>
        <NoddiIcon name='Exclamation' />
        {message}
      </Wrapper>
    );
  const successfullyCreated = () => success(t`Successfully created!`);
  const successfullyUpdated = () => success(t`Successfully updated!`);
  const asyncError = (e: NoddiAsyncError) => error(mapErrorCodeToUiMessage({ error: e }));

  const noddiToast = { success, error, warning, successfullyCreated, successfullyUpdated, asyncError };

  return { noddiToast };
};

export { useNoddiToast };

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { BookingTimeWindowPicker, LoadingScreen, NoddiButton, NoddiContainer, useIsMobile } from 'noddi-ui';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import routes from '../../../appRoutes';
import { StyledBottomBarStack } from '../../TireHotel/BookingFlow/Components/TireHotelBookingFlow';
import EditBookingTimeWindowDialog from './EditBookingDialog';
import NoBookingDataFound from './NoBookingDataFound';

export default function EditBookingTimeWindow() {
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const { id: bookingId } = useParams();

  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [selectedTimeWindowId, setSelectedTimeWindowId] = useState<number | undefined>();

  const bookingIdAsString = bookingId as string;
  const userGroupIdAsNumber = userGroupId as number;

  const {
    isPending: isBookingPending,
    data: bookingData,
    isError
  } = noddiAsync.useGet({
    type: URLKeys.getUserBooking,
    input: { userGroupId: userGroupIdAsNumber, bookingId: bookingIdAsString },
    queryConfig: {
      enabled: !!userGroupId && !!bookingId
    }
  });

  const {
    isPending: isBookingPermissionsPending,
    data: bookingPermissions,
    isError: isBookingPermissionsError
  } = noddiAsync.useGet({
    type: URLKeys.getEditBookingPersmissions,
    input: { id: bookingIdAsString },
    queryConfig: {
      enabled: !!bookingId
    }
  });

  const {
    isPending: isTimeWindowsDataPending,
    data: timeWindowsData,
    isError: isTimeWindowsDataError
  } = noddiAsync.useGet({
    type: URLKeys.getUpdateBookingTimeWindowData,
    input: { id: bookingIdAsString },
    queryConfig: {
      enabled: !!bookingId
    }
  });

  useEffect(() => {
    if (timeWindowsData?.bookingTimeWindowId) {
      setSelectedTimeWindowId(timeWindowsData?.bookingTimeWindowId);
    }
  }, [timeWindowsData]);

  if (isBookingPending || isBookingPermissionsPending || isTimeWindowsDataPending) {
    return <LoadingScreen />;
  }
  if (isError || !bookingData || isBookingPermissionsError || isTimeWindowsDataError) {
    return <NoBookingDataFound />;
  }

  const initialFromDate = bookingPermissions.timeWindows.earliestDate
    ? new Date(bookingPermissions.timeWindows.earliestDate)
    : new Date();

  const isAllowedToEditTimeWindow =
    bookingPermissions.timeWindows.canEdit &&
    selectedTimeWindowId !== undefined &&
    timeWindowsData.bookingTimeWindowId !== selectedTimeWindowId;

  return (
    <div>
      <EditBookingTimeWindowDialog
        isOpen={editDialogIsOpen}
        setIsOpen={setEditDialogIsOpen}
        bookingId={bookingIdAsString}
        timeWindowId={selectedTimeWindowId as number}
      />

      <NoddiContainer
        header={t`When is it convenient for us to come to you?`}
        breadcrumbProps={{
          links: [
            {
              title: t`Your bookings`,
              path: routes.homePageBookings.getPath()
            },
            {
              title: bookingData?.slug,
              path: routes.myBookingDetails.getPath({ id: bookingIdAsString })
            }
          ]
        }}
      >
        <BookingTimeWindowPicker
          serviceAreaId={timeWindowsData.serviceAreaId}
          salesItemIds={timeWindowsData.salesItemIds}
          initialFromDate={initialFromDate}
          maxNumberOfSlidesOnPage={3}
          selectedTimeWindowId={selectedTimeWindowId}
          onAvailableSelect={(timeWindow) => setSelectedTimeWindowId(timeWindow.id)}
        />
        <StyledBottomBarStack numberOfButtons={2} isMobile={isMobile}>
          <NoddiButton onClick={() => navigate(-1)} variant='secondary' className='min-w-40'>
            {t`Back`}
          </NoddiButton>

          <NoddiButton
            onClick={() => setEditDialogIsOpen(true)}
            disabled={!isAllowedToEditTimeWindow}
            variant='primary'
            className='min-w-40'
          >
            <Trans>Change time</Trans>
          </NoddiButton>
        </StyledBottomBarStack>
      </NoddiContainer>
    </div>
  );
}

import { SVGProps } from 'react';

const WheelAngle = (props: SVGProps<SVGSVGElement>) => (
  <svg width={30} height={30} fill='none' {...props}>
    <path
      stroke='#1C274C'
      strokeWidth={1.5}
      d='M19.5 25c2.485 0 4.5-4.477 4.5-10S21.985 5 19.5 5M15 15c0 5.523-2.015 10-4.5 10S6 20.523 6 15 8.015 5 10.5 5 15 9.477 15 15Z'
    />
    <path stroke='#1C274C' strokeWidth={1.5} d='M10.5 25c2.485 0 4.5-4.477 4.5-10S12.985 5 10.5 5' />
    <path
      stroke='#1C274C'
      strokeWidth={1.5}
      d='M12 15c0 3.314-.672 6-1.5 6S9 18.314 9 15s.672-6 1.5-6 1.5 2.686 1.5 6ZM10.5 5h9M10.5 25h9'
    />
    <path stroke='#1C274C' strokeLinecap='round' strokeWidth={1.5} d='M12 15h-1' />
  </svg>
);
export { WheelAngle };

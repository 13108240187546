import { Trans, t } from '@lingui/macro';
import { OutlinedInput, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, NoddiButton, NoddiIcon, SMSLogin, SectionSpacer, UserRegistrationForm, colors } from 'noddi-ui';
import { useEffect } from 'react';

import StickyServiceSummary from '../../../../components/StickyServiceSummary';
import { useBookingContext } from '../../../../contexts/BookingContext';
import useActivateCoupon from '../../../../hooks/useActivateCoupon';
import BookingScreen, { StickyBottomContainer, useIsMobileViewInBookingFlow } from '../../BookingScreen';
import { useBackNavigation } from '../../hooks/useBackNavigation';
import { useMembershipPrograms } from '../../hooks/useMembershipPrograms';
import ConfirmBooking from './ConfirmBooking';

const Summary = () => {
  const { isLoggedIn, isTokenLoginLoading } = useAuthContext();
  const isMobileViewInBookingFlow = useIsMobileViewInBookingFlow();
  const { bookingInputData: inputData, updateBookingInputData, salesItems } = useBookingContext();
  const { goBack } = useBackNavigation();

  const { activateCouponForSalesItem, couponsFromApi: fetchedCoupons } = useActivateCoupon();

  const { data: membershipData } = useMembershipPrograms();

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateBookingInputData({ comment: event.target.value });
  };

  useEffect(() => {
    if (isLoggedIn && fetchedCoupons?.length !== 0) {
      salesItems.forEach((si) => {
        activateCouponForSalesItem(si.id);
      });
    }
  }, [isLoggedIn, fetchedCoupons]);

  if (isTokenLoginLoading) {
    return (
      <BookingScreen seoTitle='Bekreft' hideNextButton>
        <LoadingScreen />
      </BookingScreen>
    );
  }

  return (
    <BookingScreen
      seoTitle='Bekreft'
      hideNextButton
      CustomBookingButton={
        isLoggedIn && isMobileViewInBookingFlow ? (
          <StickyBottomContainer>
            <div style={{ paddingLeft: 20, paddingRight: 20, marginTop: 20 }}>
              <ConfirmBooking membershipPrograms={membershipData} />
            </div>
          </StickyBottomContainer>
        ) : null
      }
    >
      <Stack spacing={3}>
        <Typography variant='h5'>
          <Trans>Does this look right?</Trans>
        </Typography>
        <StickyServiceSummary />
      </Stack>

      <SectionSpacer>
        <Typography marginBottom={1} variant='h5'>
          <Trans>Comment</Trans>
        </Typography>

        <OutlinedInput
          rows={2}
          onChange={handleCommentChange}
          fullWidth={true}
          type='textarea'
          value={inputData.comment || ''}
          title='Delivery Code'
          placeholder={t`Instructions to the technician at arrival (e.g. where the car is parked)`}
          multiline
        />
      </SectionSpacer>

      <SectionSpacer>
        <Typography variant='subtitle2' style={{ display: 'flex', alignItems: 'center' }}>
          <NoddiIcon name='Info' size={24} color={colors.primary.darkPurple} className='mr-2' />
          {t`You will receive a payment link after the job has been completed`}
        </Typography>
      </SectionSpacer>

      {isLoggedIn ? (
        <>
          {/* 
              Add back when we have an edit booking flow again
             <PatchBooking membershipPrograms={membershipData} />
             */}
          {!isMobileViewInBookingFlow && (
            <div className='flex justify-end'>
              <ConfirmBooking
                membershipPrograms={membershipData}
                fullWidth={false}
                BackButton={
                  <NoddiButton
                    onClick={() => {
                      goBack();
                    }}
                    className='min-w-40'
                    variant='secondary'
                  >
                    <Trans>Back</Trans>
                  </NoddiButton>
                }
              />
            </div>
          )}
        </>
      ) : (
        <SMSLogin
          userRegistrationForm={(phoneNumber) => (
            <UserRegistrationForm phoneNumber={phoneNumber} skipPasswordCreation />
          )}
          callBackObject={isMobileViewInBookingFlow ? undefined : { customCallback: goBack, title: t`Back` }}
        />
      )}
    </BookingScreen>
  );
};

export default Summary;

import { Trans, t } from '@lingui/macro';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { MembershipProgramProps } from 'noddi-async/src/types';
import { NoddiIcon, NoddiTextAreaInput, colors, getLongAddressName } from 'noddi-ui';
import { timeWindowToDisplayString } from 'noddi-util';
import { ReactNode } from 'react';
import styled from 'styled-components';

import BookingSummary from '../../../../../components/BookingFlow/Summary';
import { useBookingContext } from '../../../../../contexts/BookingContext';

type Props = {
  membershipData: MembershipProgramProps[] | undefined;
};

const SummaryBox = styled.div`
  background-color: ${colors.primary.darkPurple30};
  border-radius: 1rem;
  padding: 1rem;
`;

const SummaryItem = ({ icon, children }: { icon: ReactNode; children: ReactNode }) => {
  return (
    <Stack alignItems='center' direction='row' spacing={1}>
      {icon}
      <Typography variant='body2' fontWeight='normal'>
        {children}
      </Typography>
    </Stack>
  );
};

const ConfirmBooking = ({ membershipData }: Props) => {
  const { bookingInputData, updateBookingInputData } = useBookingContext();
  return (
    <>
      <Typography variant='h5' marginTop={1} marginBottom={2}>
        <Trans>Does everything look good?</Trans>
      </Typography>
      <Stack direction='column' gap={4}>
        <SummaryBox>
          {bookingInputData.address && (
            <SummaryItem icon={<NoddiIcon name='LocationPin' size={24} />}>
              {getLongAddressName(bookingInputData.address)}
            </SummaryItem>
          )}
          {bookingInputData.time && (
            <SummaryItem icon={<NoddiIcon name='ClockCircle' size={24} />}>
              {timeWindowToDisplayString(
                new Date(bookingInputData.time.startPublic),
                new Date(bookingInputData.time.endPublic)
              )}
            </SummaryItem>
          )}
          <BookingSummary membershipData={membershipData} />
        </SummaryBox>
        <NoddiTextAreaInput
          label={t`Comment`}
          placeholder={t`Instructions to the technician at arrival (e.g. where the car is parked)`}
          value={bookingInputData.comment || ''}
          onBlur={(event) => updateBookingInputData({ comment: event.target.value })}
        />
      </Stack>
    </>
  );
};

export default ConfirmBooking;

import { Trans, t } from '@lingui/macro';
import { Box, SxProps, Typography } from '@mui/material';
import { ServerTypes } from 'noddi-async';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { colors } from '../../../../tailwind-design-preset';

const CouponContainer = styled(Box)<{ isUsed: boolean }>`
  background: ${(props) => (props.isUsed ? undefined : `linear-gradient(135deg, #7158fe, #9d4de6)`)};
  background-color: ${(props) => (props.isUsed ? colors.systemColors.grey : undefined)};
  color: #fff;
  text-align: center;
  padding: 20px 40px;
  border-radius: 15px;
  position: relative;
`;

const AmountContainer = styled.div`
  border: 1px dashed #fff;
  padding: 10px 20px;
  border-right: 0;
  border-radius: 2px;
`;

const CouponAmount = styled.div`
  border: 1px solid #fff;
  background: #fff;
  padding: 10px 20px;
  border-left: 0;
  border-radius: 2px;
  color: ${colors.primary.orange};
`;

const AmountRow = styled.div`
  display: flex;
  align-items: center;
  margin: 12px auto;
  width: fit-content;
`;

const IsActiveTag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #2ef4a2;
  color: black;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 0 15px 0 5px;
`;

type Props = Pick<ServerTypes.CouponTemplate, 'namePublic' | 'descriptionPublic'> & {
  isActivated?: boolean;
  isUsed?: boolean;
  amountPercentage?: number;
  amount?: number;
  containerSx?: SxProps;
};

function getTag(isActivated: boolean, isUsed: boolean) {
  if (isUsed) {
    return (
      <IsActiveTag>
        <Trans>Used</Trans>
      </IsActiveTag>
    );
  }
  if (isActivated) {
    return (
      <IsActiveTag>
        <Trans>Activated</Trans>
      </IsActiveTag>
    );
  }
  return undefined;
}

export function CouponCard({
  amount,
  amountPercentage,
  namePublic,
  descriptionPublic,
  children,
  isActivated,
  isUsed,
  containerSx
}: PropsWithChildren<Props>) {
  return (
    <CouponContainer sx={containerSx} isUsed={Boolean(isUsed)}>
      {getTag(Boolean(isActivated), Boolean(isUsed))}

      <Typography variant='h5' style={{ color: colors.primary.white, marginTop: 12 }}>
        {namePublic}
      </Typography>
      <Typography style={{ color: colors.primary.white }}>{descriptionPublic}</Typography>
      <AmountRow>
        <AmountContainer>
          <Typography
            variant='subtitle1'
            fontSize='20px'
            style={{ color: colors.primary.white }}
            fontWeight='bold'
            marginY='auto'
          >
            {amount ? t`Amount` : t`Discount`}
          </Typography>
        </AmountContainer>
        <CouponAmount>
          <Typography variant='subtitle1' fontSize='20px' fontWeight='bold' marginY='auto'>
            {amount ? `${amount} kr` : `${amountPercentage} %`}
          </Typography>
        </CouponAmount>
      </AmountRow>
      {children}
    </CouponContainer>
  );
}

import { SVGProps } from 'react';

const Check = (props: SVGProps<SVGSVGElement> & { color?: string }) => {
  const { color = '#1F1F1F', ...rest } = props;
  return (
    <svg width={30} height={30} fill='none' {...rest}>
      <path stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='m9 15.4 3.143 3.6L20 10' />
    </svg>
  );
};
export { Check };

import { Trans } from '@lingui/macro';
import { Stack, Typography } from '@mui/material';
import { ServiceCategory } from 'noddi-async/src/types';

import { AvailableServiceCategory } from './AvailableServiceCategory';
import { UnavailableServiceCategory } from './UnavailableServiceCategory';

type Props = {
  address?: string;
  serviceCategories: ServiceCategory[];
  unavailableServiceCategories: string[];
};

const ServiceAvailabilityInfo = ({ address, serviceCategories, unavailableServiceCategories }: Props) => {
  if (!address) {
    return <></>;
  }

  return (
    <>
      <Trans>Hooray! We deliver the following services to </Trans> {address}:
      <Stack>
        {serviceCategories.map((serviceCategory, index) => (
          <AvailableServiceCategory key={index} serviceCategory={serviceCategory} />
        ))}
      </Stack>
      {unavailableServiceCategories.length > 0 && (
        <Stack style={{ marginTop: 22 }}>
          <Typography variant='body1'>
            <Trans>
              We are working with extending our delivery offer to your address with the following services:{' '}
            </Trans>
          </Typography>
          <Stack>
            {unavailableServiceCategories.map((serviceCategory, index) => (
              <UnavailableServiceCategory key={index} serviceCategoryName={serviceCategory} />
            ))}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export { ServiceAvailabilityInfo };

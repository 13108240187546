interface ProgressBarProps {
  value: number;
}

const NoddiProgressBar = ({ value }: ProgressBarProps) => {
  if (value > 100) {
    return <></>;
  }

  return (
    <div className='w-full min-w-25 rounded-full border-2  border-secondary-white'>
      <div
        className='h-2.5 rounded-full  bg-[#20005B] transition-all duration-300 ease-in-out'
        style={{ width: `${value > 100 ? 100 : value}%` }}
      ></div>
    </div>
  );
};

export { NoddiProgressBar };
